import { Tag, useStyles2 } from '@grafana/ui';
import React from 'react';
import detailPageTitleStyles from './DetailPageTitle.styles';
import { PLUGIN_ROOT_URL } from '../../constants';
import { Link } from 'react-router-dom';
import { decodeUrlString } from 'helpers/helpers';

type DetailPageTitleProps = {
  tagName: string;
  title: string;
  cluster?: string;
};
const DetailPageTitle = ({ tagName, title, cluster }: DetailPageTitleProps) => {
  const styles = useStyles2(detailPageTitleStyles);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '10px' }}>{title}</h1>
        <Tag name={tagName} />
      </div>
      {tagName !== 'cluster' && cluster && (
        <div className={styles.clusterLine}>
          in cluster{' '}
          <Link className={styles.clusterLink} to={`${PLUGIN_ROOT_URL}/navigation/cluster/${cluster}`}>
            {decodeUrlString(cluster)}
          </Link>
        </div>
      )}
    </div>
  );
};

export default DetailPageTitle;
