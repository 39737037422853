import { config } from '@grafana/runtime';
import { K8S_STORAGE_KEY } from '../constants';

export function getDefaultLoki(storedLokiName?: string): string {
  const storageData = JSON.parse(window?.localStorage?.getItem(K8S_STORAGE_KEY) || '{}');
  const lokiName = storageData?.lokiName;

  if (lokiName) {
    return lokiName;
  }

  if (storedLokiName) {
    return storedLokiName;
  }

  // Save any loki datasource incase there isn't a GCloud one present
  let defaultLoki = '';
  let lokiDatasource = '';
  let grafanacloudLoki = '';
  Object.keys(config.datasources).forEach?.((dsName) => {
    if (
      config.datasources[dsName].type === 'loki' &&
      !dsName.includes('usage-insights') &&
      !dsName.includes('alert-state-history')
    ) {
      if (config.datasources[dsName].isDefault) {
        defaultLoki = dsName;
      }
      lokiDatasource = dsName;
      if (dsName.includes('grafanacloud')) {
        grafanacloudLoki = dsName;
      }
    }
  });

  if (defaultLoki) {
    return defaultLoki;
  } else if (grafanacloudLoki) {
    return grafanacloudLoki;
  }

  return lokiDatasource;
}

export function getDefaultProm(storedPrometheusName?: string): string {
  const storageData = JSON.parse(window?.localStorage?.getItem(K8S_STORAGE_KEY) || '{}');
  const promName = storageData?.promName;

  if (promName) {
    return promName;
  }

  if (storedPrometheusName) {
    return storedPrometheusName;
  }

  // Try and find a default prometheus datasource
  const defaultProm = Object.keys(config.datasources).find((dsName) => {
    return (
      config.datasources[dsName].type === 'prometheus' &&
      config.datasources[dsName].isDefault &&
      !config.datasources[dsName].name.includes('usage') &&
      !config.datasources[dsName].name.includes('ml-metrics')
    );
  });

  if (defaultProm) {
    return defaultProm;
  }

  // If there is no default prometheus just pick the first one
  return (
    Object.keys(config.datasources).find((dsName) => {
      return config.datasources[dsName].type === 'prometheus';
    }) || ''
  );
}
