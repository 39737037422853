export const trackRudderStackEvent = (event: string, params: object) => {
  (window as any).rudderanalytics?.track(event, {
    ...params,
    instance_slug: location.hostname.split('.')[0],
  });
};

const useRudderStack = () => {
  return trackRudderStackEvent;
};

export default useRudderStack;
