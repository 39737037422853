import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const detailPageTitleStyles = (theme: GrafanaTheme2) => ({
  clusterLine: css`
    margin-top: ${theme.spacing(0.5)};
    font-size: 16px;
    color: ${theme.colors.text.secondary};
  `,
  clusterLink: css`
    color: ${theme.colors.text.link};
  `,
});

export default detailPageTitleStyles;
