import { DataFrame, DataTransformContext, FieldType } from '@grafana/data';
import { DataSourceRef } from '@grafana/schema';
import { encodeUrlString } from 'helpers/helpers';
import { getGenericQueryRunner } from 'helpers/scenes';
import { SceneQueries } from 'queries';
import { Observable, map } from 'rxjs';

/**
 * transformData will detect 'cluster' labels and/or fields and inject
 * 'encoded_cluster' label and/or fields alongside the entries. This is mainly
 * to support URL encoding of clusters with '/' characters in their names.
 */
export const transformData = (_context: DataTransformContext) => (source: Observable<DataFrame[]>) =>
  source.pipe(
    map((data) => {
      data?.forEach?.((d, i) => {
        const encodedClusterValues: string[] = [];

        d?.fields?.forEach?.((f, j) => {
          // detect cluster label
          if (f?.labels?.cluster) {
            data[i]!.fields[j]!.labels!.encoded_cluster = encodeUrlString(f.labels.cluster);
          }

          // detect cluster fields
          if (f?.name === 'cluster' && f?.values) {
            data[i]!.fields[j]!.values.forEach((v) => {
              encodedClusterValues.push(encodeUrlString(v));
            });
          }
        });

        if (encodedClusterValues.length) {
          data[i]?.fields?.push({
            name: 'encoded_cluster',
            type: FieldType.string,
            config: {},
            values: encodedClusterValues,
          });
        }
      });

      return data;
    })
  );

export const containerAlertsQueryRunner = (datasource: DataSourceRef) => {
  return getGenericQueryRunner(datasource, SceneQueries.Home.ContainerAlerts, {
    format: 'table',
    transformations: [
      transformData,
      {
        id: 'organize',
        options: {
          excludeByName: {
            Time: true,
          },
          includeByName: {},
          indexByName: {
            container: 2,
            cluster: 5,
            namespace: 4,
            pod: 3,
            Value: 0,
            alertname: 1,
          },
          renameByName: {
            container: 'Container',
            Value: 'Firing Since',
            alertname: 'Alert',
            cluster: 'Cluster',
            namespace: 'Namespace',
            pod: 'Pod',
          },
        },
      },
      {
        id: 'sortBy',
        options: {
          fields: {},
          sort: [
            {
              desc: true,
              field: 'Firing Since',
            },
          ],
        },
      },
    ],
  });
};

export const podAlertsQueryRunner = (datasource: DataSourceRef) => {
  return getGenericQueryRunner(datasource, SceneQueries.Home.PodAlerts, {
    format: 'table',
    transformations: [
      transformData,
      {
        id: 'organize',
        options: {
          excludeByName: {
            Time: true,
          },
          includeByName: {},
          indexByName: {
            pod: 2,
            cluster: 4,
            namespace: 3,
            Value: 0,
            alertname: 1,
          },
          renameByName: {
            pod: 'Pod',
            Value: 'Firing Since',
            alertname: 'Alert',
            cluster: 'Cluster',
            namespace: 'Namespace',
          },
        },
      },
      {
        id: 'sortBy',
        options: {
          fields: {},
          sort: [
            {
              desc: true,
              field: 'Firing Since',
            },
          ],
        },
      },
    ],
  });
};
