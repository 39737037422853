const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "cockroachdb" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_<service label>"]
    regex = "<service label value>"
    action = "keep"
  }
}

prometheus.scrape "cockroachdb" {
  job_name   = "integrations/cockroachdb"
  targets    = discovery.relabel.cockroachdb.output
  forward_to = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
