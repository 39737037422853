const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "activemq" {
    targets = discovery.kubernetes.pods.targets
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<activemq_pod_label>"]
        regex = "<activemq_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<activemq_jmx_port_number>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "activemq_cluster"
    }
    rule {
        replacement = "integrations/apache-activemq"
        target_label = "job"
    }
}

prometheus.scrape "metrics_activemq" {
    targets      = discovery.relabel.activemq.output
    honor_labels = true
    forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_activemq" {
    targets = discovery.relabel.pod_logs.output
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<activemq_pod_label>"]
        regex = "<activemq_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "activemq_cluster"
    }
    rule {
        replacement = "integrations/apache-activemq"
        target_label = "job"
    }
}

local.file_match "logs_activemq" {
    path_targets = discovery.relabel.logs_activemq.output
}

loki.source.file "logs_activemq" {
    targets    = local.file_match.logs_activemq.targets
    forward_to = [loki.process.logs_activemq.receiver]
}    

loki.process "logs_activemq" {
    forward_to = [loki.process.logs_service.receiver]

    stage.multiline {
        firstline     = "(TRACE|DEBUG|INFO|WARN|ERROR|FATAL)"
        max_lines     = 0
        max_wait_time = "3s"
    }

    stage.regex {
        expression = "(?P<level>TRACE|DEBUG|INFO|WARN|ERROR|FATAL)\\\\s*[\\\\|:]\\\\s*(?P<message>.+)"
    }

    stage.labels {
        values = {
            level = null,
        }
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }
}`;

export default { metrics, logs };
