const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.postgres "postgres" {
  data_source_names = ["postgresql://<username>:<password>@<your_postgres_instance_name>:5432/postgres"]
}

prometheus.scrape "postgres" {
  targets      = prometheus.exporter.postgres.postgres.targets
  job_name     = "integrations/postgres_exporter"
  forward_to   = [prometheus.relabel.postgres.receiver]
}

prometheus.relabel "postgres" {
  rule {
    replacement = "integrations/postgres_exporter"
    target_label = "job"
  }
  rule {
    replacement = "<your_postgres_instance_name>"
    target_label = "instance"
  }
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "postgres_logs" {
  targets = discovery.relabel.pod_logs.output
  
  rule {
    source_labels = ["namespace"]
    regex = "<your_postgres_namespace>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<your_postgres_selector_labelname>"]
    regex = "<your_postgres_selector_labelvalue>"
    action = "keep"
  }
}

local.file_match "postgres_logs" {
  path_targets = discovery.relabel.postgres_logs.output
}

loki.source.file "postgres_logs" {
  targets    = local.file_match.postgres_logs.targets
  forward_to = [loki.process.postgres_logs.receiver]
}

loki.process "postgres_logs" {
  // If you're using docker, replace \`cri\` with \`docker\` below.
  stage.cri {}
  stage.static_labels {
    values = {
      job = "integrations/postgres_exporter",
      instance = "<your_postgres_instance_name>",
    }
  }
  forward_to = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
