import {
  EmbeddedScene,
  QueryVariable,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimeRange,
  SceneVariableSet,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { DataFrame, DataTransformContext, FieldType } from '@grafana/data';
import { Observable, map } from 'rxjs';

import {
  addTimeRangeHandler,
  addVizPanelMenuHandler,
  getGenericQueryRunner,
  query_result,
} from '../../../helpers/scenes';
import { graphsConfigWithLabel } from './panelConfig';
import { SceneQueries } from 'queries';
import { ScenesCustomParams } from 'types';
import { allocationRow, idleRow, setsBoxRow } from '../GenericDetailOptimization/scene';
import { getExtensionDetailsOptimizationControls } from 'components/Extensions/ObjectDetailExtension';
import { getNetworkScene } from '../Network/Network';

export const enum QueryRefId {
  Cpu = 'cpu',
  Memory = 'memory',
}

export function getVariables(datasource: DataSourceRef, cluster?: string, namespace?: string, pod?: string) {
  const numContainers = new QueryVariable({
    name: 'numContainers',
    query: query_result(SceneQueries.PodDetail.NumContainers(cluster, namespace, pod)),
    datasource,
    skipUrlSync: true,
  });

  const cpuCapacity = new QueryVariable({
    name: 'cpuCapacityUpperLimit',
    query: query_result(SceneQueries.PodDetail.CpuRequests(cluster, namespace, pod)),
    datasource,
    skipUrlSync: true,
  });

  const memCapacity = new QueryVariable({
    name: 'memCapacityUpperLimit',
    query: query_result(SceneQueries.PodDetail.MemoryRequests(cluster, namespace, pod)),
    datasource,
    skipUrlSync: true,
  });

  const variableSet = new SceneVariableSet({
    variables: [numContainers, cpuCapacity, memCapacity],
  });

  return variableSet;
}

export const overrides = [
  {
    matcher: {
      id: 'byName',
      options: 'Value',
    },
    properties: [
      {
        id: 'mappings',
        value: [
          {
            options: {
              from: -1000000000,
              result: {
                color: 'red',
                index: 0,
                text: 'Undersized',
              },
              to: 0,
            },
            type: 'range',
          },
        ],
      },
    ],
  },
];

export const transformData =
  (type: QueryRefId) => (_context: DataTransformContext) => (source: Observable<DataFrame[]>) =>
    source.pipe(map((data) => data?.filter?.((f) => f.refId === type)));

export const sumData = (_context: DataTransformContext) => (source: Observable<DataFrame[]>) =>
  source.pipe(
    map((data) => {
      const cpu = data?.filter?.((f) => f.refId === QueryRefId.Cpu);
      const memory = data?.filter?.((f) => f.refId === QueryRefId.Memory);

      const cpuValue = cpu?.[0]?.fields?.[1]?.values?.get(0);
      const memoryValue = memory?.[0]?.fields?.[1]?.values?.get(0);
      const total = Number(cpuValue) + Number(memoryValue);
      const value = isNaN(total) ? 'No data' : total;

      return [
        {
          fields: [
            {
              config: {},
              name: 'Value',
              state: {
                calcs: {
                  sum: value,
                },
              },
              type: FieldType.number,
              values: [value],
            },
          ],
          length: 1,
        },
      ];
    })
  );

export const firstRow = (datasource: DataSourceRef, cluster?: string, namespace?: string, pod?: string) => {
  return new SceneFlexLayout({
    children: [
      {
        refIdPrefix: 'cpu',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.PodDetail.CpuLimits(cluster, namespace, pod),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'cpuLimits',
            legendFormat: 'Sum of container CPU limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.PodDetail.CpuAllocation(cluster, namespace, pod),
              instant: false,
              interval: '',
              range: true,
              refId: 'cpuAllocation',
              legendFormat: 'Sum of container CPU allocation',
            },
            {
              expr: SceneQueries.PodDetail.CpuRequests(cluster, namespace, pod),
              legendFormat: 'Sum of container CPU requests',
              refId: 'cpuRequests',
              interval: '',
              instant: false,
              range: true,
            },
            {
              expr: SceneQueries.PodDetail.CpuUsage(cluster, namespace, pod),
              instant: false,
              interval: '',
              range: true,
              refId: 'cpuUsage',
              legendFormat: 'Sum of container CPU usage',
            },
          ]
        ),
        title: 'Pod CPU',
        unit: 'cores',
        predictable: {
          query: SceneQueries.PodDetail.CpuUsage(cluster, namespace, pod),
          button: 'Predict CPU Usage',
          title: 'CPU Usage Prediction Model',
          name: 'Pod CPU usage',
          metric: 'container_cpu_usage_seconds_total',
          predictableVariableName: 'cpuCapacityUpperLimit',
          axisLabel: 'CPU usage (cores)',
        },
      },
      {
        refIdPrefix: 'mem',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.PodDetail.MemoryLimits(cluster, namespace, pod),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'memLimits',
            legendFormat: 'Sum of container memory limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.PodDetail.MemoryAllocation(cluster, namespace, pod),
              instant: false,
              interval: '',
              range: true,
              refId: 'memAllocation',
              legendFormat: 'Sum of container memory allocation',
            },
            {
              expr: SceneQueries.PodDetail.MemoryRequests(cluster, namespace, pod),
              instant: false,
              interval: '',
              range: true,
              legendFormat: 'Sum of container memory requests',
              refId: 'memRequests',
            },
            {
              expr: SceneQueries.PodDetail.MemoryUsage(cluster, namespace, pod),
              instant: false,
              interval: '',
              range: true,
              refId: 'memUsage',
              legendFormat: 'Sum of container memory usage',
            },
          ]
        ),
        title: 'Pod Memory',
        unit: 'bytes',
        predictable: {
          query: SceneQueries.PodDetail.MemoryUsage(cluster, namespace, pod),
          button: 'Predict memory usage',
          title: 'Memory Usage Prediction Model',
          name: 'Pod memory usage',
          metric: 'container_memory_rss',
          predictableVariableName: 'memCapacityUpperLimit',
          axisLabel: 'Memory usage (bytes)',
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(graphsConfigWithLabel(panel.refIdPrefix, panel).build(), [], panel.predictable),
      });
    }),
  });
};

export function getPodDetailOptimization({
  datasource,
  cluster,
  namespace,
  podName,
  relativeTimeRange,
  onTimeRangeChange,
  withPickers,
  workloadType,
  workload,
  prometheusName,
  lokiName,
  isEmbeddedExtension,
  locationTitle,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const queries = {
    cpuRequests: SceneQueries.PodDetail.CpuRequestsText(cluster, namespace, podName),
    cpuLimits: SceneQueries.PodDetail.CpuLimitsText(cluster, namespace, podName),
    memoryRequests: SceneQueries.PodDetail.MemoryRequestsText(cluster, namespace, podName),
    memoryLimits: SceneQueries.PodDetail.MemoryLimitsText(cluster, namespace, podName),
    cpuAllocation: SceneQueries.PodDetail.CostCpuAllocation(cluster, namespace, podName),
    memoryAllocation: SceneQueries.PodDetail.CostMemoryAllocation(cluster, namespace, podName),
    cpuIdle: SceneQueries.PodDetail.CostCpuIdle(cluster, namespace, podName),
    memoryIdle: SceneQueries.PodDetail.CostMemoryIdle(cluster, namespace, podName),
  };

  const urlParams = `?from=${relativeTimeRange?.from}&to=${relativeTimeRange?.to}&promName=${prometheusName}&lokiName=${lokiName}`;
  const urlPath = `/namespace/${cluster}/${namespace}/${workloadType}/${workload}/${podName}?${urlParams}`;

  const children = [
    firstRow(datasource, cluster, namespace, podName),
    setsBoxRow(datasource, queries),
    new SceneFlexLayout({
      direction: 'row',
      children: [allocationRow(datasource, queries), idleRow(datasource, queries)],
    }),
  ];

  if (isEmbeddedExtension) {
    children.push(
      getNetworkScene({
        type: 'pod',
        cluster: cluster || '',
        namespace,
        workload,
        workloadType,
        datasource,
        podName,
        relativeTimeRange,
        lokiName,
        prometheusName,
        locationTitle,
      })
    );
  }

  return new EmbeddedScene({
    $variables: getVariables(datasource, cluster, namespace, podName),
    $timeRange: sceneTimeRange,
    controls: getExtensionDetailsOptimizationControls({ withPickers, relativeTimeRange, urlPath, locationTitle }),
    body: new SceneFlexLayout({
      direction: 'column',
      children: [...children],
    }),
  });
}
