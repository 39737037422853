const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "hadoop_datanode_service_jmx" {
    targets = discovery.kubernetes.services.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_service_name", "__meta_kubernetes_service_port_name"]
        separator     = ":"
        regex         = "(<hadoop-datanode-service-namespace>:<hadoop-datanode-service-name>:<hadoop-datanode-service-exporter-port-name>)"
    }

    rule {
        target_label  = "hadoop_cluster"
        replacement   = "<your-cluster-name>"
    }
}

discovery.relabel "hadoop_namenode_service_jmx" {
    targets = discovery.kubernetes.services.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_service_name", "__meta_kubernetes_service_port_name"]
        separator     = ":"
        regex         = "(<hadoop-namenode-service-namespace>:<hadoop-namenode-service-name>:<hadoop-namenode-service-exporter-port-name>)"
    }

    rule {
        target_label  = "hadoop_cluster"
        replacement   = "<your-cluster-name>"
    }
}

discovery.relabel "hadoop_nodemanager_service_jmx" {
targets = discovery.kubernetes.services.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_service_name", "__meta_kubernetes_service_port_name"]
        separator     = ":"
        regex         = "(<hadoop-nodemanager-service-namespace>:<hadoop-nodemanager-service-name>:<hadoop-nodemanager-service-exporter-port-name>)"
    }

    rule {
        target_label  = "hadoop_cluster"
        replacement   = "<your-cluster-name>"
    }
}

discovery.relabel "hadoop_resourcemanager_service_jmx" {
targets = discovery.kubernetes.services.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_service_name", "__meta_kubernetes_service_port_name"]
        separator     = ":"
        regex         = "(<hadoop-resourcemanager-service-namespace>:<hadoop-resourcemanager-service-name>:<hadoop-resourcemanager-service-exporter-port-name>)"
    }

    rule {
        target_label  = "hadoop_cluster"
        replacement   = "<your-cluster-name>"
    }
}

prometheus.scrape "integrations_hadoop_datanode_prometheus_exporter" {
    targets        = discovery.relabel.hadoop_datanode_service_jmx.output
    forward_to     = [prometheus.relabel.metrics_service.receiver]
    job_name       = "integrations/apache-hadoop"
}

prometheus.scrape "integrations_hadoop_namenode_prometheus_exporter" {
    targets        = discovery.relabel.hadoop_namenode_service_jmx.output
    forward_to     = [prometheus.relabel.metrics_service.receiver]
    job_name       = "integrations/apache-hadoop"
}

prometheus.scrape "integrations_hadoop_nodemanager_prometheus_exporter" {
    targets        = discovery.relabel.hadoop_nodemanager_service_jmx.output
    forward_to     = [prometheus.relabel.metrics_service.receiver]
    job_name       = "integrations/apache-hadoop"
}

prometheus.scrape "integrations_hadoop_resourcemanager_prometheus_exporter" {
    targets        = discovery.relabel.hadoop_resourcemanager_service_jmx.output
    forward_to     = [prometheus.relabel.metrics_service.receiver]
    job_name       = "integrations/apache-hadoop"
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "hadoop_dn_logs" {
    targets = discovery.relabel.pod_logs.output

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<hadoop-namespace>:<hadoop-datanode-pod-name>:<hadoop-datanode-container-name>)"
    }

    rule {
        replacement  = "integrations/apache-hadoop"
        target_label = "job"
    }

    rule {
        replacement  = "<your-hadoop-cluster-name>"
        target_label = "hadoop_cluster"
    }

    rule {
        replacement  = "<match-metrics-datanode-address>"
        target_label = "instance"
    }

    rule {
        replacement  = "datanode"
        target_label = "log_type"
    }
}

discovery.relabel "hadoop_nn_logs" {
    targets = discovery.relabel.pod_logs.output

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<hadoop-namespace>:<hadoop-namenode-pod-name>:<hadoop-namenode-container-name>)"
    }

    rule {
        replacement  = "integrations/apache-hadoop"
        target_label = "job"
    }

    rule {
        replacement  = "<your-hadoop-cluster-name>"
        target_label = "hadoop_cluster"
    }

    rule {
        replacement  = "<match-metrics-namenode-address>"
        target_label = "instance"
    }

    rule {
        replacement  = "namenode"
        target_label = "log_type"
    }
}

discovery.relabel "hadoop_nm_logs" {
    targets = discovery.relabel.pod_logs.output

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<hadoop-namespace>:.<hadoop-nodemanager-pod-name>:<hadoop-nodemanager-container-name>)"
    }

    rule {
        replacement  = "<your-hadoop-cluster-name>"
        target_label = "hadoop_cluster"
    }

    rule {
        replacement  = "<match-metrics-nodemanager-address>"
        target_label = "instance"
    }

    rule {
        replacement  = "integrations/apache-hadoop"
        target_label = "job"
    }

    rule {
        replacement  = "nodemanager"
        target_label = "log_type"
    }
}

discovery.relabel "hadoop_rm_logs" {
    targets = discovery.relabel.pod_logs.output

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<hadoop-namespace>:<hadoop-resourcemanager-pod-name>:<hadoop-resourcemanager-container-name>)"
    }

    rule {
        replacement  = "integrations/apache-hadoop"
        target_label = "job"
    }

    rule {
        replacement  = "<your-hadoop-cluster-name>"
        target_label = "hadoop_cluster"
    }

    rule {
        replacement  = "<match-metrics-resourcemanager-address>"
        target_label = "instance"
    }

    rule {
        replacement  = "resourcemanager"
        target_label = "log_type"
    }
}

loki.source.kubernetes "hadoop_dn_logs" {
    targets      = discovery.relabel.hadoop_dn_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}

loki.source.kubernetes "hadoop_nn_logs" {
    targets      = discovery.relabel.hadoop_nn_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}

loki.source.kubernetes "hadoop_nm_logs" {
    targets      = discovery.relabel.hadoop_nm_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}

loki.source.kubernetes "hadoop_rm_logs" {
    targets      = discovery.relabel.hadoop_rm_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
