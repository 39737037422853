import { WorkloadTypes } from 'components/workloads/WorkloadDetail/metadataUtils';
import {
  IconName,
  PluginExtensionLink as GrafanaPluginExtensionLink,
  RawTimeRange,
  TimeRange,
  PluginExtensionLinkConfig,
  RelativeTimeRange,
} from '@grafana/data';
import { DataQuery, DataSourceRef } from '@grafana/schema';
import { FetchStatus } from 'enums';
import {
  DeepPartial,
  FieldConfigOverridesBuilder,
  PanelBuilders,
  SceneObjectState,
  SceneTimeRangeState,
  VizPanelBuilder,
} from '@grafana/scenes';
import { PodBreakdown } from 'components/workloads/podHealthUtils';
import { DateTimeRange } from 'store/timeRange';

export interface UsageCostData {
  cpuAvg?: number;
  cpuAvgPercent?: number;
  cpuMax?: number;
  cpuMaxPercent?: number;
  memoryAvg?: number;
  memoryAvgPercent?: number;
  memoryMax?: number;
  memoryMaxPercent?: number;
  memoryIdleCurrent?: number;
  memoryIdleProjected?: number;
  cpuIdleCurrent?: number;
  cpuIdleProjected?: number;
  costCurrent?: number;
  costProjected?: number;
}

export interface Container extends UsageCostData {
  cluster: string;
  container: string;
  container_id?: string;
  image_spec: string;
  image_id?: string;
  instance?: string;
  job?: string;
  namespace: string;
  pod?: string;
  uid?: string;
  waiting_reason?: string;
  alertCount: number;
}

export interface Pod extends UsageCostData {
  cluster: string;
  workload: string;
  created_by_kind: string;
  created_by_name: string;
  host_ip: string;
  host_network: string;
  instance: string;
  job: string;
  namespace: string;
  node: string;
  pod: string;
  pod_ip: string;
  uid: string;
  waiting_reason: string | undefined;
  // terminating_reason
  phase: 'Pending' | 'Running' | 'Succeeded' | 'Failed' | 'Unknown';
  containers: Container[];
  cpu_usage: number;
  mem_usage: number;
  workloadName?: string;
  workload_type?: string;
  max_cpu_usage?: number;
  max_mem_usage?: number;
  provider_id?: string;
  provider?: string;
  asserts_env?: string | undefined;
  asserts_site?: string | undefined;
  alertCount?: number;
  timestamp: number;
}

export interface Workload {
  cluster: string;
  namespace: string;
  name: string;
  type: string;
  readyPods: number;
  desiredPods: number;
  podCountByHealth: PodBreakdown;
  alertCount: number;
  asserts_env?: string;
}

export interface WorkloadQueryResult {
  cluster: string;
  namespace: string;
  workload: string;
  workload_type: string;
  asserts_env?: string;
}

export interface Workloads {
  [key: string]: Pod[];
}

export interface ContainersWaiting {
  [key: string]: string;
}

export interface Deployments {
  [key: string]: string;
}

export interface PodCpuUsage {
  [key: string]: number;
}

export interface ContainersByPod {
  [key: string]: Container[];
}

export interface PodInfoResult {
  metric: Pod;
}

export interface CpuUsageResult {
  [key: string]: string;
}

export interface GenericUsageResult {
  [key: string]: string;
}

export interface WaitingQueryResult {
  cluster: string;
  namespace: string;
  pod: string;
  reason: string;
}

export interface WaitingContainerResult {
  uid: string;
  reason: string;
}

export interface ContainerResult {
  container: string;
}

export interface ClusterNamespacePodResult {
  cluster: string;
  namespace: string;
  pod: string;
}

export interface ClusterNodeConditionResult {
  cluster: string;
  node: string;
  condition: string;
}

export interface ClusterNodeResult {
  cluster: string;
  node: string;
}

export interface ClusterResult {
  cluster: string;
}

export interface ReplicasetOwnerResult {
  cluster: string;
  instance: string;
  job: string;
  namespace: string;
  owner_is_controller: string;
  owner_kind: string;
  owner_name: string;
  replicaset: string;
}

export interface GeneralQueryResult<T> {
  metric: T;
  value: [number, string];
}

export interface GeneralRangeQueryResult<T> {
  metric: T;
  values: Array<[number, string]>;
}

export interface NodePods {
  node: number;
}

export interface Node extends UsageCostData {
  __name__?: string;
  cluster: string;
  instance: string;
  job: string;
  node: string;
  condition: string;
  podCount: number;
  cpuUsage: number;
  memoryUsage: number;
  provider_id: string;
  provider: string;
  storageUsage: number;
  asserts_env?: string | undefined;
  asserts_site?: string | undefined;
  alertCount: number;
}

export interface Cluster {
  cluster: string;
}

export interface Labels {
  __name__: string;
  cluster: string;
  instance: string;
  job: string;
  node: string;
  [key: string]: string;
}

export interface ResourceResult {
  resource: string;
}

export interface NodeInfo {
  cluster: string;
  container_runtime_version: string;
  instance: string;
  internal_ip: string;
  job: string;
  kernel_version: string;
  kubelet_version: string;
  kubeproxy_version: string;
  node: string;
  os_image: string;
  pod_cidr: string;
  provider_id: string;
  system_uuid: string;
  asserts_env: string;
  __name__: string;
}

export interface ContainerInfo {
  cluster: string;
  container: string;
  container_id: string;
  host_id: string;
  image: string;
  image_id: string;
  image_spec: string;
  namespace: string;
  node: string;
  owner_kind: string;
  owner_name: string;
  pod: string;
  pod_id: string;
  priority_class: string;
  __name__: string;
}

export interface Namespace extends UsageCostData {
  cluster: string;
  instance: string;
  job: string;
  namespace: string;
  workloads: number;
  phase: string;
  alertCount: number;
  cpu_usage: number;
  cpu_max: number;
  mem_usage: number;
  mem_max: number;
  storage_usage: number;
  storage_max: number;
  asserts_env?: string | undefined;
  asserts_site?: string | undefined;
}

export interface NamespaceAlerts {
  cluster?: string;
  namespace?: string;
}
export interface Log {
  time: number;
  value: string;
}

export type QueryLabelType = 'default' | 'exclude';
export interface QueryLabelSetting {
  type?: QueryLabelType;
}
export interface LogQueryConfig {
  custom?: boolean;
  namespace?: QueryLabelSetting;
  cluster?: QueryLabelSetting;
  pod?: QueryLabelSetting;
  container?: QueryLabelSetting;
}

export interface JSONData {
  grafana_instance_id?: string;
  logQueryConfig?: LogQueryConfig;
  datasources?: {
    lokiName: string;
    prometheusName: string;
  };
}

export interface User {
  email: string;
  id: number;
  isGrafanaAdmin: boolean;
  isSignedIn: boolean;
  orgId: number;
  orgName: string;
  orgRole: OrgRole;
}

export enum OrgRole {
  ADMIN = 'Admin',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
}

export interface Range {
  from: number | string;
  to: number | string;
}

export interface URLParams {
  cluster: string;
  namespace: string;
  workloadType: WorkloadTypes;
  workload: string;
  podName: string;
  node: string;
  [key: string]: string;
}

export interface StoredParams {
  promName?: string;
  lokiName?: string;
  namespace?: string;
  cluster?: string[];
  node?: string[];
  condition?: string;
  nodeProvider?: string;
  clusterProvider?: string;
  page?: string;
  page_size?: string;
  showUnhealthy?: boolean;
  workloadSearch?: string;
  workloadType?: WorkloadTypes | '';
  filterBarOn?: boolean;
}

export interface ClusterDetails {
  cluster: string;
  provider: string;
  container_runtime_version: string;
  internal_ip: string;
  kernel_version: string;
  kubelet_version: string;
  kubeproxy_version: string;
  os_image: string;
  pod_cidr: string;
  node_count: number;
}

export type ObjectTypes = 'cluster' | 'node' | 'namespace' | 'workload' | 'pod' | 'container';

export type ScenesCustomParams = {
  datasource: DataSourceRef;
  prometheusName: string;
  clusterName?: string;
  id?: string;
  lokiDatasource?: DataSourceRef;
  lokiName: string;
  linkClassName?: string;
  dataFlowing?: boolean;
  dataFlowingStatus?: FetchStatus;
  node?: string;
  cluster?: string;
  namespace?: string;
  podName?: string;
  workload?: string;
  container?: string;
  relativeTimeRange?: SceneTimeRangeState;
  range?: DateTimeRange;
  workloadType?: string;
  withPickers?: boolean;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  type?: ObjectTypes;
  isEmbeddedExtension?: boolean;
  locationTitle?: string;
};

export interface CustomSceneObjectDetailState extends SceneObjectState {
  type: ObjectTypes;
  cluster: string;
  node?: string;
  namespace?: string;
  workload?: string;
  workloadType?: string;
  podName?: string;
  container?: string;
  lokiDatasource?: DataSourceRef;
}

export type ScenesRouteParams = {
  node?: string;
  cluster?: string;
  namespace?: string;
  podName?: string;
  workload?: string;
  container?: string;
  workloadType?: string;
};

export interface GenericGetResult<T> {
  data: {
    result: T;
  };
}

export type ObjectWithNumber = {
  [key: string]: {
    [key: string]: number;
  };
};

export type OptionalStringMap = Map<string, string | undefined>;

export type UsageCostErrors = {
  usage: OptionalStringMap;
  cost: OptionalStringMap;
};

export type ParsedUsageCostData = {
  data: ObjectWithNumber;
  errors: OptionalStringMap;
};

export interface WindowBreakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

// Types for plugin extensions.
interface Datasources {
  prometheus: { name: string; uid: string };
  loki: { name: string; uid: string };
}

export interface PluginExtensionLinkClusterContext {
  timeRange: RawTimeRange;
  cluster: string;
  datasources: Datasources;
}

export interface PluginExtensionLinkNamespaceContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  datasources: Datasources;
}

export interface PluginExtensionLinkWorkloadContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  workload: string;
  workloadType: WorkloadTypes;
  datasources: Datasources;
}

export interface PluginExtensionLinkPodContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  workload: string;
  workloadType?: string;
  pod: string;
  datasources: Datasources;
}

export type PluginExtensionLinkContext =
  | PluginExtensionLinkClusterContext
  | PluginExtensionLinkNamespaceContext
  | PluginExtensionLinkWorkloadContext
  | PluginExtensionLinkPodContext;

// Extension points that other plugins can use to hook into the Kubernetes app.
export enum PluginExtensionPoints {
  ClusterAction = 'plugins/grafana-k8s-app/cluster/action',
  NamespaceAction = 'plugins/grafana-k8s-app/namespace/action',
  WorkloadAction = 'plugins/grafana-k8s-app/workload/action',
  PodAction = 'plugins/grafana-k8s-app/pod/action',
}

// Later versions of Grafana support additional fields like category and icon
// which are useful for grouping and displaying links in a more user-friendly way.
export type PluginExtensionLink = GrafanaPluginExtensionLink & {
  icon?: IconName;
  category?: string;
};

export type ExtensionLink = {
  targets: string | string[];
} & Omit<PluginExtensionLinkConfig<{ slug: string }>, 'type' | 'extensionPointId'>;

export interface SceneWithTitle extends SceneObjectState {
  title?: string;
}

export interface CommonUsageData {
  name: string;
  cpu_usage: number;
  cpu_max: number;
  mem_usage: number;
  storage_usage: number;
  storage_max: number;
}

export interface DataTableClusterUsage extends CommonUsageData {
  mem_max: number;
  provider: string;
  nodes: number;
  asserts_env?: string | undefined;
  asserts_site?: string | undefined;
  alertCount: number;
}

export interface NamespaceUsageData extends CommonUsageData {
  name: string;
  cpu_usage: number;
  cpu_max: number;
}

export interface EfficiencyValues {
  [key: string]: number;
  overutilized: number;
  underutilized: number;
  wellutilized: number;
}

export interface EfficiencyOverviewData {
  [key: string]: {
    values: EfficiencyValues;
    count: number | null;
  };
}
export interface EfficiencyOverview {
  data: EfficiencyOverviewData;
}

export interface HasSetOption<K> {
  setOption(option: keyof K, value: DeepPartial<K[keyof K]> | undefined): this;
}

export interface HasSetCustomFieldConfig<K> {
  setCustomFieldConfig(option: keyof K, value: DeepPartial<K[keyof K]> | undefined): this;
}

export interface HasSetOverrides<T> {
  setOverrides(builder: (b: FieldConfigOverridesBuilder<T>) => void): this;
}

type ExtractPanelBuilderOptions<T> = T extends VizPanelBuilder<infer U, any> ? U : never;
type ExtractPanelBuilderFieldConfig<T> = T extends VizPanelBuilder<any, infer U> ? U : never;
export type OptionsType<K> = ExtractPanelBuilderOptions<K>;
export type FieldConfigType<K> = ExtractPanelBuilderFieldConfig<K>;

// Panel Builder Types
export type TimeSeriesPanelType = ReturnType<typeof PanelBuilders.timeseries>;
export type TablePanelType = ReturnType<typeof PanelBuilders.table>;
export type StatPanelType = ReturnType<typeof PanelBuilders.stat>;
export type BarChartPanelType = ReturnType<typeof PanelBuilders.barchart>;
export type GaguePanelType = ReturnType<typeof PanelBuilders.gauge>;

export enum RuleFormType {
  grafana = 'grafana-alerting',
  grafanaRecording = 'grafana-recording',
  cloudAlerting = 'cloud-alerting',
  cloudRecording = 'cloud-recording',
}

export interface AlertDataQuery extends DataQuery {
  maxDataPoints?: number;
  intervalMs?: number;
  expression?: string;
}

export interface AlertQuery<T = AlertDataQuery> {
  refId: string;
  queryType: string;
  relativeTimeRange?: RelativeTimeRange;
  datasourceUid: string;
  model: T;
}

export interface RuleFormValues {
  // common
  name: string;
  type?: RuleFormType;
  dataSourceName: string | null;
  group: string;

  labels: Array<{ key: string; value: string }>;
  annotations: Array<{ key: string; value: string }>;

  // grafana rules
  queries: AlertQuery[];
  condition: string | null; // refId of the query that gets alerted on
  evaluateEvery: string;
  evaluateFor: string;
  isPaused?: boolean;
  metric?: string;

  // cortex / loki rules
  namespace: string;
  forTime: number;
  forTimeUnit: string;
  keepFiringForTime?: number;
  keepFiringForTimeUnit?: string;
  expression: string;
}

export interface DataQueryWithExpr extends DataQuery {
  expr: string;
  selector?: string;
}

export type PredictableParam = {
  button: string;
  title: string;
  name: string;
  metric: string;
  query: string;
  predictableVariableName?: string;
  axisLabel?: string;
  asMenu?: boolean;
};
