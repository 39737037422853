const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "opensearch_metrics" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_pod_label_<pod_label_name>"]
        regex         = "<pod_label_value>"
    }

    rule {
        source_labels = ["__address__"]
        regex         = "([^:]+)(:[0-9]+)?"
        target_label  = "instance"
        replacement   = "\${1}"
    }
}

prometheus.scrape "opensearch_metrics" {
    targets      = discovery.relabel.opensearch_metrics.output
    metrics_path = "/monitoring/prometheus/metrics"
    forward_to   = [prometheus.relabel.integrations_opensearch_cluster_label.receiver]
    job_name     = "integrations/opensearch"
}

prometheus.relabel "integrations_opensearch_cluster_label" {
    forward_to = [prometheus.relabel.metrics_service.receiver]
    
    rule {
      action      = "labelmap"
      regex       = "cluster"
      replacement = "opensearch_cluster"
    }

    rule {
      action = "labeldrop"
      regex  = "cluster"
    }
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "opensearch_logs" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator     = ":"
        regex         = "(<opensearch_namespace>:opensearch.*)"
    }

    rule {
        replacement  = "<your-opensearch-cluster-name>"
        target_label = "opensearch_cluster"
    }

    rule {
        action        = "replace"
        source_labels = ["__meta_kubernetes_pod_ip"]
        target_label  = "instance"
    }

    rule {
        replacement   = "integrations/opensearch" 
        target_label  = "job"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_node_name"]
        target_label  = "node"
    }
}

loki.source.kubernetes "opensearch_logs" {
  targets      = discovery.relabel.opensearch_logs.output
  forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
