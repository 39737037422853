const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "ibm_mq" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_app_<ibm-mq-pod-label>"]
    regex = "<ibm-mq-pod-label-value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "9157"
    action = "keep"
  }
  rule {
    target_label = "mq_cluster"
    replacement = "<ibm-mq-cluster-label>"
  }
  rule {
    source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
    separator = "-"
    target_label = "instance"
  }
}
prometheus.scrape "ibm_mq" {
  job_name     = "integrations/ibm-mq"
  targets      = discovery.relabel.ibm_mq.output
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_ibm_mq" {
  targets = discovery.relabel.pod_logs.output
  rule {
    source_labels = ["__meta_kubernetes_pod_label_app_<ibm-mq-pod-label>"]
    regex = "<ibm-mq-pod-label-value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
    separator = "-"
    target_label = "instance"
  }
  rule {
    replacement = "<your-cluster-name>"
    target_label = "mq_cluster"
  }
  rule {
    replacement = "mq-qmgr-error"
    target_label = "log_type"
  }
}

loki.source.kubernetes "logs_ibm_mq" {
  targets    = discovery.relabel.logs_ibm_mq.output
  forward_to = [loki.process.logs_ibm_mq.receiver]
}

loki.process "logs_ibm_mq" {
  forward_to = [loki.process.logs_service.receiver]
  stage.cri {} 
  stage.multiline {
    firstline = \`^\\s*\\d{2}\\/\\d{2}\\/\\d{2}\\s+\\d{2}:\\d{2}:\\d{2}\\s*-'\`
  }   
}`;
export default { metrics, logs };
