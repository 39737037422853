import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const setsBoxStyles = (allContainersSet: boolean) => (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex: 1;

    > div {
      flex: 1;

      > div:first-of-type {
        display: block;
      }

      > div:last-of-type {
        display: flex;
      }
    }
  `,
  wrapper: css`
    width: 100%;
    margin-top: -32px;
  `,
  topCounter: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  title: css`
    color: ${theme.colors.text.primary};
    font-size: ${theme.typography.h6.fontSize};
    letter-spacing: 0.021px;
  `,
  message: css`
    letter-spacing: 0.021px;
    margin-top: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    font-family: ${theme.typography.fontFamily};
  `,
  titleRow: css`
    padding: ${theme.spacing(1)};
    color: ${theme.colors.text.primary};
    font-size: ${theme.typography.h6.fontSize};
    letter-spacing: 0.021px;
  `,
  icon: css`
    color: ${allContainersSet ? theme.colors.success.main : theme.colors.info.main};
  `,
});

export default setsBoxStyles;
