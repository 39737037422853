const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "integrations_apache_mesos" {
    targets = discovery.kubernetes.endpoints.targets
    rule {
        source_labels = ["__meta_kubernetes_service_name","__meta_kubernetes_endpoint_port_name"]
        separator     = ":"
        regex         = "<your-service-name>:<your-exporter-endpoint-port-name>"
        action        = "keep"
    }
    rule {
        replacement  = "<your-mesos-cluster-name>"
        target_label = "mesos_cluster"
    }
}
prometheus.scrape "integrations_mesos_statsd_exporter" {
    targets    = discovery.relabel.integrations_apache_mesos.output
    forward_to = [prometheus.relabel.metrics_service.receiver]
    job_name   = "integrations/apache-mesos"
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "mesos_master_logs" {
    targets = discovery.relabel.pod_logs.output
    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<mesos-namespace>:<mesos-pod-name>:<mesos-agent-container-name>)"
    }
    rule {
        replacement  = "<your-mesos-cluster-name>"
        target_label = "mesos_cluster"
    }
    rule {
        replacement   = "integrations/mesos" 
        target_label  = "job"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_name"]
        target_label  = "node"
    }
    rule {
        replacement = "master"
        target_label = "log_type"
    }
}
discovery.relabel "mesos_agent_logs" {
    targets = discovery.relabel.pod_logs.output
    
    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<mesos-namespace>:<mesos-pod-name>:<mesos-agent-container-name>)"
    }
    rule {
        replacement  = "<your-mesos-cluster-name>"
        target_label = "mesos_cluster"
    }
    rule {
        replacement   = "integrations/mesos" 
        target_label  = "job"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_name"]
        target_label  = "node"
    }
    rule {
        replacement  = "agent"
        target_label = "log_type"
    }
}

loki.source.kubernetes "mesos_master_logs" {
    targets      = discovery.relabel.mesos_master_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}

loki.source.kubernetes "mesos_agent_logs" {
    targets      = discovery.relabel.mesos_agent_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
