const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "integrations_couchdb" {
    targets = discovery.kubernetes.endpoints.targets

    rule {
        source_labels = ["__meta_kubernetes_service_name","__meta_kubernetes_endpoint_port_name"]
        separator     = ":"
        regex         = ".*-couchdb:metrics"
        action        = "keep"
    }

    rule {
        replacement  = "<cluster-name>"
        target_label = "couchdb_cluster"
    }
}

prometheus.scrape "integrations_couchdb" {
    targets      = discovery.relabel.integrations_couchdb.output
    metrics_path = "/_node/_local/_prometheus"
    forward_to   = [prometheus.relabel.metrics_service.receiver]
    job_name     = "integrations/couchdb"
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "couchdb_logs" {
    targets = discovery.kubernetes.pods.targets
    
    rule {
        source_labels = ["__meta_kubernetes_namespace","__meta_kubernetes_pod_name","__meta_kubernetes_pod_container_port_name"]
        separator     = ":"
        regex         = "sample-apps:.*-couchdb.*:metrics"
        action        = "keep"
    }

    rule {
        replacement = "<cluster-name>"
        target_label = "couchdb_cluster"
    }

    rule {
        replacement   = "integrations/couchdb" 
        target_label  = "job"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
        separator = ":"
        regex = "(.*):(.*)"
        replacement = "$1:$2"
        target_label  = "instance"
    }

    rule {
        replacement = "couchdb"
        target_label = "log_type"
    }
  }

loki.source.kubernetes "couchdb_logs" {
    targets      = discovery.relabel.couchdb_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
