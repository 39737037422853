import { getDefaultLoki, getDefaultProm } from 'helpers/datasource';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type StateType = {
  prometheusName: string;
  lokiName: string;
};

type ActionType = {
  setPrometheusName: (name: string) => void;
  setLokiName: (name: string) => void;
  setInitialSources: () => void;
};

export type DatasourceStore = StateType & ActionType;

const initialState = {
  prometheusName: getDefaultProm(),
  lokiName: getDefaultLoki(),
};

export const setPrometheusName = (name: string) => {
  useDatasourceStore.setState({ prometheusName: name });
};

const useDatasourceStore = createWithEqualityFn<DatasourceStore>(
  (set) => ({
    //state
    ...initialState,

    // actions
    setPrometheusName,

    setLokiName: (name: string) => {
      set({ lokiName: name });
    },

    setInitialSources: () => {
      set({
        prometheusName: getDefaultProm(),
        lokiName: getDefaultLoki(),
      });
    },
  }),
  shallow
);

export default useDatasourceStore;
