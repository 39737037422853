const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "integrations_wildfly" {
    targets = discovery.kubernetes.endpoints.targets

    rule {
        source_labels = ["__meta_kubernetes_service_name"]
        regex = "wildfly"
        action = "keep"
    }
}

prometheus.scrape "integrations_wildfly" {
  targets    = discovery.relabel.integrations_wildfly.output
  forward_to = [prometheus.relabel.metrics_service.receiver]
  job_name   = "integrations/wildfly"
  basic_auth {
    username = "<wildfly_user>"
    password = "<wildfly_password>"
  }
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
loki.process "wildfly_logs_process" {
    forward_to = [loki.process.logs_service.receiver]

    stage.cri {}
    stage.multiline {
        firstline = "\\\\d+:\\\\d+:\\\\d+,\\\\d+"
        max_lines     = 0
        max_wait_time = "3s"
    }
}

discovery.relabel "wildfly_logs" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator     = ":"
        regex         = "(<wildfly_namespace>:wildfly.*)"
    }

    rule {
        replacement   = "integrations/wildfly" 
        target_label  = "job"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
        separator     = ":"
        regex         = "(.*):(.*)"
        replacement   = "$1:$2"
        target_label  = "instance"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_node_name"]
        target_label  = "host"
    }

    rule {
        target_label = "log_type"
        replacement  = "wildfly"
    }
}

loki.source.kubernetes "wildfly_logs" {
    targets      = discovery.relabel.wildfly_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
