const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "kafka_broker" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<kafka_broker_pod_label>"]
    regex = "<kafka_broker_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<kafka_broker_pod_jmx_port>"
    action = "keep"
  }
  rule {
    replacement = "<your_kafka_broker_instance_name>"
    target_label = "instance"
  }
  rule {
    replacement = "<your_kafka_cluster_name>"
    target_label = "kafka_cluster"
  }
}

prometheus.scrape "kafka_broker" {
  job_name     = "integrations/kafka"
  targets      = discovery.relabel.kafka_broker.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

discovery.relabel "kafka_zookeeper" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<zookeeper_pod_label>"]
    regex = "<zookeeper_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<zookeeper_pod_jmx_port>"
    action = "keep"
  }
  rule {
    replacement = "<your_zookeeper_instance_name>"
    target_label = "instance"
  }
  rule {
    replacement = "<your_kafka_cluster_name>"
    target_label = "kafka_cluster"
  }
}

prometheus.scrape "kafka_zookeeper" {
  job_name     = "integrations/kafka"
  targets      = discovery.relabel.kafka_zookeeper.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

discovery.relabel "kafka_connect" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<kafka_connect_pod_label>"]
    regex = "<kafka_connect_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<kafka_connect_pod_jmx_port>"
    action = "keep"
  }
  rule {
    replacement = "<your_kafka_connect_instance_name>"
    target_label = "instance"
  }
  rule {
    replacement = "<your_kafka_cluster_name>"
    target_label = "kafka_cluster"
  }
}

prometheus.scrape "kafka_connect" {
  job_name     = "integrations/kafka"
  targets      = discovery.relabel.kafka_connect.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

discovery.relabel "kafka_schemaregistry" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<kafka_schemaregistry_pod_label>"]
    regex = "<kafka_schemaregistry_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<kafka_schemaregistry_pod_jmx_port>"
    action = "keep"
  }
  rule {
    replacement = "<your_kafka_schemaregistry_instance_name>"
    target_label = "instance"
  }
  rule {
    replacement = "<your_kafka_cluster_name>"
    target_label = "kafka_cluster"
  }
}

prometheus.scrape "kafka_schemaregistry" {
  job_name     = "integrations/kafka"
  targets      = discovery.relabel.kafka_schemaregistry.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

discovery.relabel "kafka_ksqldb" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<kafka_ksqldb_pod_label>"]
    regex = "<kafka_ksqldb_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<kafka_ksqldb_pod_jmx_port>"
    action = "keep"
  }
  rule {
    replacement = "<your_kafka_ksqldb_instance_name>"
    target_label = "instance"
  }
}

prometheus.scrape "kafka_ksqldb" {
  job_name     = "integrations/kafka"
  targets      = discovery.relabel.kafka_ksqldb.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

//instantiate one exporter per kafka broker
//for detailed configuration options, please check https://grafana.com/docs/agent/latest/flow/reference/components/prometheus.exporter.kafka/
prometheus.exporter.kafka "kafka_exporter" {
  kafka_uris = "<your_kafka_broker_uri>"
  instance   = "<your_kafka_broker_instance_name>"
}

prometheus.scrape "kafka_exporter" {
  targets      = prometheus.exporter.kafka.kafka_exporter.targets
  job_name     = "integrations/kafka"
  forward_to   = [prometheus.relabel.kafka_exporter.receiver]
}

prometheus.relabel "kafka_exporter" {
  rule {
    replacement = "<your_kafka_cluster_name>"
    target_label = "kafka_cluster"
  }  
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
