const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "aerospike" {
    targets = discovery.kubernetes.pods.targets
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<aerospike_pod_label>"]
        regex = "<aerospike_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<aerospike_pod_port_number>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "aerospike_cluster"
    }
    rule {
        replacement = "integrations/aerospike"
        target_label = "job"
    }
}

prometheus.scrape "metrics_aerospike" {        
    targets      = discovery.relabel.aerospike.output
    honor_labels = true
    forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_aerospike" {
    targets = discovery.relabel.pod_logs.output    
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<aerospike_pod_label>"]
        regex = "<aerospike_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "aerospike_cluster"
    }
    rule {
        replacement = "integrations/aerospike"
        target_label = "job"
    }
}

local.file_match "logs_aerospike" {
    path_targets = discovery.relabel.logs_aerospike.output
}

loki.source.file "logs_aerospike" {
    targets    = local.file_match.logs_aerospike.targets
    forward_to = [loki.process.logs_aerospike.receiver]
}

loki.process "logs_aerospike" {
    forward_to = [loki.process.logs_service.receiver]

    stage.multiline {
        firstline     = "\\\\w{3} \\\\d{2} \\\\d{4}"
        max_lines     = 0
        max_wait_time = "3s"
    }

    stage.regex {
        expression = "\\\\w{3} \\\\d{2} \\\\d{4} \\\\d{2}:\\\\d{2}:\\\\d{2} \\\\w{3}: (?P<level>\\\\w+) \\\\((?P<context>\\\\w+)\\\\): (?P<trace>\\\\(\\\\S+\\\\))\\\\s+(?P<message>(?s:.*))$"
    }

    stage.labels {
        values = {
            context = null,
            level   = null,
        }
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }
}`;

export default { metrics, logs };
