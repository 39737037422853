const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "apache_cassandra" {
    targets = discovery.kubernetes.pods.targets
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<apache_cassandra_pod_label>"]
        regex = "<apache_cassandra_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<apache_cassandra_jmx_port_number>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "cassandra_cluster"
    }
    rule {
        replacement = "integrations/apache-cassandra"
        target_label = "job"
    }
}

prometheus.scrape "metrics_apache_cassandra" {
    targets      = discovery.relabel.apache_cassandra.output
    honor_labels = true
    forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_apache_cassandra" {
    targets = discovery.relabel.pod_logs.output
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<apache_cassandra_pod_label>"]
        regex = "<apache_cassandra_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "cassandra_cluster"
    }
    rule {
        replacement = "integrations/apache-cassandra"
        target_label = "job"
    }
}

local.file_match "logs_apache_cassandra" {
    path_targets = discovery.relabel.logs_apache_cassandra.output
}

loki.source.file "logs_apache_cassandra" {
    targets    = local.file_match.logs_apache_cassandra.targets
    forward_to = [loki.process.logs_apache_cassandra.receiver]
}

loki.process "logs_apache_cassandra" {
    forward_to = [loki.process.logs_service.receiver]

    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }
}`;

export default { metrics, logs };
