const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "nginx_pod_logs" {
    targets = discovery.relabel.pod_logs.output
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<nginx_label>"]
        regex = "<nginx_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
}  

local.file_match "nginx_pod_logs" {
    path_targets = discovery.relabel.nginx_pod_logs.output
}

loki.source.file "nginx_pod_logs" {
    targets    = local.file_match.nginx_pod_logs.targets
    forward_to = [loki.process.nginx_pod_logs.receiver]
}    

loki.process "nginx_pod_logs" {
    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }

    stage.static_labels {
        values = {
            job = "integrations/nginx",
        }
    }
    forward_to = [loki.process.logs_service.receiver]
}`;

export default { logs };
