import React, { lazy, Suspense } from 'react';

import { usePluginContext } from '@grafana/data';
import { PluginMetaContext } from 'context/PluginMetaContext';
import Loader from 'components/Loader/Loader';

const ClusterConfig = lazy(() => import('components/scenes/Config/ClusterConfig/ClusterConfig'));

export function ClusterConfigExtension() {
  const context = usePluginContext();
  return (
    context && (
      <PluginMetaContext.Provider value={context.meta}>
        <Suspense fallback={<Loader />}>
          <ClusterConfig />
        </Suspense>
      </PluginMetaContext.Provider>
    )
  );
}

export const ClusterConfigExtensionParams = {
  title: 'K8s Cluster Config',
  description: 'K8s Cluster configuration settings',
  targets: 'grafana/grafana-k8s-app/cluster-config/v1',
  component: ClusterConfigExtension,
};
