const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.mssql "integrations_mssql" {
    connection_string = "sqlserver://<db_user>:<db_password>@<mssql_service>.<mssql_namespace>.svc.cluster.local:1433"
}

prometheus.scrape "integrations_mssql" {
  targets    = prometheus.exporter.mssql.integrations_mssql.targets
  forward_to = [prometheus.relabel.metrics_service.receiver]
  job_name   = "integrations/mssql"
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
loki.process "mssql_logs_process" {
    forward_to = ["discovery.relabel.mssql_logs.receiver"]

    stage.cri {}
    stage.multiline {
        firstline = "^\\\\s*\\\\d{4}-\\\\d{2}-\\\\d{2}\\\\s+\\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d+"
    }
    stage.regex {
        expression = "^(?s)(?P<timestamp>\\\\s*\\\\d{4}-\\\\d{2}-\\\\d{2}\\\\s+\\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d+)\\\\s+(?P<component>\\\\w+)\\\\s+(?P<message>.*)$"
    }
    stage.timestamp {
        format = "RFC3339Nano"
        source = "timestamp"
    }
    stage.static_labels {
        values = {
            log_type = "mssql_error",
            job = "integrations/mssql",
            instance = "<mssql_service>.<mssql_namespace>.svc.cluster.local:1433",
        }
    }
    stage.labels {
        values = {
            message = "message",
            component = "component",
        }
    }
}

discovery.relabel "mssql_logs" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator     = ":"
        regex         = "(<mssql_namespace>:<mssql_pod_prefix>.*)"
    }

    rule {
        replacement   = "integrations/mssql" 
        target_label  = "job"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_node_name"]
        target_label  = "host"
    }
}

loki.source.kubernetes "mssql_logs" {
    targets      = discovery.relabel.mssql_logs.output
    forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
