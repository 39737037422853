import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const pastDataAlert = (theme: GrafanaTheme2) => {
  return {
    alertWrapper: css`
      display: 'flex';
      width: '100%';
      margin-top: ${theme.spacing(1)};
      margin-bottom: -${theme.spacing(2)};

      [class$='-Icon'] {
        margin-top: 3px;
      }
    `,
    button: css`
      margin-left: ${theme.spacing(2)};

      &:hover {
        background-color: ${theme.colors.primary.main};
        box-shadow: none;
      }
    `,
    dateTime: css`
      text-transform: capitalize;
    `,
  };
};

export default pastDataAlert;
