import {
  SceneComponentProps,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
  sceneGraph,
} from '@grafana/scenes';
import { Icon, PanelChrome, useStyles2 } from '@grafana/ui';
import { getValueFromSeries } from 'helpers/scenes';
import React from 'react';
import setsBoxStyles from './SetsBox.styles';
import { LoadingState } from '@grafana/data';

interface SetsBoxState extends SceneObjectState {
  numContainers: string;
  title: string;
  type: string;
}

export class SetsBox extends SceneObjectBase<SetsBoxState> {
  static Component = SetsBoxRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['numContainers'],
  });

  constructor(state?: Partial<SetsBoxState>) {
    super({ numContainers: '0', title: '', type: '', ...state });
  }
}

const messageMap = {
  cpuRequests: {
    all: 'All container CPU requests set',
    notAll: 'Not all container CPU requests set',
    href: '',
  },
  cpuLimits: {
    all: 'All container CPU limits set',
    notAll: 'Read more about CPU limits',
    href: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/optimize-resource-usage/container-requests-limits-cpu/',
  },
  memoryRequests: {
    all: 'All container memory requests set',
    notAll: 'Not all container memory requests set',
    href: '',
  },
  memoryLimits: {
    all: 'All container memory limits set',
    notAll: 'Not all container memory limits set',
    href: '',
  },
};

function SetsBoxRenderer({ model }: SceneComponentProps<SetsBox>) {
  const { title, type } = model.useState();
  const data = sceneGraph.getData(model).useState();
  const numContainers = sceneGraph.lookupVariable('numContainers', model);
  const totalContainers = numContainers?.getValueText?.().split(' ')?.[1];
  const currentContainers = getValueFromSeries(data?.data?.series, 0);
  const allContainersSet = currentContainers?.toString() === totalContainers?.toString() && Number(totalContainers) > 0;
  const styles = useStyles2(setsBoxStyles(allContainersSet));

  const loading = numContainers?.state.loading || data.data?.state === LoadingState.Loading;
  const messageType = messageMap[type as keyof typeof messageMap];
  const message = allContainersSet ? messageType?.all : messageType?.notAll;

  return (
    <div className={styles.container}>
      <PanelChrome
        loadingState={loading ? LoadingState.Loading : LoadingState.Done}
        height={100}
        // @ts-ignore
        width="100%"
        titleItems={loading && <span className={styles.titleRow}>{title}</span>}
      >
        {(innerwidth, innerheight) => {
          if (loading) {
            return null;
          }

          return (
            <div className={styles.wrapper} style={{ width: innerwidth, height: innerheight, flex: 1 }}>
              <div className={styles.topCounter}>
                <span className={styles.title}>{title}</span>
                <span>
                  {currentContainers} / {totalContainers} containers
                </span>
              </div>
              {message && (
                <div className={styles.message}>
                  <Icon name={allContainersSet ? 'check-circle' : 'info-circle'} className={styles.icon} />
                  {messageType.href ? (
                    <a href={messageType.href} target="_blank" rel="noreferrer">
                      {message}
                    </a>
                  ) : (
                    <span>{message}</span>
                  )}
                </div>
              )}
            </div>
          );
        }}
      </PanelChrome>
    </div>
  );
}
