const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "etcd" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_<service label>"]
    regex = "<service label value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_port_number"]
    regex = "2379"
    action = "keep"
  }
}

prometheus.scrape "etcd" {
  job_name     = "integrations/etcd"
  targets      = discovery.relabel.etcd.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
