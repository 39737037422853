const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "pgbouncer" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_app_kubernetes_io_name"]
    regex = "pgbouncer"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<pgbouncer_prometheus_port_number>"
    action = "keep"
  }
  rule {
    target_label = "pgbouncer_cluster"
    replacement = "<pgbouncer_cluster_label>"
  }
  rule {
    source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
    separator = "-"
    target_label = "instance"
  }
  rule {
    replacement = "integrations/pgbouncer"
    target_label = "job"
  }
}
prometheus.scrape "pgbouncer" {
  targets      = discovery.relabel.pgbouncer.output
  metrics_path = "/metrics"
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_pgbouncer" {
  targets = discovery.relabel.pod_logs.output
  rule {
    source_labels = ["__meta_kubernetes_pod_label_app_kubernetes_io_name"]
    regex = "pgbouncer"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
    separator = "-"
    target_label = "instance"
  }
  rule {
    replacement = "integrations/pgbouncer"
    target_label = "job"
  }
}

loki.source.kubernetes "logs_pgbouncer" {
  targets    = discovery.relabel.logs_pgbouncer.output
  forward_to = [loki.process.logs_pgbouncer.receiver]
}

loki.process "logs_pgbouncer" {
  forward_to = [loki.process.logs_service.receiver]
  stage.cri {}
  stage.drop {
    expression = "^ts"
  }
  stage.multiline {
    firstline = \`\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2}\\.\\d{3}\`
  }
  stage.regex {
    expression = \`\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2}\\.\\d{3} \\w+ \\[\\d+\\] (?P<level>LOG|ERROR|WARNING|INFO|DEBUG) .*\`
  }
  stage.labels {
    values = {
      level  = "",
    }
  }
}`;
export default { metrics, logs };
