import { MatchingOperator } from '../types';
import { Expression } from '../expression';
import { promql } from '../promql';

type cluster = { cluster?: string };
type namespace = { namespace?: string };
type node = { node?: string };
type pod = { pod?: string };
type container = { container?: string };

type kube_pod_container_resource_labels = {
  resource: 'cpu' | 'memory';
} & cluster &
  namespace &
  node &
  pod &
  container;

export const ksm = {
  metrics: {
    kube_pod_container_resource_limits: (values: kube_pod_container_resource_labels) =>
      promql.max({
        by: ['cluster', 'node', 'namespace', 'pod', 'container', 'resource'],
        expr: new Expression({
          metric: 'kube_pod_container_resource_limits',
          values,
          defaultOperator: MatchingOperator.regexMatch,
          defaultSelectors: [{ label: 'container', operator: MatchingOperator.notEqual, value: '' }],
        }).toString(),
      }),

    kube_pod_container_resource_requests: (values: kube_pod_container_resource_labels) =>
      promql.max({
        by: ['cluster', 'node', 'namespace', 'pod', 'container', 'resource'],
        expr: new Expression({
          metric: 'kube_pod_container_resource_requests',
          values,
          defaultOperator: MatchingOperator.regexMatch,
          defaultSelectors: [{ label: 'container', operator: MatchingOperator.notEqual, value: '' }],
        }).toString(),
      }),
  },

  rules: {
    'namespace_cpu:kube_pod_container_resource_limits:sum': (values: cluster & namespace) =>
      promql.max({
        by: ['cluster', 'namespace'],
        expr: new Expression({
          metric: 'namespace_cpu:kube_pod_container_resource_limits:sum',
          values,
          defaultOperator: MatchingOperator.regexMatch,
        }).toString(),
      }),

    'namespace_cpu:kube_pod_container_resource_requests:sum': (values: cluster & namespace) =>
      promql.max({
        by: ['cluster', 'namespace'],
        expr: new Expression({
          metric: 'namespace_cpu:kube_pod_container_resource_requests:sum',
          values,
          defaultOperator: MatchingOperator.regexMatch,
        }).toString(),
      }),

    'namespace_memory:kube_pod_container_resource_limits:sum': (values: cluster & namespace) =>
      promql.max({
        by: ['cluster', 'namespace'],
        expr: new Expression({
          metric: 'namespace_memory:kube_pod_container_resource_limits:sum',
          values,
          defaultOperator: MatchingOperator.regexMatch,
        }).toString(),
      }),

    'namespace_memory:kube_pod_container_resource_requests:sum': (values: cluster & namespace) =>
      promql.max({
        by: ['cluster', 'namespace'],
        expr: new Expression({
          metric: 'namespace_memory:kube_pod_container_resource_requests:sum',
          values,
          defaultOperator: MatchingOperator.regexMatch,
        }).toString(),
      }),
  },
};
