import React from 'react';
import { Alert, Button, useStyles2 } from '@grafana/ui';
import { shallow } from 'zustand/shallow';

import getStyles from './PastDataAlert.styles';
import useTimeRangeStore from 'store/timeRange';
import { dateTime } from '@grafana/data';
import { SceneTimeRange } from '@grafana/scenes';
import { DEFAULT_INITIAL_TIME_RANGE, DEFAULT_TIME_AMOUNT, DEFAULT_TIME_UNIT } from '../../constants';

interface Props {
  className?: string;
  sceneTimeRange: SceneTimeRange;
}

const PastDataAlert = ({ sceneTimeRange }: Props) => {
  const [range, hasDateChanged] = useTimeRangeStore((state) => [state.range, state.hasDateChanged], shallow);
  const styles = useStyles2(getStyles);

  if (!hasDateChanged) {
    return null;
  }

  const onClick = () => {
    const range = {
      from: dateTime().subtract(DEFAULT_TIME_AMOUNT, DEFAULT_TIME_UNIT),
      to: dateTime(),
    };

    sceneTimeRange.onTimeRangeChange({
      ...range,
      raw: DEFAULT_INITIAL_TIME_RANGE,
    });
  };

  return (
    <div className={styles.alertWrapper}>
      <Alert title="" severity="info">
        <span>
          You are currently viewing:{' '}
          <span className={styles.dateTime}>{range.from.format('dddd, MMMM DD, YYYY HH:mm')}</span>
          {' - '}
          <span className={styles.dateTime}>{range.to.format('dddd, MMMM DD, YYYY HH:mm')}.</span>
        </span>
        <Button type="button" variant="primary" className={styles.button} onClick={onClick}>
          Back to default
        </Button>
      </Alert>
    </div>
  );
};

export default PastDataAlert;
