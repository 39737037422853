const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "coredns" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<coredns_pod_label>"]
    regex = "<coredns_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_number"]
    regex = "<coredns_prometheus_port_number>"
    action = "keep"
  }  
  rule {
    source_labels = ["__meta_kubernetes_pod_name"]
    target_label = "instance"
  }
}

prometheus.scrape "coredns" {
  job_name     = "integrations/coredns"
  targets      = discovery.relabel.coredns.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
