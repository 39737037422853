import { useEffect, useState } from 'react';

type StorageOptions = {
  usePreviousValue?: boolean;
};

function useLocalStorage<T>(key: string): [T, (s: T, opt?: StorageOptions) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      return {};
    }
  });

  const currentValue = window.localStorage.getItem(key);
  useEffect(() => {
    const item = window.localStorage.getItem(key);
    setStoredValue(item ? JSON.parse(item) : {});
  }, [currentValue, key]);

  const setValue = (value: T, { usePreviousValue = true } = {}) => {
    try {
      let valueToStore: T;

      const item = window.localStorage.getItem(key);
      if (usePreviousValue && item) {
        valueToStore = { ...JSON.parse(item), ...value };
      } else {
        valueToStore = { ...value };
      }

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('local storage error', error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
