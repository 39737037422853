import { MatcherConfig, ThresholdsMode } from '@grafana/data';
import { PanelBuilders, SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';
import {
  AxisColorMode,
  AxisPlacement,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
  GraphDrawStyle,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LineInterpolation,
  PercentChangeColorMode,
  ScaleDistribution,
  StackingMode,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import { StatPanelType, TimeSeriesPanelType } from 'types';

export type PanelConfig = {
  title: string;
  runner?: SceneQueryRunner | SceneDataTransformer;
  decimals?: number;
  axisLabel?: string;
  calcs?: string[];
  description?: string;
  type?: string | ((panel: PanelConfig) => object);
  links?: Array<{ title: string; url: string }>;
  graphMode?: string;
  unit?: string;
  textSize?: number;
  fixedColor?: string;
  overrides?: Array<{
    matcher: MatcherConfig;
    properties: Array<{
      id: string;
      value?: unknown;
    }>;
  }>;
};

export function getPanelConfig(type: string) {
  const panelTypes = {
    cost: costsConfig,
    percentage: percentageConfig,
    graph: graphsConfig,
  };

  return panelTypes[type as keyof typeof panelTypes];
}

export const percentageConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    $data: panel.runner,
    description: panel.description,
    fieldConfig: {
      defaults: {
        color: {
          mode: 'thresholds',
        },
        decimals: panel.decimals ?? 2,
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Percentage,
          steps: [
            {
              color: 'blue',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
        unit: 'percentunit',
      },
      overrides: [],
    },
    options: {
      colorMode: BigValueColorMode.None,
      graphMode: BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      orientation: VizOrientation.Auto,
      text: {
        valueSize: 40,
      },
      reduceOptions: {
        calcs: ['last'],
        fields: '',
        values: false,
      },
      textMode: BigValueTextMode.Value,
    },
    pluginId: 'stat',
    title: panel.title,
  }).setData(panel.runner);
};

export const costsConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    $data: panel.runner,
    description: panel.description,
    fieldConfig: {
      defaults: {
        color: {
          mode: 'thresholds',
        },
        decimals: panel.decimals,
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
        unit: 'currencyUSD',
        links: panel.links ?? [],
      },
      overrides: panel.overrides ?? [],
    },
    options: {
      colorMode: BigValueColorMode.None,
      graphMode: (panel.graphMode as BigValueGraphMode) ?? BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      orientation: VizOrientation.Auto,
      text: {
        valueSize: panel.textSize ?? 40,
      },
      reduceOptions: {
        calcs: ['sum'],
        fields: '',
        values: false,
      },
      textMode: BigValueTextMode.Value,
    },
    pluginId: 'stat',
    title: panel.title,
  }).setData(panel.runner);
};

export const graphsConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();

  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    pluginId: 'timeseries',
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    fieldConfig: {
      defaults: {
        color: {
          mode: 'palette-classic',
        },
        custom: {
          axisCenteredZero: false,
          axisColorMode: AxisColorMode.Text,
          axisLabel: panel.axisLabel,
          axisPlacement: AxisPlacement.Auto,
          barAlignment: 0,
          drawStyle: GraphDrawStyle.Line,
          fillOpacity: 25,
          gradientMode: GraphGradientMode.None,
          hideFrom: {
            legend: false,
            tooltip: false,
            viz: false,
          },
          lineInterpolation: LineInterpolation.Linear,
          lineStyle: {
            fill: 'solid',
          },
          lineWidth: 1,
          pointSize: 5,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          showPoints: VisibilityMode.Auto,
          spanNulls: false,
          stacking: {
            group: 'A',
            mode: StackingMode.None,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
        unit: 'currencyUSD',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export function totalCostConfig(panel: PanelConfig) {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'dark-blue',
          mode: 'fixed',
        },
        min: 0,
        unit: 'currencyUSD',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Auto,
      wideLayout: true,
      colorMode: BigValueColorMode.BackgroundSolid,
      graphMode: BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      showPercentChange: false,
      percentChangeColorMode: PercentChangeColorMode.Standard,
    },
    title: panel.title,
    pluginId: 'stat',
    description: panel.description || '',
    $data: panel.runner,
  }).setData(panel.runner);
}

export function costPercentChangeConfig(panel: PanelConfig) {
  const panelBuilder = PanelBuilders.stat();
  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'transparent',
          mode: 'fixed',
        },
        min: 0,
        noValue: '-',
        unit: 'percentunit',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Auto,
      wideLayout: true,
      colorMode: BigValueColorMode.BackgroundSolid,
      graphMode: BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      showPercentChange: false,
      percentChangeColorMode: PercentChangeColorMode.Standard,
    },
    title: panel.title || '',
    pluginId: 'stat',
    $data: panel.runner,
  }).setData(panel.runner);
}

export function countPodsConfig(panel: PanelConfig) {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    description: '',
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: panel.fixedColor || 'dark-blue',
          mode: 'fixed',
        },
        min: 0,
        noValue: '-',
        unit: 'short',
        decimals: 0,
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['mean'],
        fields: '',
      },
      text: {
        valueSize: 40,
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Auto,
      wideLayout: true,
      colorMode: BigValueColorMode.BackgroundSolid,
      graphMode: BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      showPercentChange: false,
      percentChangeColorMode: PercentChangeColorMode.Standard,
    },
    title: panel.title,
    pluginId: 'stat',
    $data: panel.runner,
  }).setData(panel.runner);
}
