export enum TableType {
  Cluster = 'cluster',
  Namespace = 'namespace',
  Workload = 'workload',
  Resources = 'resources',
  Node = 'node',
}

export enum SourceType {
  HostedExporter = 'hosted-exporter',
  Instance = 'instance',
  Integration = 'integration',
  CloudApp = 'cloud-app',
  DataSource = 'data-source',
}

export enum RudderstackEvents {
  IntegrationUpdate = 'cloud_k8s_clicked_update_integration',
  IntegrationInstall = 'cloud_k8s_clicked_install_integration',
  SetupClickedTest = 'cloud_k8s_clicked_test_setup',
  SetupOpenAgent = 'cloud_k8s_clicked_open_agent_instructions',
  SetupClickedSaveConfig = 'cloud_k8s_clicked_finish_setup',
  SetupStart = 'cloud_k8s_clicked_start_setup',
  ManualSetupNoData = 'cloud_k8s_clicked_manual_setup_no_data',
  AgentDocsNoData = 'cloud_k8s_clicked_agent_docs_no_data',
  CopyItem = 'cloud_k8s_clicked_copy_string',
  ExploreLogs = 'cloud_k8s_clicked_explore_logs',
  ExploreDashboards = 'cloud_k8s_clicked_explore_dashboards',
  IntegrationUninstall = 'cloud_k8s_clicked_integration_uninstall',
  DashboardListClick = 'cloud_k8s_clicked_dashboard_list',
  NamespaceListClick = 'cloud_k8s_clicked_namespace',
  WorkloadListClick = 'cloud_k8s_clicked_workload',
  PodListClick = 'cloud_k8s_clicked_pod',
  ExploreNamespaces = 'cloud_k8s_clicked_explore_namespaces',
  ExploreWorkloads = 'cloud_k8s_clicked_explore_workloads',
  ExploreClusters = 'cloud_k8s_clicked_explore_clusters',
  ExploreAlerts = 'cloud_k8s_clicked_explore_alerts',
  ExplorePod = 'cloud_k8s_clicked_explore_pod',
  ExplorePodStartTime = 'cloud_k8s_clicked_explore_pod_start_time',
  ExplorePodRestarts = 'cloud_k8s_clicked_explore_pod_restarts',
  ExploreContainers = 'cloud_k8s_clicked_explore_containers',
  ExploreContainer = 'cloud_k8s_clicked_explore_container',
  ExploreWorkload = 'cloud_k8s_clicked_explore_workload',
  ExploreDeployments = 'cloud_k8s_clicked_explore_deployments',
  ExplorePodStatus = 'cloud_k8s_clicked_explore_pod_status',
  RunOutlierDetection = 'cloud_k8s_clicked_outlier_detection',
  RunPredictionModel = 'cloud_k8s_clicked_prediction_model',
  OpenedAgentConfig = 'cloud_k8s_clicked_open_agent_config',
  OpenedOperatorConfig = 'cloud_k8s_clicked_open_operator_config',
  InstalledK8sIntegration = 'cloud_k8s_clicked_install_integration',
  IntegrationTest = 'cloud_k8s_clicked_test_integration',
  AppO11YNavigateToServiceClick = 'cloud_k8s_appo11y_navigate_to_service',
  AppO11YPopOverClick = 'cloud_k8s_appo11y_popover',
  ChangeRefreshPicker = 'cloud_k8s_clicked_change_refresh_picker',
  TitleNavigationMenuClick = 'cloud_k8s_title_navigation_menu_click',
  TitleNavigationMenuItemClick = 'cloud_k8s_title_navigation_menu_item_click',
}

export enum PodHealthStatus {
  Healthy,
  Unhealthy,
  Warning,
  Unknown,
}

export enum FetchStatus {
  Idle,
  Fetching,
  Success,
  Error,
  InstallRollbackError,
}

export enum AgentOptions {
  Alloy,
  AgentInStaticMode,
  AgentOperator,
}

export enum TokenOptions {
  NewToken,
  ExistingToken,
}

export enum IntegrationConnectionErrorCause {
  NoMetricsFound = 'No metrics were found for this integration.',
  CannotScrapeMetrics = "Unable to reach the integration's metrics endpoint.",
  NoLogsFound = 'No logs were found for this integration.',
  Unexpected = 'An unexpected error occurred. Please try again.',
}

export enum Platforms {
  Kubernetes = 'kubernetes',
  Openshift = 'openshift',
  Fargate = 'fargate',
  IBMCloud = 'ibmcloud',
  Autopilot = 'autopilot',
  AzureAKS = 'azureaks',
}

export enum DeploymentMethods {
  Helm = 'helm',
  Terraform = 'terraform',
}
