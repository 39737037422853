import { ThresholdsMode } from '@grafana/data';
import { PanelConfig } from '../CostsOverviewScene/panelConfig';
import { PanelBuilders } from '@grafana/scenes';
import { TimeSeriesPanelType } from 'types';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import {
  AxisColorMode,
  AxisPlacement,
  GraphDrawStyle,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  SortOrder,
  StackingMode,
  TooltipDisplayMode,
  VisibilityMode,
} from '@grafana/schema';

export const nodeGraphConfig = (refIdPrefix: string, panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();

  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      tooltip: {
        mode: TooltipDisplayMode.Multi,
        sort: SortOrder.None,
      },
      legend: {
        showLegend: true,
        displayMode: LegendDisplayMode.Table,
        placement: 'bottom',
        calcs: ['min', 'mean', 'max'],
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: GraphDrawStyle.Line,
          lineInterpolation: LineInterpolation.Smooth,
          barAlignment: 0,
          lineWidth: 2,
          fillOpacity: 0,
          gradientMode: GraphGradientMode.None,
          spanNulls: false,
          insertNulls: false,
          showPoints: VisibilityMode.Never,
          pointSize: 5,
          stacking: {
            mode: StackingMode.None,
            group: 'A',
          },
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
        },
        color: {
          mode: 'palette-classic',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
        unit: panel.unit ?? 'bytes',
      },
      overrides: [
        {
          matcher: {
            id: 'byFrameRefID',
            options: `${refIdPrefix}Usage`,
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'blue',
                mode: 'fixed',
              },
            },
          ],
        },
        {
          matcher: {
            id: 'byFrameRefID',
            options: `${refIdPrefix}Requests`,
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'orange',
                mode: 'fixed',
              },
            },
            {
              id: 'custom.lineStyle',
              value: {
                dash: [10, 10],
                fill: 'dash',
              },
            },
          ],
        },
        {
          matcher: {
            id: 'byFrameRefID',
            options: `${refIdPrefix}Limits`,
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'red',
                mode: 'fixed',
              },
            },
            {
              id: 'custom.lineStyle',
              value: {
                dash: [10, 10],
                fill: 'dash',
              },
            },
          ],
        },
        {
          matcher: {
            id: 'byFrameRefID',
            options: `${refIdPrefix}Allocation`,
          },
          properties: [
            {
              id: 'custom.lineStyle',
              value: {
                dash: [0, 10],
                fill: 'dot',
              },
            },
            {
              id: 'color',
              value: {
                fixedColor: 'green',
                mode: 'fixed',
              },
            },
            {
              id: 'custom.fillOpacity',
              value: 30,
            },
            {
              id: 'custom.gradientMode',
              value: 'opacity',
            },
          ],
        },
        {
          matcher: {
            id: 'byFrameRefID',
            options: `${refIdPrefix}Capacity`,
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'purple',
                mode: 'fixed',
              },
            },
            {
              id: 'custom.gradientMode',
              value: 'hue',
            },
            {
              id: 'custom.fillOpacity',
              value: 10,
            },
          ],
        },
      ],
    },
  }).setData(panel.runner);
};
