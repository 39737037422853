import { SceneDataTransformer, SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';

import { addVizPanelMenuHandler, getGenericQueryRunner } from '../../../helpers/scenes';
import { costsConfig } from '../CostsOverviewScene/panelConfig';
import { SetsBox } from '../SetsBox/SetsBox';
import { QueryRefId, sumData, transformData, overrides } from '../PodDetailOptimization/PodDetailOptimization';
import { networkConfig } from './networkPanelConfig';

export type QueryType = {
  [key: string]: string;
};

export const setsBoxRow = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    children: [
      {
        runner: getGenericQueryRunner(datasource, queries.cpuRequests, {
          refId: 'cpuRequestsSet',
          instant: true,
          range: false,
          intervalMs: '3600',
        }),
        type: 'cpuRequests',
        title: 'CPU requests set',
      },
      {
        runner: getGenericQueryRunner(datasource, queries.cpuLimits, {
          instant: true,
          range: false,
          intervalMs: '3600',
        }),
        type: 'cpuLimits',
        title: 'CPU limits set',
      },
      {
        runner: getGenericQueryRunner(datasource, queries.memoryRequests, {
          instant: true,
          range: false,
          intervalMs: '3600',
        }),
        type: 'memoryRequests',
        title: 'Memory requests set',
      },
      {
        runner: getGenericQueryRunner(datasource, queries.memoryLimits, {
          instant: true,
          range: false,
          intervalMs: '3600',
        }),
        type: 'memoryLimits',
        title: 'Memory limits set',
      },
    ].map((panel) => {
      return new SceneFlexItem({
        $data: panel.runner,
        height: 100,
        body: new SetsBox({
          numContainers: '${numContainers}',
          type: panel.type,
          title: panel.title,
        }),
      });
    }),
  });
};

export const allocationRow = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 100,
    $data: getGenericQueryRunner(
      datasource,
      queries.cpuAllocation,
      {
        legendFormat: 'cpu',
        refId: QueryRefId.Cpu,
        instant: true,
        range: false,
        interval: '',
        intervalMs: '1m',
      },
      [
        {
          expr: queries.memoryAllocation,
          instant: true,
          interval: '',
          range: false,
          refId: QueryRefId.Memory,
          legendFormat: 'memory',
        },
      ]
    ),
    children: [
      {
        title: 'CPU cost allocation',
        width: '24.6%',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cpu)],
        }),
        predictable: {
          query: queries.cpuAllocation,
          button: 'Predict CPU cost',
          title: 'CPU cost prediction model',
          name: 'CPU cost',
          metric: 'node_cpu_hourly_cost',
          axisLabel: 'CPU cost',
          asMenu: true,
        },
      },
      {
        title: 'Memory cost allocation',
        width: '24.6%',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Memory)],
        }),
        predictable: {
          query: queries.memoryAllocation,
          button: 'Predict memory cost',
          title: 'Memory cost prediction model',
          name: 'Memory cost',
          metric: 'node_ram_hourly_cost', // update
          axisLabel: 'Memory cost',
          asMenu: true,
        },
      },
      {
        title: 'Total cost (compute)',
        width: '50%',
        runner: new SceneDataTransformer({
          transformations: [sumData],
        }),
        predictable: {
          query: `(${queries.cpuAllocation}) + (${queries.memoryAllocation})`,
          button: 'Predict total allocation cost',
          title: 'Total allocation cost prediction model',
          name: 'Total allocation cost',
          metric: 'node_cpu_seconds_total',
          axisLabel: 'Total allocation cost',
          asMenu: true,
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        width: panel.width,
        height: 100,
        md: {
          width: '100%',
        },
        body: addVizPanelMenuHandler(costsConfig({ ...panel, textSize: 25 }).build(), [], panel.predictable),
      });
    }),
  });
};

export const idleRow = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    direction: 'row',
    height: 100,
    $data: getGenericQueryRunner(
      datasource,
      queries.cpuIdle,
      {
        legendFormat: 'cpu',
        refId: QueryRefId.Cpu,
        instant: true,
        range: false,
      },
      [
        {
          expr: queries.memoryIdle,
          instant: true,
          interval: '',
          range: false,
          refId: QueryRefId.Memory,
          legendFormat: 'memory',
        },
      ]
    ),
    children: [
      {
        title: 'CPU idle cost',
        width: '24.6%',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Cpu)],
        }),
        predictable: {
          query: queries.cpuIdle,
          button: 'Predict CPU idle cost',
          title: 'CPU idle cost prediction model',
          name: 'CPU idle cost',
          metric: 'node_cpu_hourly_cost',
          axisLabel: 'CPU idle cost',
          asMenu: true,
        },
      },
      {
        title: 'Memory idle cost',
        width: '24.6%',
        runner: new SceneDataTransformer({
          transformations: [transformData(QueryRefId.Memory)],
        }),
        predictable: {
          query: queries.memoryIdle,
          button: 'Predict memory idle cost',
          title: 'Memory idle cost prediction model',
          name: 'Memory idle cost',
          metric: 'node_memory_MemAvailable_bytes',
          axisLabel: 'Memory idle cost',
          asMenu: true,
        },
      },
      {
        title: 'Total idle cost (compute)',
        width: '50%',
        runner: new SceneDataTransformer({
          transformations: [sumData],
        }),
        predictable: {
          query: `(${queries.cpuIdle}) + (${queries.memoryIdle})`,
          button: 'Predict total idle cost',
          title: 'Total idle cost prediction model',
          name: 'Total idle cost',
          metric: 'node_cpu_seconds_total',
          axisLabel: 'Total idle cost',
          asMenu: true,
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        width: panel.width,
        height: 100,
        md: {
          width: '100%',
        },
        body: addVizPanelMenuHandler(costsConfig({ ...panel, textSize: 25, overrides }).build(), [], panel.predictable),
      });
    }),
  });
};

export const networkRow = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    children: [
      {
        runner: getGenericQueryRunner(
          datasource,
          queries.netUsageRx,
          {
            instant: false,
            range: true,
            interval: '',
            refId: 'rx',
            legendFormat: 'Rate of Received Bytes',
          },
          [
            {
              expr: queries.netUsageTx,
              instant: false,
              range: true,
              interval: '',
              refId: 'tx',
              legendFormat: 'Rate of Transmitted Bytes',
            },
          ]
        ),
        title: 'Network Bandwidth',
        unit: 'binBps',
        predictable: undefined,
      },

      {
        runner: getGenericQueryRunner(
          datasource,
          queries.netDroppedRx,
          {
            instant: false,
            range: true,
            interval: '',
            refId: 'rx_dropped',
            legendFormat: 'Rate of Received Packets Dropped',
          },
          [
            {
              expr: queries.netDroppedTx,
              instant: false,
              range: true,
              interval: '',
              refId: 'tx_dropped',
              legendFormat: 'Rate of Transmitted Packets Dropped',
            },
          ]
        ),
        title: 'Network Saturation',
        unit: 'pps',
        predictable: undefined,
      },
    ].map((panel) => {
      return new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(networkConfig(panel).build(), [], panel.predictable),
      });
    }),
  });
};
