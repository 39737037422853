import { DateTime, RawTimeRange } from '@grafana/data';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { DEFAULT_INITIAL_TIME_RANGE, DEFAULT_REFRESH_INTERVAL } from '../constants';
import { getInitialTimeRange, sceneRefreshIntervalToMs } from 'helpers/helpers';

export type DateTimeRange = {
  from: DateTime;
  to: DateTime;
  raw: RawTimeRange;
};

export type RelativeRange = { from: string; to: string } | null;

type StateType = {
  relativeRange: RelativeRange;
  range: DateTimeRange;
  hasDateChanged: boolean;
  isDataRefreshing: boolean;
  isPastDate: boolean;
  refreshInterval: number;
};

type ActionType = {
  setTimeRange: (range: DateTimeRange, isPastDate?: boolean, relativeRange?: RelativeRange) => void;
  setDateChanged: (changed: boolean) => void;
  setIsDataRefreshing: (isDataRefreshing: boolean) => void;
  setRefreshInterval: (refreshInterval: number) => void;
};

export type TimeRangeStore = StateType & ActionType;

const { defaultFrom, defaultTo, defaultRelativeRange } = getInitialTimeRange();
const initialState = {
  range: {
    from: defaultFrom,
    to: defaultTo,
    raw: {
      from: defaultFrom.toISOString(),
      to: defaultTo.toISOString(),
    },
  },
  hasDateChanged: false,
  isDataRefreshing: false,
  isPastDate: false,
  relativeRange: defaultRelativeRange,
  refreshInterval: sceneRefreshIntervalToMs(DEFAULT_REFRESH_INTERVAL),
};

export function setTimeRange(
  range: DateTimeRange,
  isPastDate = false,
  relativeRange: RelativeRange = DEFAULT_INITIAL_TIME_RANGE
) {
  useTimeRangeStore.setState({ range, hasDateChanged: isPastDate, relativeRange });
}

export function setRefreshInterval(refreshInterval: number) {
  useTimeRangeStore.setState({ refreshInterval });
}

const useTimeRangeStore = createWithEqualityFn<TimeRangeStore>(
  (set) => ({
    //state
    ...initialState,

    // actions
    setTimeRange,
    setRefreshInterval,

    setDateChanged: (hasDateChanged: boolean) => {
      set({ hasDateChanged });
    },

    setIsDataRefreshing: (isDataRefreshing: boolean) => {
      set({ isDataRefreshing });
    },
  }),
  shallow
);

export default useTimeRangeStore;
