const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "tensorflow_metrics" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_pod_label_<pod_label_name>"]
        regex         = "<pod_label_value>"
    }

    rule {
        source_labels = ["__address__"]
        regex         = "([^:]+)(:[0-9]+)?"
        target_label  = "instance"
        replacement   = "\${1}"
    }
}

prometheus.scrape "tensorflow_metrics" {
    targets      = discovery.relabel.tensorflow_metrics.output
    metrics_path = "/monitoring/prometheus/metrics"
    forward_to   = [prometheus.relabel.metrics_service.receiver]
    job_name     = "integrations/tensorflow"
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "tensorflow_logs" {
    targets = discovery.kubernetes.pods.targets

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator     = ":"
        regex         = "(<tensorflow_namespace>:tensorflow.*)"
    }

    rule {
        action        = "replace"
        source_labels = ["__meta_kubernetes_pod_ip"]
        target_label  = "instance"
    }

    rule {
        replacement   = "integrations/tensorflow" 
        target_label  = "job"
    }

    rule {
        replacement   = "tensorflow"
        target_label  = "name"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_node_name"]
        target_label  = "__host__"
    }
}

loki.source.kubernetes "tensorflow_logs" {
  targets      = discovery.relabel.tensorflow_logs.output
  forward_to   = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
