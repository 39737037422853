import {
  DataFrameJSON,
  ExploreUrlState,
  serializeStateToUrlParam,
  AppEvents,
  URLRange,
  dateTime,
  rangeUtil,
} from '@grafana/data';
import { DataQuery, DataSourceRef, SortOrder } from '@grafana/schema';
import {
  DEFAULT_INITIAL_TIME_RANGE,
  DEFAULT_TIME_AMOUNT,
  DEFAULT_TIME_UNIT,
  K8S_STORAGE_KEY,
  URL_ENCODE_FORWARD_SLASH,
} from '../constants';
import { FetchStatus, RudderstackEvents } from 'enums';
import { GeneralQueryResult, GenericUsageResult, Namespace, ParsedUsageCostData, Range, UsageCostErrors } from 'types';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import appEvents from 'grafana/app/core/app_events';
import { QueryErrors } from 'hooks/useDataFrame';
import { config } from '@grafana/runtime';
import { DateTimeRange } from 'store/timeRange';

export class QueryCollection implements ExploreUrlState {
  datasource: string;
  queries: Array<DataQuery & Record<string, any>>;
  range: URLRange;

  constructor(datasource: string, range: URLRange, instant: boolean, obj: Object, args?: string[]) {
    this.datasource = datasource;
    this.range = range;
    this.queries = [];

    Object.entries(obj)?.map?.(([refId, query]) => {
      let expr = '';

      switch (typeof query) {
        case 'string':
          expr = query;
          break;

        case 'function':
          expr = query.apply(null, args);
          break;

        default:
          throw new Error(`Unexpected object value type '${typeof query}' (refId: '${refId}')`);
      }

      this.queries.push({ refId, expr, instant });
    });
  }

  toArray(): string[] {
    return this.queries?.map?.((q) => q.expr);
  }

  toRange(): Range {
    return { from: this.range.from.valueOf().toString(), to: this.range.to.valueOf().toString() };
  }

  toExploreUrl(): string {
    const clone: ExploreUrlState = { ...this };
    clone.queries?.map?.((v, i) => (clone.queries[i].expr = encodeQueryString(v.expr)));

    return `/explore?left=${serializeStateToUrlParam(clone)}`;
  }
}

export function getExploreUrl(selectedLokiName: string, query: string, range?: URLRange | null, instant?: boolean) {
  // Certain characters need to be manually encoded
  const expr = encodeQueryString(query);

  const logUrlState: ExploreUrlState = {
    datasource: selectedLokiName,
    queries: [{ refId: 'A', expr, instant }],
    range: range ?? { from: 'now-1h', to: 'now' },
  };

  return `/explore?left=${serializeStateToUrlParam(logUrlState)}`;
}

export function getOutlierUrl(dsId: string, query: string) {
  const queryString = JSON.stringify({
    refId: 'A',
    expr: encodeQueryString(query),
    editorMode: 'code',
    range: true,
  });
  return `/a/grafana-ml-app/outlier-detector/create?algorithm=dbscan&sensitivity=0.65&ds=${dsId}&query_params=${encodeURIComponent(
    queryString
  )}`;
}

export function getPredictUrl(dsId: string, query: string) {
  const queryString = JSON.stringify({
    refId: 'A',
    expr: encodeQueryString(query),
    editorMode: 'code',
    range: true,
  });
  return `/a/grafana-ml-app/metric-forecast/create?ds=${dsId}&query_params=${encodeURIComponent(queryString)}`;
}

export function isValueInArray<T>(value: T, array: T[]) {
  return array.includes(value);
}

export function isFetchLoadingOrReady(value: FetchStatus) {
  if (isValueInArray<FetchStatus>(value, [FetchStatus.Fetching, FetchStatus.Success, FetchStatus.Error])) {
    return true;
  }

  return false;
}

export function isEmptyArray<T>(array: T[]) {
  return Array.isArray(array) && array.length === 0;
}

export const calcUsed = (value: number) => {
  if (!value) {
    return 0;
  }
  if (value < 0.01 && value > 0) {
    return `0.5%`;
  }
  return `${(value * 100).toFixed(0)}%`;
};

export const calcUnused = (value: number) => {
  if (!value) {
    return `100%`;
  }
  const unused = 1 - value;
  if (unused < 0) {
    return `0%`;
  }
  return `${unused * 100}%`;
};

export const calcUsedString = (value: number) => {
  if (!value || !isFinite(value)) {
    return 'No data';
  }
  if (value < 0.01 && value > 0) {
    return `<1%`;
  }
  return `${(value * 100).toFixed(0)}%`;
};

export function transfromDataFrameToUseMetrics(data: DataFrameJSON[] | undefined) {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.reduce<Array<GeneralQueryResult<GenericUsageResult>>>((acc, val) => {
    if (val?.schema?.fields?.length) {
      const item = {
        metric: { ...val?.schema?.fields?.[1]?.labels },
        value: [val?.data?.values?.[0]?.[0], val.data?.values?.[1]?.[0]],
      };
      acc.push(item as never);
    }

    return acc;
  }, []);
}

export function pluralizeTitle(title: string, count: number): string {
  if (count > 1 || count === 0) {
    return `${title.toLowerCase()}s`;
  }

  return title.toLowerCase();
}

export const getValidName = (metric: DataFrameJSON, type: string) => {
  const fromSchema = metric.schema?.name?.split('"')[1];
  const fields = metric.schema?.fields;
  const fromLabel = fields?.[fields.length - 1]?.labels?.[type];

  return fromSchema || fromLabel;
};

export const getNamespaceRowNameFromMetric = (metric: DataFrameJSON) => {
  const fromSchemaCluster = metric.schema?.name?.split('"')[1];
  const fromSchemaNamespace = metric.schema?.name?.split('"')[3];
  const fields = metric.schema?.fields;
  const fromLabelCluster = fields?.[fields.length - 1]?.labels?.cluster;
  const fromLabelNamespace = fields?.[fields.length - 1]?.labels?.namespace;

  const fromSchema = `${fromSchemaNamespace}-${fromSchemaCluster}`;
  const fromLabel = `${fromLabelNamespace}-${fromLabelCluster}`;
  return fromSchemaCluster && fromSchemaNamespace ? fromSchema : fromLabel;
};

export const getNamespaceRowNameFromData = (data: Namespace) => {
  return `${data.namespace}-${data.cluster}`;
};

export const savePrometheusName = (promName: string) => {
  localStorage?.setItem(
    K8S_STORAGE_KEY,
    JSON.stringify({
      ...(JSON.parse(localStorage?.getItem(K8S_STORAGE_KEY) as string) || {}),
      promName: promName,
    })
  );
};

export const saveRefreshInterval = (refreshInterval: string) => {
  localStorage?.setItem(
    K8S_STORAGE_KEY,
    JSON.stringify({
      ...(JSON.parse(localStorage?.getItem(K8S_STORAGE_KEY) as string) || {}),
      refreshInterval,
    })
  );
};

export const copyContent = (content: string, setCopied: (copied: boolean) => void) => {
  trackRudderStackEvent(RudderstackEvents.CopyItem, {});

  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard
    .writeText(content)
    .then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    })
    .catch((err) => {
      appEvents.emit(AppEvents.alertError, [`Failed to copy to clipboard: ${err}`]);
    });
};

/**
 * Gets data from a list of dataframe arrays [[], [], []] and transforms it into an object with metric type value as a key for an object with orderResultNames as keys.
 * @example { container1: { cpu: 0.4, memory: 0.3 }, container2: { cpu: 0.1, memory: 0.9 } }
 */
export function getValidData(
  data: DataFrameJSON[][],
  errors: QueryErrors[],
  orderResultNames: string[],
  metricType: string,
  getNameFn?: (metric: DataFrameJSON) => string
) {
  const parsedData: ParsedUsageCostData = {
    data: {},
    errors: new Map<string, string | undefined>(),
  };

  if (data?.length) {
    data?.forEach?.((list, index) => {
      parsedData.errors.set(orderResultNames[index], errors[index]?.results?.A.error);

      list?.forEach?.((item) => {
        const name = getNameFn?.(item) || getValidName(item, metricType);
        const current = parsedData.data[name as keyof typeof parsedData.data];
        if (!current) {
          parsedData.data[name as keyof typeof parsedData.data] = {};
        }

        parsedData.data[name as keyof typeof parsedData.data][orderResultNames[index]] = item?.data
          ?.values?.[1]?.[0] as unknown as number;
      });
    });
  }

  return parsedData;
}

export function getPrecisionValue(value: number | undefined, cost: boolean) {
  if (cost && typeof value !== 'undefined' && value < 0) {
    return 'Undersized';
  }

  const formatter = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const displayValue = formatter.format(value || 0);
  if (!cost) {
    return formatter.format(value || 0);
  }

  return value! > 0 && value! < 0.01 ? '< $0.01' : `$${displayValue}`;
}

export function parseUsageData(
  data: DataFrameJSON[][],
  errors: QueryErrors[],
  type: string,
  getNameFn?: (metric: DataFrameJSON) => string
) {
  const orderResultNames = [
    'cpuAvg',
    'cpuAvgPercent',
    'cpuMax',
    'cpuMaxPercent',
    'memoryAvg',
    'memoryAvgPercent',
    'memoryMax',
    'memoryMaxPercent',
  ];

  return getValidData(data, errors, orderResultNames, type, getNameFn);
}

export function parseCostData(
  data: DataFrameJSON[][],
  errors: QueryErrors[],
  type: string,
  getNameFn?: (metric: DataFrameJSON) => string
) {
  const orderResultNames = [
    'costCurrent',
    'costProjected',
    'cpuIdleCurrent',
    'cpuIdleProjected',
    'memoryIdleCurrent',
    'memoryIdleProjected',
  ];

  return getValidData(data, errors, orderResultNames, type, getNameFn);
}

export function mergeUsageData<T>(
  type: string,
  allData: T[],
  usageData: ParsedUsageCostData,
  costData: ParsedUsageCostData,
  sortOrder: SortOrder,
  sortColumn: string,
  nameFn?: (item: T) => string
) {
  const ret = {
    data: [] as T[],
    errors: {
      usage: usageData.errors,
      cost: costData.errors,
    } as UsageCostErrors,
  };

  if (allData && (costData || usageData)) {
    let parsed = allData.reduce((acc, item) => {
      const typeName = item[type as never] ? type : 'name';
      const name = nameFn ? nameFn(item) : item[typeName as never];
      const currentUsageData = usageData.data[name] || {};
      const currentCostData = costData.data[name] || {};

      acc.push({ ...item, ...currentUsageData, ...currentCostData } as never);
      return acc;
    }, []);

    if (sortOrder !== SortOrder.None) {
      parsed.sort((a: T, b: T) => {
        const aValue = (a?.[sortColumn as keyof typeof a] || 0) as number;
        const bValue = (b?.[sortColumn as keyof typeof a] || 0) as number;

        return sortOrder === SortOrder.Ascending ? aValue - bValue : bValue - aValue;
      });
    } else {
      parsed.sort((a: T, b: T) => {
        const typeName = a?.[type as keyof typeof a] ? type : 'name';
        const aValue = a?.[typeName as keyof typeof a] || '';
        const bValue = b?.[typeName as keyof typeof b] || '';

        return (aValue as string)?.localeCompare(bValue as string);
      });
    }

    ret.data = [...parsed];
  }

  return ret;
}

export const isDescendant = (child: Node, parent: Node | null): boolean => {
  let node: Node | null = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export function countFiltersApplied(values: Array<string | string[] | boolean | null | undefined>) {
  return values.reduce((acc, current) => {
    const itemHasData = Array.isArray(current) ? current.length > 0 : !!current;

    if (itemHasData) {
      acc += 1;
    }

    return acc;
  }, 0);
}

export function encodeUrlString(parameter: string): string {
  return encodeURIComponent(parameter.replace(/\//g, URL_ENCODE_FORWARD_SLASH));
}

export function decodeUrlString(parameter: string): string {
  return decodeURIComponent(parameter.replaceAll(URL_ENCODE_FORWARD_SLASH, '/'));
}

export function sceneRefreshIntervalToMs(relativeTime: string): number {
  if (relativeTime === 'Off' || !relativeTime) {
    // Zero disables SWR refresh interval
    return 0;
  }

  const regex = /^(\d+)([smh])$/;
  const match = relativeTime.match(regex);

  const value = parseInt(match?.[1] as string, 10);
  const unit = match?.[2] || 's';

  const unitToMs = {
    s: 1000,
    m: 60 * 1000,
    h: 60 * 60 * 1000,
  };

  return value * unitToMs[unit as keyof typeof unitToMs];
}

/**
 * encodeQueryString encodes characters which would be missed by the standard
 * encodeURIComponent function, such as '#' and '+'. This is needed when URL
 * encoding queries (such as PromQL) that contain comments and/or regexes.
 * @param query A value representing an unencoded URI component.
 */
export function encodeQueryString(query?: string): string {
  return query?.trim()?.replaceAll('#', '%23')?.replaceAll('+', '%2B') || '';
}

export function getInitialTimeRange() {
  // Set default values
  let defaultFrom = dateTime().subtract(DEFAULT_TIME_AMOUNT, DEFAULT_TIME_UNIT);
  let defaultTo = dateTime();
  let defaultRelativeRange = DEFAULT_INITIAL_TIME_RANGE;

  // If from and to params are available make those the default
  const searchParams = new URLSearchParams(window.location.search);
  const fromParam = searchParams.get('from') || searchParams.get('start');
  const toParam = searchParams.get('to') || searchParams.get('end');
  if (fromParam && toParam) {
    defaultFrom = dateTime(fromParam);
    defaultTo = dateTime(toParam);
    defaultRelativeRange = {
      from: fromParam,
      to: toParam,
    };

    // If we have a relative time range (i.e now-1h to now), get moment objects.
    if (fromParam.includes('now')) {
      const { from, to, raw } = rangeUtil.convertRawToRange({ from: fromParam, to: toParam });
      defaultFrom = from;
      defaultTo = to;
      defaultRelativeRange = {
        from: raw.from as string,
        to: raw.to as string,
      };
    }
  }

  return {
    defaultFrom,
    defaultTo,
    defaultRelativeRange,
  };
}

export function timeRangeOrDefault(range?: Range) {
  const initial = getInitialTimeRange();
  return range ? range : { from: initial.defaultFrom.valueOf().toString(), to: initial.defaultTo.valueOf().toString() };
}

export function dateTimeRangeOrDefault(range?: DateTimeRange) {
  const r = range
    ? {
        from: range.from.valueOf().toString(),
        to: range.to.valueOf().toString(),
      }
    : undefined;

  return timeRangeOrDefault(r);
}

export function getDatasourceNameFromId(datasourceRef?: DataSourceRef) {
  const datasources = Object.values(config.datasources);
  const dataSource = datasources.find((datasource) => datasource.uid === datasourceRef?.uid);
  return dataSource?.name || '';
}

export function pipe<T>(...fns: Array<(arg: T) => T>) {
  return (value: T) => fns.reduce((acc, fn) => fn(acc), value);
}

export function spreadArgsWithScope(scope: unknown, fn: (...args: any[]) => unknown, args: unknown[]): unknown {
  return fn.call(scope, ...args);
}
