const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "gitlab" {
    targets = discovery.kubernetes.pods.targets
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<gitlab_pod_label>"]
        regex = "<gitlab_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<gitlab_prometheus_port_number>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }       
    rule {
        replacement = "integrations/gitlab"
        target_label = "job"
    }
}

prometheus.scrape "gitlab" {
    targets      = discovery.relabel.gitlab.output
    metrics_path = "/-/metrics"
    honor_labels = true
    forward_to   = [prometheus.remote_write.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_gitlab" {
    targets = discovery.relabel.pod_logs.output 
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<gitlab_pod_label>"]
        regex = "<gitlab_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<gitlab_prometheus_port_number>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }       
    rule {
        replacement = "integrations/gitlab"
        target_label = "job"
    }
}

local.file_match "logs_gitlab" {
    path_targets = discovery.relabel.logs_gitlab.output
}

loki.source.file "logs_gitlab" {
    targets    = local.file_match.logs_gitlab.targets
    forward_to = [loki.process.logs_gitlab_exceptions_json.receiver]
}

loki.process "logs_gitlab_exceptions_json" {
    forward_to = [loki.process.logs_service.receiver]

    stage.match {
        selector = \`{tmp_container_runtime=\"containerd\"}\`

        stage.cri {}

        stage.drop {
            expression = "^([^{}].*)?$"
        }

        stage.regex {
            expression = \`^\\{\"severity\":\"(?P<severity>ERROR)\",\"time\":\"(?P<time>[^\"]+)\",\"correlation_id\":\"(?P<correlation_id>[^\"]+)\",\"exception.class\":\"(?P<exception_class>[^\"]+)\",.*$\`
        }

        stage.labels {
            values = {
                severity = "",
                time = "",
                correlation_id = "",
                exception_class = "",
            }
        }

        stage.match {
            selector = \`{exception_class=\"\"}\`
            action = "drop"
        }
    }
}`;

export default { metrics, logs };
