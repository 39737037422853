import React, { useMemo } from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { useReturnToPrevious } from '@grafana/runtime';

export interface OpenInK8sButtonState extends SceneObjectState {
  href: string;
  locationTitle: string;
}

export default class OpenInK8sButton extends SceneObjectBase<OpenInK8sButtonState> {
  static Component = OpenInK8sButtonRenderer;

  constructor(state: OpenInK8sButtonState) {
    super(state);
  }
}

function OpenInK8sButtonRenderer({ model }: SceneComponentProps<OpenInK8sButton>) {
  const { href, locationTitle } = model.useState();
  const setReturnToPrevious = useReturnToPrevious();

  const { value } = sceneGraph.getTimeRange(model).useState();

  const processedHref = useMemo(() => {
    const url = new URL(href, window.location.origin);
    url.searchParams.set('from', typeof value.raw.from === 'string' ? value.raw.from : value.from.utc().toISOString());
    url.searchParams.set('to', typeof value.raw.to === 'string' ? value.raw.to : value.to.utc().toISOString());

    return url.toString();
  }, [href, value]);

  return (
    <LinkButton
      data-cy="OpenInK8sButton"
      variant="secondary"
      href={processedHref}
      onClick={() => setReturnToPrevious(locationTitle)}
    >
      Open in Kubernetes
    </LinkButton>
  );
}
