const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.mysql "mysql" {
  data_source_name = "root:password@(localhost:3306)/"
}

prometheus.scrape "mysql" {
  targets      = prometheus.exporter.mysql.mysql.targets
  job_name     = "integrations/mysql"
  forward_to   = [prometheus.relabel.mysql.receiver]
}

prometheus.relabel "mysql" {
  rule {
    replacement = "integrations/mysql"
    target_label = "job"
  }
  rule {
    replacement = "<your_mysql_instance_name>"
    target_label = "instance"
  }
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}
`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "mysql_logs" {
  targets = discovery.relabel.pod_logs.output
  
  rule {
    source_labels = ["namespace"]
    regex = "<your_mysql_namespace>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_label_<your_mysql_selector_labelname>"]
    regex = "<your_mysql_selector_labelvalue>"
    action = "keep"
  }
}

local.file_match "mysql_logs" {
  path_targets = discovery.relabel.mysql_logs.output
}

loki.source.file "mysql_logs" {
  targets    = local.file_match.mysql_logs.targets
  forward_to = [loki.process.mysql_logs.receiver]
}

loki.process "mysql_logs" {
  // If you're using docker, replace \`cri\` with \`docker\` below.
  stage.cri {}
  stage.labels {
    values = {
      instance = "pod",
    }
  }
  stage.regex {
    expression = \`(?P<timestamp>.+) (?P<thread>[\\d]+) \\[(?P<label>.+?)\\]( \\[(?P<err_code>.+?)\\] \\[(?P<subsystem>.+?)\\])? (?P<msg>.+)\`
  }
  stage.labels {
    values = {
      level = "label",
      err_code = "err_code",
      subsystem = "subsystem",
    }
  }
  stage.drop {
    expression = "^ *$"
    drop_counter_reason = "drop empty lines"
  }
  stage.static_labels {
    values = {
      job = "integrations/mysql",
      instance = "<your_mysql_instance_name>",
    }
  }
  forward_to = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
