const metrics = `// Cilium Agent
discovery.relabel "cilium_agent" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_k8s_app"]
    regex = "cilium"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_label_k8s_app"]
    regex = "(.+)"
    replacement = "\${1}"
    target_label = "k8s_app"
  }
}

prometheus.scrape "cilium_agent" {
  targets      = discovery.relabel.cilium_agent.output
  job_name     = "integrations/cilium-enterprise/cilium-agent"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

// Cilium Operator
discovery.relabel "cilium_operator" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_name"]
    regex = "cilium-operator"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_label_io_cilium_app"]
    regex = "operator"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_label_io_cilium_app_app"]
    regex = "(.+)"
    replacement = "\${1}"
    target_label = "io_cilium_app"
  }
}

prometheus.scrape "cilium_operator" {
  targets      = discovery.relabel.cilium_operator.output
  job_name     = "integrations/cilium-enterprise/cilium-operator"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

// Hubble Relay
discovery.relabel "hubble_relay" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_k8s_app"]
    regex = "hubble-relay"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
}

prometheus.scrape "hubble_relay" {
  targets    = discovery.relabel.hubble_relay.output
  job_name   = "integrations/cilium-enterprise/hubble-relay"
  forward_to = [prometheus.relabel.metrics_service.receiver]
}

// Hubble
discovery.relabel "hubble" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_k8s_app"]
    regex = "hubble"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "hubble-metrics"
    action = "keep"
  }
}

prometheus.scrape "hubble" {
  targets      = discovery.relabel.hubble.output
  job_name     = "integrations/cilium-enterprise/hubble"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

// Hubble Enterprise
discovery.relabel "hubble_enterprise" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_app_kubernetes_io_name"]
    regex = "hubble-enterprise"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
}

prometheus.scrape "hubble_enterprise" {
  targets      = discovery.relabel.hubble_enterprise.output
  job_name     = "integrations/cilium-enterprise/hubble-enterprise"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

// Hubble Timescape Ingester
discovery.relabel "hubble_timescape_ingester" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_app_kubernetes_io_name"]
    regex = "hubble-timescape-ingester"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_label_app_kubernetes_io_component"]
    regex = "ingester"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
}

prometheus.scrape "hubble_timescape_ingester" {
  targets      = discovery.relabel.hubble_timescape_ingester.output
  job_name     = "integrations/cilium-enterprise/hubble-timescape-ingester"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}

// Hubble Timescape Server
discovery.relabel "hubble_timescape_server" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_app_kubernetes_io_name"]
    regex = "hubble-timescape-server"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_label_app_kubernetes_io_component"]
    regex = "server"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_endpoint_port_name"]
    regex = "metrics"
    action = "keep"
  }
}

prometheus.scrape "hubble_timescape_server" {
  targets      = discovery.relabel.hubble_timescape_server.output
  job_name     = "integrations/cilium-enterprise/hubble-timescape-server"
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
