const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "cert_manager" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_<cert_manager_pod_label>"]
    regex = "<cert_manager_pod_label_value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_port_number"]
    regex = "<cert_manager_prometheus_port_number>"
    action = "keep"
  }
  rule {
      source_labels = ["__meta_kubernetes_pod_name"]
      target_label = "instance"
  }
}

prometheus.scrape "cert_manager" {
  job_name     = "integrations/cert-manager"
  targets      = discovery.relabel.cert_manager.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
