const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "metrics_clickhouse" {
    targets = discovery.kubernetes.endpoints.targets
    rule {
        // This must match the label you specified in the clickhouse deployment
        source_labels = ["__meta_kubernetes_endpoints_label_<label name>"]
        regex = "<endpoint label value>"
        action = "keep"
    }

    rule {
        source_labels = ["__meta_kubernetes_endpoint_port_name"]
        regex = "<clickhouse service listening port>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_label_clickhouse_cluster"]
        target_label = "clickhouse_cluster"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_name"]
        target_label = "instance"
    }
}

prometheus.scrape "metrics_clickhouse" {
    job_name     = "integrations/clickhouse"
    targets      = discovery.relabel.metrics_clickhouse.output
    honor_labels = true
    forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_clickhouse" {
    targets = discovery.relabel.pod_logs.output    
    rule {
        source_labels = ["namespace"]
        regex = "<your_clickhouse_namespace>"
        action = "keep"
    }
    rule {
        // This must match the label you specified in the Clickhouse deployment
        source_labels = ["__meta_kubernetes_pod_label_<label name>"]
        regex = "<pod label value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_label_clickhouse_cluster"]
        target_label = "clickhouse_cluster"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "integrations/clickhouse"
        target_label = "job"
    }
}

local.file_match "logs_clickhouse" {
    path_targets = discovery.relabel.logs_clickhouse.output
}

loki.source.file "logs_clickhouse" {
    targets    = local.file_match.logs_clickhouse.targets
    forward_to = [loki.process.logs_clickhouse.receiver]
}

loki.process "logs_clickhouse" {
    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }

    stage.multiline {
        firstline     = \`^([\\d]{4}).([\\d]{1,2}).([\\d]{1,2}) (([\\d]+):([\\d]+):([\\d]+).([\\d]+))\`
        max_lines     = 0
        max_wait_time = "0s"
    }

    stage.regex {
        expression = \`(?P<timestamp>([\\d]{4}).([\\d]{1,2}).([\\d]{1,2}) (([\\d]+):([\\d]+):([\\d]+).([\\d]+))) \\[ \\d+ \\] \\{.*\\} <(?P<level>.+)> (?P<message>(?s:.*))$\`
    }

    stage.labels {
        values = {
            instance = "pod",
            level = "",
        }
    }

    stage.static_labels {
        values = {
            job = "integrations/clickhouse",
        }
    }

    forward_to = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
