const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "apache_solr" {
    targets = discovery.kubernetes.endpoints.targets

    rule {
        source_labels = ["__meta_kubernetes_service_name"]
        regex = "<solr_service_name>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "solr_cluster"
    }
    rule {
        replacement = "integrations/apache-solr"
        target_label = "job"
    }
}

prometheus.scrape "solr_metrics" {        
  targets      = discovery.relabel.integrations_solr.output
  metrics_path = "/metrics"
  forward_to   = [prometheus.relabel.metrics_service.receiver]
  job_name     = "integrations/apache-solr"
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "solr_logs" {
  targets = discovery.kubernetes.pods.targets
  
    rule {
      source_labels = ["__meta_kubernetes_namespace","__meta_kubernetes_pod_name"]
      separator     = ":"
      regex         = "<solr-pod-name>"
      action        = "keep"
    }

    rule {
      replacement = "<your-cluster-name>"
      target_label = "solr_cluster"
    }

    rule {
        replacement   = "integrations/apache-solr" 
        target_label  = "job"
    }

    rule {
      source_labels = ["__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
      separator = ":"
      regex = "(.*):(.*)"
      replacement = "$1:$2"
      target_label  = "instance"
    }

    rule {
      replacement = "/var/log/solr/solr.log"
      target_label = "filename"
    }
}

loki.source.kubernetes "solr_logs" {
  targets      = discovery.relabel.solr_logs.output
  forward_to   = [loki.process.logs_service.receiver]
}

loki.process "apache_solr_logs_process" {
  forward_to = [loki.process.logs_service.receiver]

  stage.cri {}
  stage.multiline {
    firstline = "^\\\\d{4}-\\\\d{2}-\\\\d{2} \\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d{3}"
  }
  stage.regex {
    expression = "^\\\\d{4}-\\\\d{2}-\\\\d{2} \\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d{3} (?P<level>\\\\w+)"
  }
  stage.labels {
    values = {
      level = "",
    }
  }

  stage.match {
      selector = "{tmp_container_runtime=\\"docker\\"}"
      // the docker processing stage extracts the following k/v pairs: log, stream, time
      stage.docker {}
  }

  stage.match {
      selector = "{tmp_container_runtime=\\"containerd\\"}"
      // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
      stage.cri {}
  }
}`;

export default { metrics, logs };
