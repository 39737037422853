import React, { memo, useMemo } from 'react';
import { DataSourceRef } from '@grafana/schema';
import { getNodeDetailOptimization } from 'components/scenes/NodeDetailOptimization/NodeDetailOptimization';
import { getNamespaceDetailOptimization } from 'components/scenes/NamespaceDetailOptimization/NamespaceDetailOptimization';
import { getWorkloadDetailOptimization } from 'components/scenes/WorkloadDetailOptimization/WorkloadDetailOptimization';
import { getClusterDetailOptimization } from 'components/scenes/ClusterDetailOptimization/ClusterDetailOptimization';
import { getPodDetailOptimization } from 'components/scenes/PodDetailOptimization/PodDetailOptimization';
import { TimeRange } from '@grafana/data';
import { DateTimeRange } from 'store/timeRange';
import omit from 'lodash/omit';
import { getDatasourceNameFromId } from 'helpers/helpers';
import { SceneControlsSpacer, SceneObject, SceneTimePicker, SceneTimeRangeState } from '@grafana/scenes';
import OpenInK8sButton from 'components/OpenInK8sButton/OpenInK8sButton';
import { PLUGIN_ROOT_URL } from '../../constants';
import { getRefreshPicker } from 'helpers/scenes';

export interface ObjectDetailExtensionProps {
  entityType: 'Node' | 'Namespace' | 'Workload' | 'KubeCluster' | 'Pod';
  entityName: string;
  clusterName: string;
  initialTimeRange: DateTimeRange;
  namespace?: string;
  workloadType?: string;
  workload?: string;
  locationTitle?: string;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  promDatasourceRef: DataSourceRef;
  lokiDatasourceRef: DataSourceRef;
}

export function getExtensionDetailsOptimizationControls({
  withPickers,
  relativeTimeRange,
  urlPath,
  locationTitle = 'Previous',
}: {
  withPickers?: boolean;
  relativeTimeRange?: SceneTimeRangeState;
  urlPath: string;
  locationTitle?: string;
}) {
  let controls: SceneObject[] = [];
  if (withPickers) {
    const sceneTimePicker = new SceneTimePicker(relativeTimeRange!);
    controls = [
      new OpenInK8sButton({ locationTitle, href: `${PLUGIN_ROOT_URL}/navigation${urlPath}` }),
      new SceneControlsSpacer(),
      sceneTimePicker,
      getRefreshPicker(),
    ];
  }

  return controls;
}

function ObjectDetailExtension({ ...props }: ObjectDetailExtensionProps) {
  const scene = useMemo(() => {
    if (!props) {
      return null;
    }
    const {
      entityType,
      entityName,
      clusterName,
      initialTimeRange,
      namespace,
      workloadType,
      workload,
      promDatasourceRef,
      lokiDatasourceRef,
      onTimeRangeChange,
    } = props;

    const relativeTimeRange = {
      from: initialTimeRange?.raw?.from as string,
      to: initialTimeRange?.raw?.to as string,
      value: initialTimeRange,
    };

    const commonProps = {
      datasource: promDatasourceRef,
      cluster: clusterName,
      prometheusName: getDatasourceNameFromId(promDatasourceRef),
      lokiName: getDatasourceNameFromId(lokiDatasourceRef),
      onTimeRangeChange,
      relativeTimeRange,
      withPickers: true,
      isEmbeddedExtension: true,
      locationTitle: props.locationTitle || '',
    };

    switch (entityType) {
      case 'Node':
        return getNodeDetailOptimization({
          ...commonProps,
          node: entityName,
        });
      case 'Namespace':
        return getNamespaceDetailOptimization({
          ...commonProps,
          namespace: entityName,
        });
      case 'Workload':
        return getWorkloadDetailOptimization({
          ...commonProps,
          namespace: namespace || '',
          workloadType: workloadType || '',
          workload: entityName,
        });

      case 'KubeCluster':
        return getClusterDetailOptimization({
          ...commonProps,
        });
      case 'Pod':
        return getPodDetailOptimization({
          ...commonProps,
          namespace: namespace || '',
          podName: entityName,
          workload: workload || '',
          workloadType: workloadType || '',
        });
    }
    // excluding initialTimeRange from the dependency array to avoid re-rendering after time range change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(omit(props, 'initialTimeRange')));

  return scene && <scene.Component model={scene} />;
}

export default ObjectDetailExtension;

export const ObjectDetailExtensionParams = {
  title: 'K8s Object Detail',
  description: 'Metrics details for various k8s objects',
  id: 'grafana-k8s-app/object-detail-extension/v1',
  component: memo(ObjectDetailExtension),
};
