const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
  discovery.relabel "tomcat_service_jmx" {
      targets = discovery.kubernetes.services.targets

      rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_service_name", "__meta_kubernetes_service_port_name"]
        separator     = ":"
        regex         = "(<tomcat-namespace>:<tomcat-service-name>:<jmx-port-name>)"
      }

      rule {
        replacement  = "integrations/apache-tomcat"
        target_label = "job"
      }
  }

  prometheus.scrape "tomcat_metrics" {
    targets      = discovery.relabel.tomcat_service_jmx.output
    forward_to   = [prometheus.relabel.metrics_service.receiver]
  }`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
    discovery.relabel "tomcat_logs" {
      targets = discovery.relabel.pod_logs.output

      rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
        separator     = ":"
        regex         = "(<tomcat-namespace>:<tomcat-pod-name>:<tomcat-container-name>)"
      }

      rule {
        replacement   = "<tomcat-service-name>.<tomcat-namespace>.svc:<jmx-exporter-port>"
        target_label  = "instance"
      }

      rule {
        replacement  = "integrations/apache-tomcat"
        target_label = "job"
      }

      rule {
        replacement  = "catalina.out"
        target_label = "log_type"
      }
    }

    loki.source.kubernetes "tomcat_logs" {
      targets      = discovery.relabel.tomcat_logs.output
      forward_to   = [loki.process.logs_service.receiver]
    }`;

export default { metrics, logs };
