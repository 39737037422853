/*

Good Metrics
============

node_memory_MemAvailable_bytes, windows_memory_available_bytes
  data is accurate when compared to actual memory usage

Bad Metrics
===========

kube_node_status_allocatable{resource="ephemeral_storage"}
  data is not accurate compared to actual disk usage

kube_node_status_allocatable{resource="cpu"}
  data is not accurate compared to actual cpu usage

kube_node_status_allocatable{resource="memory"}
  data is not accurate compared to actual memory usage
*/

import { cadvisor } from './promql/metrics/cadvisor';
import { ksm } from './promql/metrics/ksm';
import { promql } from './promql/promql';
import { snippets } from './promql/snippets';

// Logs/events labels
const defaultLogsLabel = `job!~"integrations/kubernetes/eventhandler|infra-monitoring/eventrouter"`;
const defaultEventsLabel = `job=~"integrations/kubernetes/eventhandler|infra-monitoring/eventrouter"`;

export const Queries = {
  IsDataFlowing: promql.count({ expr: promql.last_over_time({ expr: 'kube_node_info{cluster!=""}' }) }),
  Namespace: (namespace?: string, cluster?: string) => {
    namespace = namespace || '.+';
    cluster = cluster || '.+';
    return `
      # prefer namespaces with a count of workloads
      sum by (cluster, namespace) (
          group by (cluster, namespace, workload) (${snippets.pods.withWorkloads(cluster, namespace)})
      )
      # otherwise fallback to a zero value for namespaces without workloads
      OR on (cluster, namespace)
      last_over_time(
        group by (cluster, namespace) (kube_namespace_status_phase{cluster=~"${cluster}", namespace=~"${namespace}", phase="Active"} == 1)
      [$__range:]) - 1
    `;
  },
  NamespacePhase: (namespace?: string, cluster?: string) => {
    namespace = namespace || '.+';
    cluster = cluster || '.+';
    return `
     sum(kube_namespace_status_phase{cluster=~"${cluster}", namespace=~"${namespace}"} == 1) by (phase, namespace)`;
  },
  AlertsByNamespace: (namespace?: string, cluster?: string) => {
    namespace = namespace || '.+';
    cluster = cluster || '.+';
    return `
    count by (namespace, cluster) (
      ALERTS{cluster=~"${cluster}", namespace=~"${namespace}", alertname=~"(Kube.*|CPUThrottlingHigh)", alertstate="firing"}
      )
    `;
  },

  /**
   * Workload type is no longer required.
   *
   * @param cluster   - Must be a single cluster, not pipe-separated, e.g. "kubeadm-control-plane".
   * @param namespace - Must be a single namespace, not pipe-separated, e.g. "kube-system".
   * @param workload  - Optional: single workload name or pipe-separated, e.g. "", "cilium" or "cilium|app".
   * @param node      - Optional: single node name or pipe-separated, e.g. "", "pool-tpvwuymt1-jyg4f" or "pool-tpvwuymt1-jyg4f|pool-tpvwuymt1-jyg4x".
   *
   * @returns PromQL query with the following labels:
   *            cluster, namespace, workload, workload_type, pod, node, pod_ip, uid, asserts_env, asserts_site, phase.
   */
  PodStatusPhase: (
    cluster: string,
    namespace?: string | undefined,
    workload?: string | undefined,
    node?: string | undefined
  ) => {
    workload = workload || '.+';
    node = node || '.*';
    namespace = namespace || '.+';

    return `
    # take the most recent entry of each pod, given that ip and uid can change over time
    topk by (cluster, namespace, workload, workload_type, pod) (1,
      max by (cluster, namespace, workload, workload_type, pod, node, pod_ip, uid, asserts_env, asserts_site) (
        # bare pods
        label_replace(
          label_replace(
            ${promql.last_over_time({
              expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="", pod=~"${workload}"})`,
            })}
          , "workload", "$1", "pod", "(.+)")
        , "workload_type", "pod", "", "")

        OR

        # static pods
        label_replace(
          label_replace(
            ${promql.last_over_time({
              expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="Node", pod=~"${workload}"})`,
            })}
          , "workload", "$1", "pod", "(.+)")
        , "workload_type", "staticpod", "", "")

        OR

        # replicaset
        label_replace(
          label_replace(
            label_replace(
              ${promql.last_over_time({
                expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="ReplicaSet", created_by_name=~"${workload}"})`,
              })}
            , "replicaset", "$1", "created_by_name", "(.+)")
            * on (cluster, namespace, replicaset) group_left
            group by (cluster, namespace, replicaset) (${promql.last_over_time({
              expr: `timestamp(kube_replicaset_owner{cluster="${cluster}", namespace=~"${namespace}", replicaset=~"${workload}", owner_kind=""})`,
            })})
          , "workload", "$1", "created_by_name", "(.+)")
        , "workload_type", "replicaset", "", "")

        OR

        # deployment
        label_replace(
          label_replace(
            label_replace(
              ${promql.last_over_time({
                expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="ReplicaSet", created_by_name=~"${workload}-.+"})`,
              })}
            , "replicaset", "$1", "created_by_name", "(.+)")
            * on (cluster, namespace, replicaset) group_left (owner_name)
            group by (cluster, namespace, replicaset, owner_name) (${promql.last_over_time({
              expr: `timestamp(kube_replicaset_owner{cluster="${cluster}", namespace=~"${namespace}", owner_kind="Deployment", owner_name=~"${workload}"})`,
            })})
          , "workload", "$1", "owner_name", "(.+)")
        , "workload_type", "deployment", "", "")

        OR

        # daemonset
        label_replace(
          label_replace(
            ${promql.last_over_time({
              expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="DaemonSet", created_by_name=~"${workload}"})`,
            })}
          , "workload", "$1", "created_by_name", "(.+)")
        , "workload_type", "daemonset", "", "")

        OR

        # job
        label_replace(
          label_replace(
            ${promql.last_over_time({
              expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="Job", created_by_name=~"${workload}"})`,
            })}
          , "workload", "$1", "created_by_name", "(.+)")
        , "workload_type", "job", "", "")

        OR

        # statefulset
        label_replace(
          label_replace(
            ${promql.last_over_time({
              expr: `timestamp(kube_pod_info{cluster="${cluster}", namespace=~"${namespace}", node=~"${node}", created_by_kind="StatefulSet", created_by_name=~"${workload}"})`,
            })}
          , "workload", "$1", "created_by_name", "(.+)")
        , "workload_type", "statefulset", "", "")
      )
      * on (cluster, namespace, pod) group_left (phase)
      group by (cluster, namespace, pod, phase) (
        # take most recent phase of each pod, given that the phase can change over time
        topk by (cluster, namespace, pod) (1,
          ${promql.last_over_time({
            expr: `timestamp(kube_pod_status_phase{cluster="${cluster}", namespace=~"${namespace}", pod=~"${workload}.*"} == 1)`,
          })}
        )
      )
    )
    `;
  },
  PodContainersWaiting: (cluster: string, namespace?: string | undefined) => {
    namespace = namespace || '.+';
    cluster = cluster || '.+';
    return `
    sum by (cluster, namespace, pod, reason) (kube_pod_container_status_waiting_reason{cluster=~"${cluster}", namespace=~"${namespace}"} > 0)`;
  },
  PodStartTime: (cluster: string, namespace: string, podName: string) =>
    `kube_pod_start_time{pod="${podName}", cluster="${cluster}", namespace="${namespace}"}`,
  PodRestarts: (cluster: string, namespace: string, podName: string) =>
    `sum(kube_pod_container_status_restarts_total{pod="${podName}", cluster="${cluster}", namespace="${namespace}"})`,
  ContainerInfo: (cluster: string, namespace: string, podName: string) =>
    promql.last_over_time({
      expr: `max by (cluster, namespace, pod, container, image_spec) (kube_pod_container_info{pod="${podName}", cluster="${cluster}", namespace="${namespace}"})`,
    }),
  WaitingContainers: (cluster: string, namespace: string, podName: string) =>
    `max by (cluster, namespace, pod, container, uid, reason) (kube_pod_container_status_waiting_reason{pod="${podName}", cluster="${cluster}", namespace="${namespace}"})`,
  ContainerAlertsCount: (cluster: string, namespace: string, podName: string) =>
    `count by (container) (ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster="${cluster}", namespace="${namespace}", pod="${podName}", container!=""})`,
  SinglePodStatus: (cluster: string, namespace: string, podName: string) => `
    max by (cluster, namespace, pod, phase) (kube_pod_status_phase{cluster="${cluster}", namespace="${namespace}", pod="${podName}"} > 0)
    * on (cluster, namespace, pod) group_left (node, created_by_kind, created_by_name, host_ip, pod_ip)
    max by (cluster, node, namespace, pod, created_by_kind, created_by_name, host_ip, pod_ip) (
      kube_pod_info{cluster="${cluster}", namespace="${namespace}", pod="${podName}"}
    )`,
  DaemonSet: (cluster: string, namespace: string, workload?: string) => {
    const workloadFilter = workload ? `, daemonset="${workload}"` : '';
    return `{__name__=~"kube_daemonset.*", namespace="${namespace}", cluster=~"${cluster}"${workloadFilter}}`;
  },
  ReplicaSet: (cluster: string, namespace: string, workload?: string) => {
    const workloadFilter = workload ? `, replicaset="${workload}"` : '';
    return `{__name__=~"kube_replicaset.*", namespace="${namespace}", cluster=~"${cluster}"${workloadFilter}}`;
  },
  StatefulSet: (cluster: string, namespace: string, workload?: string) => {
    const workloadFilter = workload ? `, statefulset="${workload}"` : '';
    return `{__name__=~"kube_statefulset.*", namespace="${namespace}", cluster=~"${cluster}"${workloadFilter}}`;
  },
  Job: (cluster: string, namespace: string, workload?: string) => {
    const workloadFilter = workload ? `, job_name="${workload}"` : '';
    return `{__name__=~"kube_job.*", namespace="${namespace}", cluster=~"${cluster}"${workloadFilter}}`;
  },
  Deployment: (cluster: string, namespace: string, workload?: string) => {
    const workloadFilter = workload ? `, deployment="${workload}"` : '';
    return `{__name__=~"kube_deployment.*", namespace="${namespace}", cluster=~"${cluster}"${workloadFilter}}`;
  },
  PodsAlertsCount: (cluster: string, namespace?: string | undefined) => {
    namespace = namespace || '.+';
    cluster = cluster || '.+';
    return `
    count by (cluster, namespace, pod) (ALERTS{cluster=~"${cluster}", namespace=~"${namespace}", alertname=~"(Kube.*|CPUThrottlingHigh)", alertstate="firing", pod!=""})`;
  },
  ClusterList: `
    last_over_time(
      group by (cluster) (kube_node_info{cluster!=""})
    [$__range:])
  `,
  InstrumentedApps: (cluster: string, namespace?: string, workload?: string, workloadType?: string, pod?: string) => {
    const namespaceFilter = namespace ? `, k8s_namespace_name="${namespace}"` : '';
    const workloadFilter = workload && workloadType ? `, k8s_${workloadType}_name="${workload}"` : '';
    const podFilter = pod ? `, k8s_pod_name="${pod}"` : '';
    const metricFilters = `k8s_cluster_name="${cluster}"${namespaceFilter}${workloadFilter}${podFilter}`;
    return `
    topk by (job) (1,
      group by (job, deployment_environment) (target_info{${metricFilters}})
    OR
      group by (job, deployment_environment) (traces_target_info{${metricFilters}})
    )`;
  },
};

export const ResourceQueries = {
  ClusterAlertsCount: (cluster?: string) => {
    cluster = cluster || '.+';
    return `
      count by (cluster) (
        ALERTS{cluster=~"${cluster}", alertname=~"(Kube.*|CPUThrottlingHigh)", alertstate="firing"}
      )
    `;
  },
  ClusterMemory: `avg_over_time(avg by (cluster) (
    1 - sum by (cluster) (
      max by (cluster, instance) (
        windows_memory_available_bytes{cluster!=""}
        OR
        node_memory_MemAvailable_bytes{cluster!=""}
      )
    )
    /
    sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{cluster!="", resource="memory"}))
  )[$__range:$__interval])`,
  NodeCpuUsage:
    '1 - avg by (cluster, instance) (max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster!="", mode="idle"}[$__range]))) >= 0',
  NamespaceMemory: (cluster?: string, namespace?: string) => {
    cluster = cluster || '.+';
    namespace = namespace || '.+';
    return `
    avg by (namespace, cluster) (
      node_namespace_pod_container:container_memory_working_set_bytes{cluster=~"${cluster}", namespace=~"${namespace}", container!="POD", container!="", image!=""}
      / on (cluster, instance) group_left
      max by (cluster, instance) (machine_memory_bytes{cluster=~"${cluster}"})
    )`;
  },
  NamespaceCpuUsage: (cluster?: string, namespace?: string) => {
    cluster = cluster || '.+';
    namespace = namespace || '.+';
    return `
    sum by (namespace, cluster) (
      node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster=~"${cluster}", namespace=~"${namespace}", container!="POD", container!=""}
    )
    / on (cluster) group_left()
    sum by (cluster) (
      max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster=~"${cluster}"}[$__range]))
    )`;
  },
  PodCpuUsageVsRequested: (namespace: string, cluster: string, pod: string) =>
    `sum(node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}) by (pod) / sum(cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}) by (pod)`,
  WorkloadPodCpuUsageVsRequested: (namespace: string, cluster: string, workload: string) =>
    `sum(node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod=~"${workload}.*"}) by (pod) / sum(cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}",  pod=~"${workload}.*"}) by (pod)`,
  PodMemUsage: (cluster: string, pod: string) => `
    sum by (pod) (
      container_memory_working_set_bytes{container!="", image!="", cluster="${cluster}", pod="${pod}"}
      /
      on (cluster, instance) group_left
      max by (cluster, instance) (machine_memory_bytes{cluster="${cluster}"})
    )`,

  NamespaceUsage: {
    CpuAvg: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      avg_over_time(
        sum by (cluster, namespace) (
          max by (cluster, namespace, pod, container) (node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster=~"${cluster}", container!=""})
        )[$__range:$__interval]
      )`;
    },
    CpuAvgPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      ${ResourceQueries.NamespaceUsage.CpuAvg(
        cluster
      )} / on (cluster, namespace) group_left sum by (cluster, namespace) (
        namespace_cpu:kube_pod_container_resource_requests:sum{${cluster ? `cluster=~"${cluster}"` : ''}}
      )`;
    },
    CpuMax: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      max_over_time(
        sum by (cluster, namespace) (
          max by (cluster, namespace, pod, container) (node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster=~"${cluster}", container!=""})
        )[$__range:$__interval]
      )`;
    },
    CpuMaxPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      ${ResourceQueries.NamespaceUsage.CpuMax(
        cluster
      )} / on (cluster, namespace) group_left sum by (cluster, namespace) (
        namespace_cpu:kube_pod_container_resource_requests:sum{cluster=~"${cluster}"}
      )`;
    },
    MemoryAvg: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      avg_over_time(
        sum by (cluster, namespace) (max by (cluster, namespace, pod, container) (node_namespace_pod_container:container_memory_rss{cluster=~"${cluster}", container!=""}))
        [$__range:$__interval]
      )`;
    },
    MemoryAvgPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      ${ResourceQueries.NamespaceUsage.MemoryAvg(
        cluster
      )} /  on (cluster, namespace) group_left sum by (cluster, namespace) (
        namespace_memory:kube_pod_container_resource_requests:sum{cluster=~"${cluster}"}
      )`;
    },
    MemoryMax: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      max_over_time(
        sum by (cluster, namespace) (max by (cluster, namespace, pod, container) (node_namespace_pod_container:container_memory_rss{cluster=~"${cluster}", container!=""}))
        [$__range:$__interval]
      )`;
    },
    MemoryMaxPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      ${ResourceQueries.NamespaceUsage.MemoryMax(
        cluster
      )} / on (cluster, namespace) group_left sum by (cluster, namespace) (
        namespace_memory:kube_pod_container_resource_requests:sum{cluster=~"${cluster}"}
      )`;
    },
  },

  NamespaceCost: {
    CostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(sum by (cluster, namespace) (
        # actual cost of cpu allocation and usage is the max of:
        max by (cluster, namespace, node) (
            # cpu requests (in cores)
            sum by (cluster, namespace, node, resource) (kube_pod_container_resource_requests{cluster=~"${cluster}", container!="", resource="cpu"})
            OR
            # cpu usage (in cores)
            sum by (cluster, namespace, node) (
              rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster=~"${cluster}"})
      )[$__range:1h])
      +
      sum_over_time(sum by (cluster, namespace) (
          # actual cost of memory allocation and usage is the max of:
          max by (cluster, namespace, node) (
              # memory requests (in bytes)
              sum by (cluster, namespace, node, resource) (kube_pod_container_resource_requests{cluster=~"${cluster}", container!="", resource="memory"})
              OR
              # memory usage (in bytes)
              (
                sum by (cluster, namespace, node) (
                  container_memory_rss{cluster=~"${cluster}", container!="", node!=""}
                  OR
                  label_replace(
                    container_memory_rss{cluster=~"${cluster}", container!="", node=""}
                    , "node", "$1", "instance", "([^:]+).*"
                  )
                )
              )
          )
          # in GiB
          /1024/1024/1024

          # multiplied by the hourly cost per GB per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster=~"${cluster}"})
        )[$__range:1h])`;
    },
    CostProjected: (cluster: string) => `
      (${ResourceQueries.NamespaceCost.CostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(sum by (cluster, namespace) (
        (
            # requests
            sum by (cluster, namespace, node) (
                kube_pod_container_resource_requests{cluster=~"${cluster}", container!="", resource="cpu"}
            )
            # usage
            - on (cluster, namespace, node) group_left
            sum by (cluster, namespace, node) (
              rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster=~"${cluster}"})
      )[$__range:1h])`;
    },
    CpuIdleCostProjected: (cluster: string) => `
      (${ResourceQueries.NamespaceCost.CpuIdleCostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(sum by (cluster, namespace) (
        (
            # requests
            sum by (cluster, namespace, node) (
                kube_pod_container_resource_requests{cluster=~"${cluster}", container!="", resource="memory"}
            )
            # usage
            - on (cluster, namespace, node) group_left
            sum by (cluster, namespace, node) (
              container_memory_rss{cluster=~"${cluster}", container!="", node!=""}
              OR
              label_replace(
                container_memory_rss{cluster=~"${cluster}", container!="", node=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster=~"${cluster}"})
      )[$__range:1h])`;
    },
    MemoryIdleCostProjected: (cluster: string) => `
      (${ResourceQueries.NamespaceCost.MemoryIdleCostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
  },

  NodeUsage: {
    CpuAvg: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      avg_over_time(sum by (cluster, node) (
        label_replace(
            1 - max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster=~"${cluster}", mode="idle"}[$__rate_interval]))
            , "node", "$1", "instance", "([^:]+).*"
        )
      )[$__range:$__rate_interval])`;
    },
    CpuAvgPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `${ResourceQueries.NodeUsage.CpuAvg(cluster)} /
      sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="cpu"}))`;
    },
    CpuMax: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      max_over_time(sum by (cluster, node) (
        label_replace(
            1 - max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster=~"${cluster}", mode="idle"}[$__rate_interval]))
            , "node", "$1", "instance", "([^:]+).*"
        )
      )[$__range:$__rate_interval])`;
    },
    CpuMaxPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `${ResourceQueries.NodeUsage.CpuMax(cluster)} /
      sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="cpu"}))`;
    },
    MemoryAvg: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      avg_over_time(sum by (cluster, node) (
        sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="memory"}))
        - on (cluster, node) group_left
        max by (cluster, node) (
          label_replace(
            windows_memory_available_bytes{cluster=~"${cluster}"}
            OR
            node_memory_MemAvailable_bytes{cluster=~"${cluster}"}
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )[$__range:$__interval])`;
    },
    MemoryAvgPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `${ResourceQueries.NodeUsage.MemoryAvg(cluster)} /
      sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="memory"}))`;
    },
    MemoryMax: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      max_over_time(sum by (cluster, node) (
        sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="memory"}))
        - on (cluster, node) group_left
        max by (cluster, node) (
          label_replace(
            windows_memory_available_bytes{cluster=~"${cluster}"}
            OR
            node_memory_MemAvailable_bytes{cluster=~"${cluster}"}
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )[$__range:$__interval])`;
    },
    MemoryMaxPercent: (cluster: string) => {
      cluster = cluster || '.+';
      return `${ResourceQueries.NodeUsage.MemoryMax(cluster)} /
      sum by (cluster, node) (max by (cluster, node) (kube_node_status_capacity{cluster=~"${cluster}", resource="memory"}))`;
    },
  },

  NodeCost: {
    CostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(sum by (cluster, node) (
        max by (cluster, node) (node_total_hourly_cost{cluster=~"${cluster}"})
      )[$__range:1h])`;
    },
    CostProjected: (cluster: string) => `
      (${ResourceQueries.NodeCost.CostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(sum by (cluster, node) (
        label_replace(
            sum by (cluster, instance) (max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster=~"${cluster}", mode="idle"}[$__rate_interval])))
            , "node", "$1", "instance", "([^:]+).*"
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster=~"${cluster}"})
      )[$__range:1h])`;
    },
    CpuIdleCostProjected: (cluster: string) => `
      (${ResourceQueries.NodeCost.CpuIdleCostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string) => {
      cluster = cluster || '.+';
      return `
      sum_over_time(
        sum by (cluster, node) (
          max by (cluster, node) (
            label_replace(
                windows_memory_available_bytes{cluster=~"${cluster}"}
                OR
                node_memory_MemAvailable_bytes{cluster=~"${cluster}"}
                , "node", "$1", "instance", "([^:]+).*"
            )
          )
          # in GiB
          /1024/1024/1024
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster=~"${cluster}"})
        )[$__range:1h]
      )`;
    },
    MemoryIdleCostProjected: (cluster: string) => `
      (${ResourceQueries.NodeCost.MemoryIdleCostCurrent(cluster)}) * ((60*60*24*30)/$__range_s)
    `,
  },

  ClusterUsage: {
    CpuAvg: () => `
      avg_over_time(sum by (cluster) (
          1 - max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{mode="idle"}[$__rate_interval]))
      )[$__range:$__rate_interval])
    `,
    CpuAvgPercent: () => `
      ${ResourceQueries.ClusterUsage.CpuAvg()} / sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{resource="cpu"}))
    `,
    CpuMax: () => `
      max_over_time(sum by (cluster) (
          1 - max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{mode="idle"}[$__rate_interval]))
      )[$__range:$__rate_interval])
    `,
    CpuMaxPercent: () => `
      ${ResourceQueries.ClusterUsage.CpuMax()} / sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{resource="cpu"}))
    `,
    MemoryAvg: () => `
      avg_over_time(sum by (cluster) (
        max by (cluster, node) (kube_node_status_capacity{resource="memory"})
        - on (cluster, node) group_left
        max by (cluster, node) (
          label_replace(
              windows_memory_available_bytes{}
              OR
              node_memory_MemAvailable_bytes{}
              , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )[$__range:$__interval])
    `,
    MemoryAvgPercent: () => `
      ${ResourceQueries.ClusterUsage.MemoryAvg()} / sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{resource="memory"}))
    `,
    MemoryMax: () => `
      max_over_time(sum by (cluster) (
        max by (cluster, node) (kube_node_status_capacity{resource="memory"})
        - on (cluster, node) group_left
        max by (cluster, node) (
          label_replace(
              windows_memory_available_bytes{}
              OR
              node_memory_MemAvailable_bytes{}
              , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )[$__range:$__interval])
    `,
    MemoryMaxPercent: () => `
      ${ResourceQueries.ClusterUsage.MemoryMax()} / sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{resource="memory"}))
    `,
  },

  ClusterCost: {
    CostCurrent: () => `
      sum_over_time(sum by (cluster) (
        max by (cluster, node) (node_total_hourly_cost{})
      )[$__range:1h])
    `,
    CostProjected: () => `
      (${ResourceQueries.ClusterCost.CostCurrent()}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: () => `
      sum_over_time(sum by (cluster) (
        label_replace(
            sum by (cluster, instance) (max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{mode="idle"}[$__rate_interval])))
            , "node", "$1", "instance", "([^:]+).*"
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{})
      )[$__range:1h])
    `,
    CpuIdleCostProjected: () => `
      (${ResourceQueries.ClusterCost.CpuIdleCostCurrent()}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: () => `
      sum_over_time(sum by (cluster) (
        max by (cluster, node) (
          label_replace(
              windows_memory_available_bytes{}
              OR
              node_memory_MemAvailable_bytes{}
              , "node", "$1", "instance", "([^:]+).*"
          )
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{})
      )[$__range:1h])
    `,
    MemoryIdleCostProjected: () => `
      (${ResourceQueries.ClusterCost.MemoryIdleCostCurrent()}) * ((60*60*24*30)/$__range_s)
    `,
  },

  WorkloadPodUsage: {
    CpuAvg: (cluster: string, namespace: string, pods: string) => `
      avg_over_time(sum by (pod) (
        node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!=""}
      )[$__range:$__interval])
    `,
    CpuAvgPercent: (cluster: string, namespace: string, pods: string) => `
      ${ResourceQueries.WorkloadPodUsage.CpuAvg(
        cluster,
        namespace,
        pods
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="cpu"})
    `,
    CpuMax: (cluster: string, namespace: string, pods: string) => `
      max_over_time(sum by (pod) (
        node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!=""}
      )[$__range:$__interval])
    `,
    CpuMaxPercent: (cluster: string, namespace: string, pods: string) => `
      ${ResourceQueries.WorkloadPodUsage.CpuMax(
        cluster,
        namespace,
        pods
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="cpu"})
    `,
    MemoryAvg: (cluster: string, namespace: string, pods: string) => `
      avg_over_time(sum by (pod) (
        node_namespace_pod_container:container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!=""}
      )[$__range:$__interval])
    `,
    MemoryAvgPercent: (cluster: string, namespace: string, pods: string) => `
      ${ResourceQueries.WorkloadPodUsage.MemoryAvg(
        cluster,
        namespace,
        pods
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="memory"})
    `,
    MemoryMax: (cluster: string, namespace: string, pods: string) => `
      max_over_time(sum by (pod) (
        max by (pod, container) (node_namespace_pod_container:container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!=""})
      )[$__range:$__interval])
    `,
    MemoryMaxPercent: (cluster: string, namespace: string, pods: string) => `
      ${ResourceQueries.WorkloadPodUsage.MemoryMax(
        cluster,
        namespace,
        pods
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="memory"})
    `,
  },

  WorkloadPodCost: {
    CostCurrent: (cluster: string, namespace: string, pods: string) => `
      # Total compute cost by container over the selected time range
      # Same as query in total cost panel above except "sum by (container)" at the top level.
      sum_over_time(sum by (pod) (
          # actual cost of cpu allocation and usage is the max of:
          max by (cluster, node, pod) (
              # cpu requests (in cores)
              sum by (cluster, node, pod, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="cpu"})
              OR
              # cpu usage (in cores)
              sum by (cluster, node, pod) (
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node!="", container!=""}[$__rate_interval])
                OR
                label_replace(
                  rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node="", container!=""}[$__rate_interval])
                  , "node", "$1", "instance", "([^:]+).*"
                )
              )
          )
          # multiplied by the hourly cost per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
      +
      sum_over_time(sum by (pod) (
          # actual cost of memory allocation and usage is the max of:
          max by (cluster, node, pod) (
              # memory requests (in bytes)
              sum by (cluster, node, pod, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="memory"})
              OR
              # memory usage (in bytes)
              (
                sum by (cluster, node, pod) (
                  container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", node!=""}
                  OR
                  label_replace(
                    container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", node=""}
                    , "node", "$1", "instance", "([^:]+).*"
                  )
                )
              )
          )
          # in GiB
          /1024/1024/1024

          # multiplied by the hourly cost per GB per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostProjected: (cluster: string, namespace: string, pods: string) => `
      (${ResourceQueries.WorkloadPodCost.CostCurrent(cluster, namespace, pods)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string, namespace: string, pods: string) => `
      sum_over_time(sum by (pod) (
        (
            # requests
            sum by (cluster, node, pod) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="cpu"}
            )
            # usage
            - on (cluster, node, pod) group_left
            sum by (cluster, node, pod) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CpuIdleCostProjected: (cluster: string, namespace: string, pods: string) => `
      (${ResourceQueries.WorkloadPodCost.CpuIdleCostCurrent(cluster, namespace, pods)}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string, namespace: string, pods: string) => `
      sum_over_time(
        sum by (pod) (
            (
                # requests
                sum by (cluster, pod) (
                    kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", container!="", resource="memory"}
                )
                # usage
                - on (cluster, pod) group_left (node)
                sum by (cluster, node, pod) (
                  container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node!="", container!=""}
                  OR
                  label_replace(
                    container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"${pods}", node="", container!=""}
                    , "node", "$1", "instance", "([^:]+).*"
                  )
                )
            )
            # in GiB
            /1024/1024/1024
            * on (cluster, node) group_left
            max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
    `,
    MemoryIdleCostProjected: (cluster: string, namespace: string, pods: string) => `
      (${ResourceQueries.WorkloadPodCost.MemoryIdleCostCurrent(cluster, namespace, pods)}) * ((60*60*24*30)/$__range_s)
    `,
  },

  WorkloadUsage: {
    CpuAvg: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        avg_over_time(
          sum by (pod) (max by (pod, container) (node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster=~"${cluster}", namespace=~"${namespace}", container!=""}))
          [$__range:$__interval]
        )
      )`;
    },

    CpuAvgPercent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      ${ResourceQueries.WorkloadUsage.CpuAvg(
        cluster,
        namespace
      )} / sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="cpu"})
      )`;
    },
    CpuMax: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        max_over_time(
          sum by (pod) (max by (pod, container) (node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster=~"${cluster}", namespace=~"${namespace}", container!=""}))
          [$__range:$__interval]
        )
      )`;
    },
    CpuMaxPercent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      ${ResourceQueries.WorkloadUsage.CpuMax(
        cluster,
        namespace
      )} / sum by (cluster, namespace, workload,workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="cpu"})
      )`;
    },

    MemoryAvg: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        avg_over_time(
            sum by (pod) (max by (pod, container) (node_namespace_pod_container:container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", container!=""}))
            [$__range:$__interval]
        )
      )`;
    },
    MemoryAvgPercent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      ${ResourceQueries.WorkloadUsage.MemoryAvg(
        cluster,
        namespace
      )} / sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="memory"})
      )`;
    },
    MemoryMax: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        max_over_time(
            sum by (pod) (max by (pod, container) (node_namespace_pod_container:container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", container!=""}))
            [$__range:$__interval]
        )
      )`;
    },
    MemoryMaxPercent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      ${ResourceQueries.WorkloadUsage.MemoryMax(
        cluster,
        namespace
      )} / sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="memory"})
      )`;
    },
  },

  WorkloadCost: {
    CostCurrent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (
            sum_over_time(sum by (pod) (
                # actual cost of cpu allocation and usage is the max of:
                max by (cluster, namespace, node, pod) (
                    # cpu requests (in cores)
                    sum by (cluster, namespace, node, pod, resource) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="cpu"})
                    OR
                    # cpu usage (in cores)
                    sum by (cluster, namespace, node, pod) (
                      rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", namespace=~"${namespace}", node!="", container!=""}[$__rate_interval])
                      OR
                      label_replace(
                        rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", namespace=~"${namespace}", node="", container!=""}[$__rate_interval])
                        , "node", "$1", "instance", "([^:]+).*"
                      )
                    )
                )
                # multiplied by the hourly cost per node where this pod is running
                * on (cluster, node) group_left
                max by (cluster, node) (node_cpu_hourly_cost{cluster=~"${cluster}"})
            )[$__range:1h])
            +
            sum_over_time(sum by (pod) (
                # actual cost of memory allocation and usage is the max of:
                max by (cluster, node, pod) (
                    # memory requests (in bytes)
                    sum by (cluster, node, pod, resource) (kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="memory"})
                    OR
                    # memory usage (in bytes)
                    sum by (cluster, node, pod) (
                      container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", node!="", container!=""}
                      OR
                      label_replace(
                        container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", node="", container!=""}
                        , "node", "$1", "instance", "([^:]+).*"
                      )
                    )
                )
                # in GiB
                /1024/1024/1024

                # multiplied by the hourly cost per GB per node where this pod is running
                * on (cluster, node) group_left
                max by (cluster, node) (node_ram_hourly_cost{cluster=~"${cluster}"})
            )[$__range:1h])
        )
      )`;
    },
    CostProjected: (cluster: string, namespace: string) => `
      (${ResourceQueries.WorkloadCost.CostCurrent(cluster, namespace)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (
            sum_over_time(sum by (pod) (
                (
                    # requests
                    sum by (cluster, node, pod) (
                        kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="cpu"}
                    )
                    # usage
                    - on (cluster, node, pod) group_left
                    sum by (cluster, node, pod) (
                      rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", namespace=~"${namespace}", node!="", container!=""}[$__rate_interval])
                      OR
                      label_replace(
                        rate(container_cpu_usage_seconds_total{cluster=~"${cluster}", namespace=~"${namespace}", node="", container!=""}[$__rate_interval])
                        , "node", "$1", "instance", "([^:]+).*"
                      )
                    )
                )
                * on (cluster, node) group_left
                max by (cluster, node) (node_cpu_hourly_cost{cluster=~"${cluster}"})
            )[$__range:1h])
        )
      )
    `;
    },
    CpuIdleCostProjected: (cluster: string, namespace: string) => `
      (${ResourceQueries.WorkloadCost.CpuIdleCostCurrent(cluster, namespace)}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string, namespace: string) => {
      cluster = cluster || '.+';
      namespace = namespace || '.+';
      return `
      sum by (cluster, namespace, workload, workload_type) (
        group by (cluster, namespace, workload, pod, workload_type) (${snippets.pods.withWorkloads(cluster, namespace)})
        * on (pod) group_left
        sum by (pod) (
            sum_over_time(sum by (pod) (
                (
                    # requests
                    sum by (cluster, node, pod) (
                        kube_pod_container_resource_requests{cluster=~"${cluster}", namespace=~"${namespace}", container!="", resource="memory"}
                    )
                    # usage
                    - on (cluster, node, pod) group_left
                    sum by (cluster, node, pod) (
                      container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", node!="", container!=""}
                      OR
                      label_replace(
                        container_memory_rss{cluster=~"${cluster}", namespace=~"${namespace}", node="", container!=""}
                        , "node", "$1", "instance", "([^:]+).*"
                      )
                    )
                )
                # in GiB
                /1024/1024/1024
                * on (cluster, node) group_left
                max by (cluster, node) (node_ram_hourly_cost{cluster=~"${cluster}"})
            )[$__range:1h])
        )
      )`;
    },
    MemoryIdleCostProjected: (cluster: string, namespace: string) => `
      (${ResourceQueries.WorkloadCost.MemoryIdleCostCurrent(cluster, namespace)}) * ((60*60*24*30)/$__range_s)
    `,
  },

  NodePodUsage: {
    CpuAvg: (cluster: string, node: string) => `
      avg_over_time(sum by (pod) (
        rate(container_cpu_usage_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}[$__rate_interval])
        OR
        rate(container_cpu_usage_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}[$__rate_interval])
      )[$__range:$__rate_interval])
    `,
    CpuAvgPercent: (cluster: string, node: string) => `
      ${ResourceQueries.NodePodUsage.CpuAvg(
        cluster,
        node
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="cpu"})
    `,
    CpuMax: (cluster: string, node: string) => `
      max_over_time(sum by (pod) (
        rate(container_cpu_usage_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}[$__rate_interval])
        OR
        rate(container_cpu_usage_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}[$__rate_interval])
      )[$__range:$__rate_interval])
    `,
    CpuMaxPercent: (cluster: string, node: string) => `
      ${ResourceQueries.NodePodUsage.CpuMax(
        cluster,
        node
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="cpu"})
    `,

    MemoryAvg: (cluster: string, node: string) => `
      avg_over_time(sum by (pod) (
        container_memory_rss{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}
        OR
        container_memory_rss{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}
      )[$__range:$__interval])
    `,
    MemoryAvgPercent: (cluster: string, node: string) => `
      ${ResourceQueries.NodePodUsage.MemoryAvg(
        cluster,
        node
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="memory"})
    `,
    MemoryMax: (cluster: string, node: string) => `
      max_over_time(sum by (pod) (
        container_memory_rss{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}
        OR
        container_memory_rss{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}
      )[$__range:$__interval])
    `,
    MemoryMaxPercent: (cluster: string, node: string) => `
      ${ResourceQueries.NodePodUsage.MemoryMax(
        cluster,
        node
      )} / sum by (pod) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="memory"})
    `,
  },

  NodePodCost: {
    CostCurrent: (cluster: string, node: string) => `
      sum_over_time(sum by (pod) (
        # actual cost of cpu allocation and usage is the max of:
        max by (cluster, node, pod) (
            # cpu requests (in cores)
            sum by (cluster, node, pod, resource) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="cpu"})
            OR
            # cpu usage (in cores)
            sum by (cluster, node, pod) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
      +
      sum_over_time(sum by (pod) (
        # actual cost of memory allocation and usage is the max of:
        max by (cluster, node, pod) (
          # memory requests (in bytes)
          sum by (cluster, node, pod, resource) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="memory"})
          OR
          # memory usage (in bytes)
          sum by (cluster, node, pod) (
            container_memory_rss{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}
            OR
            label_replace(
              container_memory_rss{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}
              , "node", "$1", "instance", "([^:]+).*"
            )
          )
        )
        # in GiB
        /1024/1024/1024

        # multiplied by the hourly cost per GB per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostProjected: (cluster: string, node: string) => `
      (${ResourceQueries.NodePodCost.CostCurrent(cluster, node)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string, node: string) => `
      sum_over_time(sum by (pod) (
        (
          # requests
          sum by (cluster, node, pod) (
            kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="cpu"}
          )
          # usage
          - on (cluster, node, pod) group_left
          sum by (cluster, node, pod) (
            rate(container_cpu_usage_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}[$__rate_interval])
            OR
            label_replace(
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}[$__rate_interval])
              , "node", "$1", "instance", "([^:]+).*"
            )
          )
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CpuIdleCostProjected: (cluster: string, node: string) => `
      (${ResourceQueries.NodePodCost.CpuIdleCostCurrent(cluster, node)}) * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string, node: string) => `
      sum_over_time(
        sum by (pod) (
          (
            # requests
            sum by (cluster, pod) (
              kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="memory"}
            )
            # usage
            - on (cluster, pod) group_left (node)
            sum by (cluster, node, pod) (
              container_memory_rss{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", node="", container!=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
          )
          # in GiB
          /1024/1024/1024
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
    `,
    MemoryIdleCostProjected: (cluster: string, node: string) => `
      (${ResourceQueries.NodePodCost.MemoryIdleCostCurrent(cluster, node)}) * ((60*60*24*30)/$__range_s)
    `,
  },

  ContainerUsage: {
    CpuAvg: (cluster: string, namespace: string, pod: string) => `
      avg_over_time(max by (container) (
        node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!=""}
      )[$__range:$__interval])
    `,
    CpuAvgPercent: (cluster: string, namespace: string, pod: string) =>
      `${ResourceQueries.ContainerUsage.CpuAvg(
        cluster,
        namespace,
        pod
      )} / sum by (container) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="cpu"})`,
    CpuMax: (cluster: string, namespace: string, pod: string) => `
      max_over_time(max by (container) (
        node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!=""}
      )[$__range:$__interval])
    `,
    CpuMaxPercent: (cluster: string, namespace: string, pod: string) => `
      ${ResourceQueries.ContainerUsage.CpuMax(
        cluster,
        namespace,
        pod
      )} / sum by (container) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="cpu"})`,
    MemoryAvg: (cluster: string, namespace: string, pod: string) => `
      avg_over_time(avg by (container) (
        node_namespace_pod_container:container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!=""}
      )[$__range:$__interval])
    `,
    MemoryAvgPercent: (cluster: string, namespace: string, pod: string) => `
      ${ResourceQueries.ContainerUsage.MemoryAvg(
        cluster,
        namespace,
        pod
      )} / sum by (container) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="memory"})
    `,
    MemoryMax: (cluster: string, namespace: string, pod: string) => `
      max_over_time(max by (container) (
        node_namespace_pod_container:container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!=""}
      )[$__range:$__interval])
    `,
    MemoryMaxPercent: (cluster: string, namespace: string, pod: string) => `
      ${ResourceQueries.ContainerUsage.MemoryMax(
        cluster,
        namespace,
        pod
      )} / sum by (container) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="memory"})
    `,
  },

  ContainerCost: {
    CostCurrent: (cluster: string, namespace: string, pod: string) => `
      # Total compute cost by container over the selected time range
      sum_over_time(sum by (container) (
          # actual cost of cpu allocation and usage is the max of:
          max by (cluster, node, container) (
              # cpu requests (in cores)
              sum by (cluster, node, container, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="cpu"})
              OR
              # cpu usage (in cores)
              sum by (cluster, node, container) (
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node!="", container!=""}[$__rate_interval])
                OR
                label_replace(
                  rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node="", container!=""}[$__rate_interval])
                  , "node", "$1", "instance", "([^:]+).*"
                )
              )
          )
          # multiplied by the hourly cost per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
      +
      sum_over_time(sum by (container) (
          # actual cost of memory allocation and usage is the max of:
          max by (cluster, node, container) (
              # memory requests (in bytes)
              sum by (cluster, node, container, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="memory"})
              OR
              # memory usage (in bytes)
              sum by (cluster, node, container) (
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node!="", container!=""}
                OR
                label_replace(
                  container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node="", container!=""}
                  , "node", "$1", "instance", "([^:]+).*"
                )
              )
          )
          # in GiB
          /1024/1024/1024

          # multiplied by the hourly cost per GB per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostProjected: (cluster: string, namespace: string, pod: string) => `
      (${ResourceQueries.ContainerCost.CostCurrent(cluster, namespace, pod)}) * ((60*60*24*30)/$__range_s)
    `,
    CpuIdleCostCurrent: (cluster: string, namespace: string, pod: string) => `
      sum_over_time(sum by (container) (
        (
            # requests
            sum by (cluster, node, container) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="cpu"}
            )
            # usage
            - on (cluster, node, container) group_left
            sum by (cluster, node, container) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            )
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CpuIdleCostProjected: (cluster: string, namespace: string, pod: string) => `
      (${ResourceQueries.ContainerCost.CpuIdleCostCurrent(cluster, namespace, pod)})  * ((60*60*24*30)/$__range_s)
    `,
    MemoryIdleCostCurrent: (cluster: string, namespace: string, pod: string) => `
      sum_over_time(
        sum by (container) (
          (
              # requests
              sum by (cluster, container) (
                  kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!="", resource="memory"}
              )
              # usage
              - on (cluster, container) group_left (node)
              sum by (cluster, node, container) (
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node!="", container!=""}
                OR
                label_replace(
                  container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", node="", container!=""}
                  , "node", "$1", "instance", "([^:]+).*"
                )
              )
          )
          # in GiB
          /1024/1024/1024
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    MemoryIdleCostProjected: (cluster: string, namespace: string, pod: string) => `
      (${ResourceQueries.ContainerCost.MemoryIdleCostCurrent(cluster, namespace, pod)})  * ((60*60*24*30)/$__range_s)
    `,
  },
};

export const NodeQueries = {
  NodesCondition: (cluster?: string) => {
    cluster = cluster || '.+';
    return `
    group by (cluster, node, condition) (kube_node_status_condition{cluster=~"${cluster}", status="true"} == 1)`;
  },
  NodeInfo: (cluster?: string, node?: string, extraLabels?: string) => {
    cluster = cluster || '.+';
    node = node || '.+';

    const defaultLabels = ['asserts_env', 'asserts_site', 'cluster', 'node', 'provider_id'];
    const labels = [...defaultLabels, extraLabels].join(',');

    return `
      last_over_time(
        max by (${labels}) (
          kube_node_info{cluster=~"${cluster}", node=~"${node}(:[0-9]{2,5})?"}
        )
      [$__range:])
    `;
  },
  NodeLabels: (cluster: string, node: string) =>
    `kube_node_labels{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"}`,
  NodeStatusCapacity: (cluster: string, node: string) =>
    `max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})`,
  NodeCreated: (cluster: string, node: string) =>
    `kube_node_created{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"}`,
  NodePods: (cluster: string, node: string) => `
    max by (cluster, namespace, pod, phase) (
      kube_pod_status_phase{cluster="${cluster}"} > 0
    )
    * on (cluster, namespace, pod) group_left(created_by_kind, created_by_name, host_ip, pod_ip, node, asserts_env, asserts_site)
    max by (cluster, node, namespace, pod, created_by_kind, created_by_name, host_ip, pod_ip, asserts_env, asserts_site) (
      kube_pod_info{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"}
    )`,
  NodeAlertsCount: (cluster?: string) => {
    cluster = cluster || '.+';
    return `
    count by (cluster, node) (
      # node alerts
      ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", node!=""}

      OR

      # pod alerts by node
      max by (cluster, namespace, pod) (ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", node="", pod!=""})
      * on (cluster, namespace, pod) group_left (node)
      max by (cluster, namespace, pod, node) (kube_pod_info{cluster=~"${cluster}", node!=""})
    )
    `;
  },
  NodePodsAlertsCount: (cluster: string, node: string) => `
    count by (cluster, namespace, pod) (
      max by (cluster, namespace, pod) (ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster="${cluster}", pod!=""})
      * on (cluster, namespace, pod) group_left (node)
      max by (cluster, namespace, pod, node) (kube_pod_info{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})
    )
  `,
  NodesReplicaSetOwner: (cluster: string) => `kube_replicaset_owner{cluster="${cluster}"}`,

  // PodsPerNode shows number of running pods per node (or just one node).
  // We use the instance label as the node label - only if the node label is
  // missing (otherwise we would overwrite with an undesired instance label).
  PodsPerNode: (cluster?: string, node?: string) => {
    cluster = cluster || '.+';
    return node
      ? `sum by (cluster, node) (label_replace(kubelet_running_pods{cluster=~"${cluster}", node="", instance=~"${node}(:[0-9]{2,5})?"}, "node", "$1", "instance", "([^:]+).*") OR kubelet_running_pods{cluster=~"${cluster}", node=~"${node}(:[0-9]{2,5})?"})`
      : `sum by (cluster, node) (label_replace(kubelet_running_pods{cluster=~"${cluster}", node=""}, "node", "$1", "instance", "([^:]+).*") OR kubelet_running_pods{cluster=~"${cluster}", node!=""})`;
  },

  CpuUsage: (node: string) =>
    `1 - avg by (cluster, instance) (max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{mode="idle", instance=~"${node}(:[0-9]{2,5})?"}[$__range]))) >= 0`,
  DiskSpaceSize: (node: string) =>
    `node_filesystem_size_bytes{mountpoint="/", instance=~"${node}(:[0-9]{2,5})?"} OR
    node_filesystem_size_bytes{volume="C:", node=~"${node}(:[0-9]{2,5})?"}`,
  DiskSpaceUsage: (node: string) =>
    `(100 - (node_filesystem_avail_bytes{mountpoint="/", instance=~"${node}(:[0-9]{2,5})?"} * 100) / node_filesystem_size_bytes{mountpoint="/", instance=~"${node}(:[0-9]{2,5})?"}) OR
    (100 - (node_filesystem_avail_bytes{volume="C:", instance=~"${node}(:[0-9]{2,5})?"} * 100) / node_filesystem_size_bytes{volume="C:", instance=~"${node}(:[0-9]{2,5})?"})`,
  NodeMemory: (node: string) => `
    max by (cluster, node) (kube_node_status_capacity{resource="memory", node=~"${node}(:[0-9]{2,5})?"})
    - on (cluster, node) group_left
    max by (cluster, node) (
      label_replace(
        windows_memory_available_bytes{instance=~"${node}(:[0-9]{2,5})?"}
        OR
        node_memory_MemAvailable_bytes{instance=~"${node}(:[0-9]{2,5})?"}
        , "node", "$1", "instance", "([^:]*).*"
      )
    )`,
};

export const PredictionQueries = {
  WorkloadPodCpuUsageVsRequested: (namespace: string, cluster: string, workload: string) =>
    `avg(node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{cluster="${cluster}", namespace="${namespace}", pod=~"${workload}.*"}) / avg(cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}",  pod=~"${workload}.*"})`,
  WorkloadMemUsage: (cluster: string, workload: string) => `
    avg(
      container_memory_working_set_bytes{container!="", image!="", cluster="${cluster}", pod=~"${workload}.*"}
      /
      on (cluster, instance) group_left
      max by (cluster, instance) (machine_memory_bytes{cluster="${cluster}"})
    )`,
};

export const ClusterQueries = (cluster: string) => ({
  DiskSpaceSize: `sum by(cluster)(node_filesystem_size_bytes{mountpoint="/",cluster="${cluster}"})`,
  ClusterMemory: `sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="memory"}))`,
});

export const SceneQueries = {
  Home: {
    NodeCount:
      'count(group by (cluster, node) (kube_pod_info{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    PodCount:
      'count(group by (cluster, namespace, pod) (kube_pod_info{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    ContainerCount:
      'count(group by (cluster, namespace, pod, container) (kube_pod_container_info{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    ClusterCount:
      'count(group by (cluster) (kube_pod_info{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    NamespaceCount:
      'count(group by (cluster, namespace) (kube_namespace_status_phase{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    WorkloadCount:
      'count(group by (cluster, namespace, workload, workload_type) (namespace_workload_pod:kube_pod_owner:relabel{cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"}))',
    FiringAlerts:
      'count(ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${filteredClusters:pipe}", namespace=~"${namespace:pipe}"})',
    ContainerAlertsPodNames: `
      group by (pod) (
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container!=""}
      )
    `,
    PodAlertsPodNames: `
      group by (pod) (
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container=""}
      )
    `,
    ContainerAlerts: `
      1000 * max by (alertname, cluster, namespace, pod, container) (
        group by (alertname, cluster, namespace, pod, container) (
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container!=""}
        )
        * on (alertname, cluster, namespace, pod, container) group_left
        max by (alertname, cluster, namespace, pod, container) (
          ALERTS_FOR_STATE{alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container!=""}
        )
      )
      * on (cluster, namespace, pod) group_left (workload, workload_type)
      group by (cluster, namespace, pod, workload, workload_type) (
        ${snippets.pods.withWorkloads('', '', '', '', '(${containerAlertsPodNames:pipe})')}
      )
    `,
    PodAlerts: `
      1000 * max by (alertname, cluster, namespace, pod) (
        group by (alertname, cluster, namespace, pod, container) (
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container=""}
        )
        * on (alertname, cluster, namespace, pod) group_left
        max by (alertname, cluster, namespace, pod, container) (
          ALERTS_FOR_STATE{alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}", pod!="", container=""}
        )
      )
      * on (cluster, namespace, pod) group_left (workload, workload_type)
      group by (cluster, namespace, pod, workload, workload_type) (
        ${snippets.pods.withWorkloads('', '', '', '', '(${podAlertsPodNames:pipe})')}
      )
    `,
    DeployedContainerImages: `
      sum by (image_spec) (
        kube_pod_container_info{cluster=~"\${filteredClusters:pipe}", namespace=~"\${namespace:pipe}"}
      )
    `,
    CPUUsageByCluster: `
      sum by (cluster) (max by (cluster, instance, cpu, core) (1 - rate(node_cpu_seconds_total{cluster=~"\${filteredClusters:pipe}", mode="idle"}[$__rate_interval])))
      / on (cluster)
      sum by (cluster) (max by (cluster, node) (kube_node_status_capacity{cluster=~"\${filteredClusters:pipe}", resource="cpu"}))
    `,
    MemoryUsageByCluster: `
      1 - (
        sum by (cluster) (
            max by (cluster, node) (
            label_replace(
                windows_memory_available_bytes{cluster=~"\${filteredClusters:pipe}"}
                OR
                node_memory_MemAvailable_bytes{cluster=~"\${filteredClusters:pipe}"}
            , "node", "$1", "instance", "(.+)")
            )
        )
        / on (cluster)
        sum by (cluster) (
            max by (cluster, node) (
            kube_node_status_capacity{cluster=~"\${filteredClusters:pipe}", resource="memory"}
            )
        )
      )
    `,
  },
  Config: {
    K8sMonitoringHelm: (cluster: string) => `
      topk(1,
        group by (version) (
          grafana_kubernetes_monitoring_build_info{cluster="${cluster}"}
        ) OR vector(0)
      )`,
    GrafanaAlloy: (cluster: string) => `
      topk(1,
        group by (version) (
          agent_build_info{cluster="${cluster}", version=~"v.+"}
          OR
          alloy_build_info{cluster="${cluster}", version=~"v.+"}
        ) OR vector(0)
      )`,
    NodeExporter: (cluster: string) => `
    topk(1,
      count by (cluster, instance, version) (node_exporter_build_info{cluster="${cluster}"})
      OR vector(0)
    )`,
    WindowsExporter: (cluster: string) => `
    topk(1,
      count by (cluster, instance, version) (windows_exporter_build_info{cluster="${cluster}"})
      OR vector(0)
    )`,
    Kepler: (cluster: string) => `
    topk(1,
      count by (cluster, instance) (kepler_exporter_build_info{cluster="${cluster}"})
      OR vector(0)
    )`,
    OpenCost: (cluster: string) => `
    topk(1,
      count by (cluster, version) (opencost_build_info{cluster="${cluster}"})
      OR vector(0)
    )`,
    OpenCostWithoutCluster: `
    topk(1,
      group(opencost_build_info)
      OR vector(0)
    )`,
    KubeStateMetrics: (cluster: string) => `
    topk(1,
      count by (cluster, node) (kube_node_info{cluster="${cluster}"})
      OR vector(0)
    )`,
    PodOwnerJobLabel: (cluster: string) => `
    bottomk(1,
      1 - absent(kube_pod_owner{cluster="${cluster}", job!=""})
      OR vector(1)
    )`,
    WorkloadRecordingRule: (cluster: string) => `
    bottomk(1,
      1 - absent(namespace_workload_pod:kube_pod_owner:relabel{cluster="${cluster}"})
      OR vector(1)
    )`,
    cAdvisor: (cluster: string) => `
    topk(1,
      count by (cluster, instance) (machine_memory_bytes{cluster="${cluster}"})
      OR vector(0)
    )`,
    Kubelet: (cluster: string) => `
    topk(1,
      count by (cluster, instance) (kubernetes_build_info{cluster="${cluster}"})
      OR vector(0)
    )`,
  },
  ClusterDetail: {
    CpuCapacity: (cluster?: string) =>
      `sum(max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="cpu"}))`,
    CpuLimits: (cluster?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
        }),
      }),
    CpuRequests: (cluster?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
        }),
      }),
    CpuUsage: (cluster?: string) =>
      `sum(1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", mode="idle"}[$__rate_interval]))) >= 0`,
    CpuAllocation: (cluster?: string) => `
      sum(
        max by (cluster, node) (
          # cpu requests (in cores)
          sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", container!="", resource="cpu"})
          OR
          # cpu usage (in cores)
          label_replace(
            sum by (cluster, instance) (1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", mode="idle"}[$__rate_interval]))) >= 0
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )

    `,
    MemoryCapacity: (cluster?: string) =>
      `sum(max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="memory"}))`,
    MemoryRequests: (cluster?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
        }),
      }),
    MemoryLimits: (cluster?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
        }),
      }),
    MemoryUsage: (cluster?: string) => `
      sum(
        max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="memory"})
        - on (cluster, node) group_left
        max by (cluster, node) (
          label_replace(
            windows_memory_available_bytes{cluster="${cluster}"}
            OR
            node_memory_MemAvailable_bytes{cluster="${cluster}"}
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )
    `,
    MemoryAllocation: (cluster?: string) => `
      sum(
        max by (cluster, node) (
          # memory requests (in bytes)
          sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", container!="", resource="memory"})
          OR
          # memory usage (in bytes)
          max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="memory"})
          - on (cluster, node) group_left
          max by (cluster, node) (
            label_replace(
              windows_memory_available_bytes{cluster="${cluster}"}
              OR
              node_memory_MemAvailable_bytes{cluster="${cluster}"}
              , "node", "$1", "instance", "([^:]+).*"
            )
          )
        )
      )
    `,
    NumContainers: (cluster?: string) =>
      `count(kube_pod_container_info{cluster="${cluster}", container!=""}) OR clamp_max(absent(fake_metric{}),0)`,
    CpuRequestsText: (cluster?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    CpuLimitsText: (cluster?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryRequestsText: (cluster?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryLimitsText: (cluster?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    CostCpuAllocation: (cluster?: string) => `
      sum_over_time(sum(
        # cpu capacity (in cores)
        max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="cpu"})
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostMemoryAllocation: (cluster?: string) => `
      sum_over_time(sum(
        # memory capacity (in GiB)
        max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", resource="memory"})/1024/1024/1024
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostCpuIdle: (cluster?: string) => `
    sum_over_time(sum(
      label_replace(
          sum by (cluster, instance) (max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", mode="idle"}[$__rate_interval])))
          , "node", "$1", "instance", "([^:]+).*"
      )
      * on (cluster, node) group_left
      max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
  )[$__range:1h])
    `,
    CostMemoryIdle: (cluster?: string) => `
      sum_over_time(sum(
        max by (cluster, node) (
          label_replace(
            windows_memory_available_bytes{cluster="${cluster}"}
            OR
            node_memory_MemAvailable_bytes{cluster="${cluster}"}
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,

    Network: {
      Usage: {
        Rx: (cluster?: string, labels?: string) => {
          return `
          sum ${promql.by(labels?.split(','))} (
            max by (cluster, node, device) (
              label_replace(
                rate(node_network_receive_bytes_total{cluster="${cluster}"}[$__rate_interval])
              , "node", "$1", "instance", "([^:]+).*")
            )
          )`;
        },
        Tx: (cluster?: string, labels?: string) => {
          return `
          - sum ${promql.by(labels?.split(','))} (
            max by (cluster, node, device) (
              label_replace(
                rate(node_network_transmit_bytes_total{cluster="${cluster}"}[$__rate_interval])
              , "node", "$1", "instance", "([^:]+).*")
            )
          )`;
        },
      },

      Dropped: {
        Rx: (cluster?: string, labels?: string) => {
          return `
          sum ${promql.by(labels?.split(','))} (
            max by (cluster, node, device) (
              label_replace(
                rate(node_network_receive_drop_total{cluster="${cluster}"}[$__rate_interval])
              , "node", "$1", "instance", "([^:]+).*")
            )
          )`;
        },
        Tx: (cluster?: string, labels?: string) => {
          return `
          - sum ${promql.by(labels?.split(','))} (
            max by (cluster, node, device) (
              label_replace(
                rate(node_network_transmit_drop_total{cluster="${cluster}"}[$__rate_interval])
              , "node", "$1", "instance", "([^:]+).*")
            )
          )`;
        },
      },
    },

    Energy: {
      Total: (cluster: string) => energyQueries.ByCluster('kepler_container_joules_total')(cluster),
      Package: (cluster: string) => energyQueries.ByCluster('kepler_container_package_joules_total')(cluster),
      DRAM: (cluster: string) => energyQueries.ByCluster('kepler_container_dram_joules_total')(cluster),
      GPU: (cluster: string) => energyQueries.ByCluster('kepler_container_gpu_joules_total')(cluster),
      Other: (cluster: string) => energyQueries.ByCluster('kepler_container_other_joules_total')(cluster),
      ByNode: (cluster: string) => energyQueries.ByNode('kepler_container_joules_total')(cluster),
      ByNamespace: (cluster: string) => energyQueries.ByNamespace('kepler_container_joules_total')(cluster),
    },
  },
  NodeDetail: {
    CpuCapacity: (cluster?: string, node?: string) =>
      `max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="cpu"})`,
    CpuLimits: (cluster?: string, node?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }),
    CpuRequests: (cluster?: string, node?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }),
    CpuUsage: (cluster?: string, node?: string) =>
      `sum(
        1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
        OR
        1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
      ) >= 0`,
    CpuAllocation: (cluster?: string, node?: string) => `
      sum(
        max by (cluster, node) (
          # cpu requests (in cores)
          sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="cpu"})
          OR
          # cpu usage (in cores)
          label_replace(
            sum by (cluster, node, instance) (
              1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
              OR
              1-max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
            ) >= 0
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
      )

    `,
    MemoryCapacity: (cluster?: string, node?: string) =>
      `max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="memory"})`,
    MemoryRequests: (cluster?: string, node?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }),
    MemoryLimits: (cluster?: string, node?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }),
    MemoryUsage: (cluster?: string, node?: string) => `
      max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="memory"})
      - on (cluster, node) group_left
      max by (cluster, node) (
        label_replace(
          windows_memory_available_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
          OR
          node_memory_MemAvailable_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
          , "node", "$1", "instance", "([^:]+).*"
        )
      )
    `,
    MemoryAllocation: (cluster?: string, node?: string) => `
      sum(
        max by (cluster, node) (
          # memory requests (in bytes)
          sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", container!="", resource="memory"})
          OR
          # memory usage (in bytes)
          max by (cluster, node) (kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="memory"})
          - on (cluster, node) group_left
          max by (cluster, node) (
            label_replace(
              windows_memory_available_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
              OR
              node_memory_MemAvailable_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
              , "node", "$1", "instance", "([^:]+).*"
            )
          )
        )
      )
    `,
    PodNames: (cluster?: string, node?: string) =>
      `group by (pod) (kube_pod_info{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})`,
    NumContainers: (cluster?: string) =>
      `count(kube_pod_container_info{cluster="${cluster}", pod=~"(\${podNames:pipe})", container!=""}) OR clamp_max(absent(fake_metric{}),0)`,
    CpuRequestsText: (cluster?: string, node?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    CpuLimitsText: (cluster?: string, node?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryRequestsText: (cluster?: string, node?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryLimitsText: (cluster?: string, node?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          node: node && `${node}(:[0-9]{2,5})?`,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    CostCpuAllocation: (cluster?: string, node?: string) => `
      sum_over_time((
        # cpu usage (in cores)
        max(kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="cpu"})
        # multiplied by the hourly cost per node where this pod is running
        *
        sum(max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"}))
      )[$__range:1h])
    `,
    CostMemoryAllocation: (cluster?: string, node?: string) => `
      sum_over_time((
        # cpu usage (in cores)
        max(kube_node_status_capacity{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", resource="memory"})/1024/1024/1024
        # multiplied by the hourly cost per node where this pod is running
        *
        sum(node_ram_hourly_cost{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})
      )[$__range:1h])
    `,
    CostCpuIdle: (cluster?: string, node?: string) => `
      sum_over_time(sum(
        label_replace(
          sum by (cluster, node, instance) (
            max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
            OR
            max by (cluster, instance, cpu, core) (rate(node_cpu_seconds_total{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?", mode="idle"}[$__rate_interval]))
          )
          , "node", "$1", "instance", "([^:]+).*"
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})
      )[$__range:1h])
    `,
    CostMemoryIdle: (cluster?: string, node?: string) => `
      sum_over_time(sum(
        max by (cluster, node) (
          label_replace(
            windows_memory_available_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
            OR
            node_memory_MemAvailable_bytes{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}
            , "node", "$1", "instance", "([^:]+).*"
          )
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}", node=~"${node}(:[0-9]{2,5})?"})
      )[$__range:1h])
    `,

    Network: {
      Usage: {
        Rx: (cluster?: string, node?: string) => {
          return `
            sum(
              max by (cluster, instance, device) (
                rate(node_network_receive_bytes_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, node?: string) => {
          return `
            - sum(
              max by (cluster, instance, device) (
                rate(node_network_transmit_bytes_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}[$__rate_interval])
              )
            )
          `;
        },
        RxBy(cluster?: string, node?: string) {
          return `
            sum by (cluster, namespace, pod, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, '', '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
        TxBy(cluster?: string, node?: string) {
          return `
            - sum by (cluster, namespace, pod, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, '', '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
      },

      Dropped: {
        Rx: (cluster?: string, node?: string) => {
          return `
            sum(
              max by (cluster, instance, device) (
                rate(node_network_receive_drop_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, node?: string) => {
          return `
            - sum(
              max by (cluster, instance, device) (
                rate(node_network_transmit_drop_total{cluster="${cluster}", instance=~"${node}(:[0-9]{2,5})?"}[$__rate_interval])
              )
            )
          `;
        },
        RxBy(cluster?: string, node?: string) {
          return `
            sum by (cluster, namespace, pod, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, '', '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
        TxBy(cluster?: string, node?: string) {
          return `
            - sum by (cluster, namespace, pod, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, '', '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
      },
    },

    Energy: {
      Total: (cluster: string, node?: string) => energyQueries.ByNode('kepler_container_joules_total')(cluster, node),
      Package: (cluster: string, node?: string) =>
        energyQueries.ByNode('kepler_container_package_joules_total')(cluster, node),
      DRAM: (cluster: string, node?: string) =>
        energyQueries.ByNode('kepler_container_dram_joules_total')(cluster, node),
      GPU: (cluster: string, node?: string) => energyQueries.ByNode('kepler_container_gpu_joules_total')(cluster, node),
      Other: (cluster: string, node?: string) =>
        energyQueries.ByNode('kepler_container_other_joules_total')(cluster, node),
      ByPod: (cluster: string) =>
        energyQueries.ByPod('kepler_container_joules_total')(cluster, '', '(${podNames:pipe})'),
    },
  },
  NamespaceDetail: {
    CpuLimits: (cluster?: string, namespace?: string) =>
      ksm.rules['namespace_cpu:kube_pod_container_resource_limits:sum']({
        cluster,
        namespace,
      }),
    CpuRequests: (cluster?: string, namespace?: string) =>
      ksm.rules['namespace_cpu:kube_pod_container_resource_requests:sum']({
        cluster,
        namespace,
      }),
    CpuUsage: (cluster?: string, namespace?: string) =>
      `sum(max by (cluster, namespace, pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", container!=""}[$__rate_interval])))`,
    CpuAllocation: (cluster?: string, namespace?: string) => `
      max by (namespace) (
        # cpu requests (in cores)
        namespace_cpu:kube_pod_container_resource_requests:sum{cluster="${cluster}", namespace="${namespace}"}
        OR
        # cpu usage (in cores)
        sum by (namespace) (max by (namespace, pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", container!=""}[$__rate_interval])))
      )
    `,
    MemoryRequests: (cluster?: string, namespace?: string) =>
      ksm.rules['namespace_memory:kube_pod_container_resource_requests:sum']({
        cluster,
        namespace,
      }),
    MemoryLimits: (cluster?: string, namespace?: string) =>
      ksm.rules['namespace_memory:kube_pod_container_resource_limits:sum']({
        cluster,
        namespace,
      }),
    MemoryUsage: (cluster?: string, namespace?: string) =>
      `sum(max by (cluster, namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", container!=""}))`,
    MemoryAllocation: (cluster?: string, namespace?: string) => `
      max by (namespace) (
        # memory requests (in bytes)
        namespace_memory:kube_pod_container_resource_requests:sum{cluster="${cluster}", namespace="${namespace}"}
        OR
        # memory usage (in bytes)
        sum by (namespace) (max by (namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", container!=""}))
      )
    `,
    NumContainers: (cluster?: string, namespace?: string) =>
      `count(kube_pod_container_info{cluster="${cluster}", namespace="${namespace}", container!=""}) OR clamp_max(absent(fake_metric{}),0)`,
    CpuRequestsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          namespace,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    CpuLimitsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          namespace,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryRequestsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          namespace,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',
    MemoryLimitsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          namespace,
        }),
      }) + ' OR clamp_max(absent(fake_metric{}),0)',

    Network: {
      Usage: {
        Rx: (cluster?: string, namespace?: string) => {
          return `
            sum(
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string) => {
          return `
            - sum(
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_bytes_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
            )
          `;
        },

        RxBy(cluster?: string, namespace?: string) {
          return `
            sum by (cluster, namespace, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace)}
              )
            )
          `;
        },
        TxBy(cluster?: string, namespace?: string) {
          return `
            - sum by (cluster, namespace, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_bytes_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace)}
              )
            )
          `;
        },
      },

      Dropped: {
        Rx: (cluster?: string, namespace?: string) => {
          return `
            sum(
              max by (namespace, pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod!=""}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string) => {
          return `
            - sum(
              max by (namespace, pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod!=""}[$__rate_interval])
              )
            )
          `;
        },

        RxBy(cluster?: string, namespace?: string) {
          return `
            sum by (cluster, namespace, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace)}
              )
            )
          `;
        },
        TxBy(cluster?: string, namespace?: string) {
          return `
            - sum by (cluster, namespace, workload, workload_type) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", namespace="${namespace}"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace)}
              )
            )
          `;
        },
      },
    },
    Energy: {
      Total: (cluster: string, namespace?: string) =>
        energyQueries.ByNamespace('kepler_container_joules_total')(cluster, namespace),
      Package: (cluster: string, namespace?: string) =>
        energyQueries.ByNamespace('kepler_container_package_joules_total')(cluster, namespace),
      DRAM: (cluster: string, namespace?: string) =>
        energyQueries.ByNamespace('kepler_container_dram_joules_total')(cluster, namespace),
      GPU: (cluster: string, namespace?: string) =>
        energyQueries.ByNamespace('kepler_container_gpu_joules_total')(cluster, namespace),
      Other: (cluster: string, namespace?: string) =>
        energyQueries.ByNamespace('kepler_container_other_joules_total')(cluster, namespace),
      ByWorkload: (cluster: string, namespace?: string) =>
        energyQueries.ByWorkload('kepler_container_joules_total')(cluster, namespace),
    },

    CostCpuAllocation: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        # actual cost of cpu allocation and usage is the max of:
        max by (cluster, namespace, node) (
            # cpu requests (in cores)
            sum by (cluster, namespace, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", container!="", resource="cpu"})
            OR
            # cpu usage (in cores)
            sum by (cluster, namespace, node) (max by (cluster, namespace, node, pod, container) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostMemoryAllocation: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        # actual cost of memory allocation and usage is the max of:
        max by (cluster, node) (
            # memory requests (in bytes)
            sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", container!="", resource="memory"})
            OR
            # memory usage (in bytes)
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              container_memory_rss{cluster="${cluster}", namespace="${namespace}", node!="", container!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", node="", container!=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # in GiB
        /1024/1024/1024

        # multiplied by the hourly cost per GB per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostCpuIdle: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        (
            # requests
            sum by (cluster, node, resource) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", container!="", resource="cpu"}
            )
            # usage
            - on (cluster, node) group_left
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostMemoryIdle: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        (
            # requests
            sum by (cluster, node) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", container!="", resource="memory"}
            )
            # usage
            - on (cluster, node) group_left
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              container_memory_rss{cluster="${cluster}", namespace="${namespace}", node!="", container!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", node="", container!=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
  },
  WorkloadDetail: {
    PodNames: (cluster?: string, namespace?: string, workload?: string, workloadType?: string) =>
      `group by (pod) (${snippets.pods.withWorkloads(cluster, namespace, workload, workloadType, '', true)})`,
    CpuLimits: (cluster?: string, namespace?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }),
    CpuRequests: (cluster?: string, namespace?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }),
    CpuUsage: (cluster?: string, namespace?: string) =>
      `sum(max by (pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!=""}[$__rate_interval])))`,
    CpuAllocation: (cluster?: string, namespace?: string) => `
      max by (namespace) (
        # cpu requests (in cores)
        sum by (namespace, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="cpu"})
        OR
        # cpu usage (in cores)
        sum by (namespace) (max by (namespace, pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!=""}[$__rate_interval])))
      )
    `,
    MemoryRequests: (cluster?: string, namespace?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }),
    MemoryLimits: (cluster?: string, namespace?: string) =>
      promql.sum({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }),
    MemoryUsage: (cluster?: string, namespace?: string) =>
      `sum(max by (pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!=""}))`,
    MemoryAllocation: (cluster?: string, namespace?: string) => `
      max by (namespace) (
        # memory requests (in bytes)
        sum by (namespace, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="memory"})
        OR
        # memory usage (in bytes)
        sum by (namespace) (max by (namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!=""}))
      )
    `,
    NumContainers: (cluster?: string, namespace?: string) =>
      `count(kube_pod_container_info{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!=""}) OR clamp_max(absent(fake_metric{pod=~"(\${podNames:pipe})"}),0)`,
    CpuRequestsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${podNames:pipe})"}),0)',
    CpuLimitsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${podNames:pipe})"}),0)',
    MemoryRequestsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${podNames:pipe})"}),0)',
    MemoryLimitsText: (cluster?: string, namespace?: string) =>
      promql.count({
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          namespace,
          pod: '(${podNames:pipe})',
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${podNames:pipe})"}),0)',

    Network: {
      Usage: {
        Rx: (cluster?: string, namespace?: string) => {
          return `
            sum(
              max by (pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string) => {
          return `
            - sum(
              max by (pod, interface) (
                rate(container_network_transmit_bytes_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
            )
          `;
        },
        RxBy: (cluster?: string, namespace?: string) => {
          return `
            sum by (cluster, namespace, workload, workload_type, pod) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace, '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
        TxBy: (cluster?: string, namespace?: string) => {
          return `
            - sum by (cluster, namespace, workload, workload_type, pod) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_bytes_total{cluster="${cluster}", namespace="${namespace}",  pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace, '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
      },

      Dropped: {
        Rx: (cluster?: string, namespace?: string) => {
          return `
            sum(
              max by (pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string) => {
          return `
            - sum(
              max by (pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
            )
          `;
        },
        RxBy: (cluster?: string, namespace?: string) => {
          return `
            sum by (cluster, namespace, workload, workload_type, pod) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace, '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
        TxBy: (cluster?: string, namespace?: string) => {
          return `
            - sum by (cluster, namespace, pod, workload, workload_type, pod) (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})"}[$__rate_interval])
              )
              * on (cluster, namespace, pod) group_left (workload, workload_type)
              group by (cluster, namespace, pod, workload, workload_type) (
                ${snippets.pods.withWorkloads(cluster, namespace, '', '', '(${podNames:pipe})')}
              )
            )
          `;
        },
      },
    },

    Energy: {
      Total: (cluster: string) =>
        energyQueries.ByWorkload('kepler_container_joules_total')(cluster, '', '(${podNames:pipe})'),
      Package: (cluster: string) =>
        energyQueries.ByWorkload('kepler_container_package_joules_total')(cluster, '', '(${podNames:pipe})'),
      DRAM: (cluster: string) =>
        energyQueries.ByWorkload('kepler_container_dram_joules_total')(cluster, '', '(${podNames:pipe})'),
      GPU: (cluster: string) =>
        energyQueries.ByWorkload('kepler_container_gpu_joules_total')(cluster, '', '(${podNames:pipe})'),
      Other: (cluster: string) =>
        energyQueries.ByWorkload('kepler_container_other_joules_total')(cluster, '', '(${podNames:pipe})'),
      ByPod: (cluster: string) =>
        energyQueries.ByPod('kepler_container_joules_total')(cluster, '', '(${podNames:pipe})'),
    },

    CostCpuAllocation: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        # actual cost of cpu allocation and usage is the max of:
        max by (cluster, namespace, node) (
            # cpu requests (in cores)
            sum by (cluster, namespace, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="cpu"})
            OR
            # cpu usage (in cores)
            sum by (cluster, namespace, node) (max by (cluster, namespace, node, pod, container) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # multiplied by the hourly cost per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostMemoryAllocation: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        # actual cost of memory allocation and usage is the max of:
        max by (cluster, node) (
            # memory requests (in bytes)
            sum by (cluster, node, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="memory"})
            OR
            # memory usage (in bytes)
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              container_memory_rss{cluster="${cluster}", namespace="${namespace}", node!="", container!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", node="", container!=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # in GiB
        /1024/1024/1024

        # multiplied by the hourly cost per GB per node where this pod is running
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostCpuIdle: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        (
            # requests
            sum by (cluster, node) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="cpu"}
            )
            # usage
            - on (cluster, node) group_left
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node!="", container!=""}[$__rate_interval])
              OR
              label_replace(
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node="", container!=""}[$__rate_interval])
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        * on (cluster, node) group_left
        max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
    CostMemoryIdle: (cluster?: string, namespace?: string) => `
      sum_over_time(sum(
        (
            # requests
            sum by (cluster, node) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", container!="", resource="memory"}
            )
            # usage
            - on (cluster, node) group_left
            sum by (cluster, node) (max by (cluster, namespace, node, pod, container) (
              container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node!="", container!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod=~"(\${podNames:pipe})", node="", container!=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
        )
        # in GiB
        /1024/1024/1024
        * on (cluster, node) group_left
        max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
      )[$__range:1h])
    `,
  },
  PodDetail: {
    CpuRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          namespace,
          pod,
          container,
        }),
      }),
    CpuLimits: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          namespace,
          pod,
          container,
        }),
      }),
    CpuUsage: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: cadvisor.metrics.container_cpu_usage_seconds_total({ cluster, namespace, pod, container }),
      }),
    CpuAllocation: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.max({
        by: ['namespace'],
        expr: promql.or({
          left: promql.sum({
            by: ['namespace', 'resource'],
            expr: ksm.metrics.kube_pod_container_resource_requests({
              resource: 'cpu',
              cluster,
              namespace,
              pod,
              container,
            }),
          }),

          right: promql.sum({
            by: ['namespace'],
            expr: cadvisor.metrics.container_cpu_usage_seconds_total({ cluster, namespace, pod, container }),
          }),
        }),
      }),
    MemoryRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          namespace,
          pod,
          container,
        }),
      }),
    MemoryLimits: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          namespace,
          pod,
          container,
        }),
      }),
    MemoryUsage: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.sum({
        by: container ? ['container'] : ['pod'],
        expr: cadvisor.metrics.container_memory_rss({ cluster, namespace, pod, container }),
      }),
    MemoryAllocation: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.max({
        by: ['namespace'],
        expr: promql.or({
          left: promql.sum({
            by: ['namespace', 'resource'],
            expr: ksm.metrics.kube_pod_container_resource_requests({
              resource: 'memory',
              cluster,
              namespace,
              pod,
              container,
            }),
          }),

          right: promql.sum({
            by: ['namespace'],
            expr: cadvisor.metrics.container_memory_rss({ cluster, namespace, pod, container }),
          }),
        }),
      }),

    Network: {
      Usage: {
        Rx: (cluster?: string, namespace?: string, pod?: string, labels?: string) => {
          return `
            sum ${promql.by(labels?.split(','))} (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_receive_bytes_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string, pod?: string, labels?: string) => {
          return `
            - sum ${promql.by(labels?.split(','))} (
              max by (cluster, namespace, pod, interface) (
                rate(container_network_transmit_bytes_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}[$__rate_interval])
              )
            )
          `;
        },
      },

      Dropped: {
        Rx: (cluster?: string, namespace?: string, pod?: string, labels?: string) => {
          return `
            sum ${promql.by(labels?.split(','))} (
              max by (pod, interface) (
                rate(container_network_receive_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}[$__rate_interval])
              )
            )
          `;
        },
        Tx: (cluster?: string, namespace?: string, pod?: string, labels?: string) => {
          return `
            - sum ${promql.by(labels?.split(','))} (
              max by (pod, interface) (
                rate(container_network_transmit_packets_dropped_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}"}[$__rate_interval])
              )
            )
          `;
        },
      },
    },
    Energy: {
      Total: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByPod('kepler_container_joules_total')(cluster, namespace, pod),
      Package: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByPod('kepler_container_package_joules_total')(cluster, namespace, pod),
      DRAM: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByPod('kepler_container_dram_joules_total')(cluster, namespace, pod),
      GPU: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByPod('kepler_container_gpu_joules_total')(cluster, namespace, pod),
      Other: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByPod('kepler_container_other_joules_total')(cluster, namespace, pod),
      ByContainer: (cluster: string, namespace?: string, pod?: string) =>
        energyQueries.ByContainer('kepler_container_joules_total')(cluster, namespace, pod),
    },

    NumContainers: (cluster?: string, namespace?: string, pod?: string) =>
      `count by (pod) (kube_pod_container_info{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container!=""}) OR clamp_max(absent(fake_metric{pod="${pod}"}),0)`,
    CpuRequestsText: (cluster?: string, namespace?: string, pod?: string) =>
      promql.count({
        by: ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'cpu',
          cluster,
          namespace,
          pod,
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${pod})"}),0)',
    CpuLimitsText: (cluster?: string, namespace?: string, pod?: string) =>
      promql.count({
        by: ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'cpu',
          cluster,
          namespace,
          pod,
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${pod})"}),0)',
    MemoryRequestsText: (cluster?: string, namespace?: string, pod?: string) =>
      promql.count({
        by: ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_requests({
          resource: 'memory',
          cluster,
          namespace,
          pod,
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${pod})"}),0)',
    MemoryLimitsText: (cluster?: string, namespace?: string, pod?: string) =>
      promql.count({
        by: ['pod'],
        expr: ksm.metrics.kube_pod_container_resource_limits({
          resource: 'memory',
          cluster,
          namespace,
          pod,
        }),
      }) + ' OR clamp_max(absent(fake_metric{pod=~"(${pod})"}),0)',
    CostCpuAllocation: (cluster?: string, namespace?: string, pod?: string, container?: string) => {
      const byPodOrContainer = container ? 'container' : 'pod';
      container = container || '.+';

      return `
        sum_over_time(sum(
          # actual cost of cpu allocation and usage is the max of:
          max by (cluster, node, ${byPodOrContainer}) (
              # cpu requests (in cores)
              sum by (cluster, node, ${byPodOrContainer}, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", resource="cpu"})
              OR
              # cpu usage (in cores)
              sum by (cluster, node, ${byPodOrContainer}) (max by (cluster, node, namespace, pod, container) (
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node!=""}[$__rate_interval])
                OR
                label_replace(
                  rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node=""}[$__rate_interval])
                  , "node", "$1", "instance", "([^:]+).*"
                )
              ))
          )
          # multiplied by the hourly cost per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
      `;
    },
    CostMemoryAllocation: (cluster?: string, namespace?: string, pod?: string, container?: string) => {
      const byPodOrContainer = container ? 'container' : 'pod';
      container = container || '.+';

      return `
        sum_over_time(sum(
          # actual cost of memory allocation and usage is the max of:
          max by (cluster, node, ${byPodOrContainer}) (
            # memory requests (in bytes)
            sum by (cluster, node, ${byPodOrContainer}, resource) (kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", resource="memory"})
            OR
            # memory usage (in bytes)
            sum by (cluster, node, ${byPodOrContainer}) (max by (cluster, node, namespace, pod, container) (
              container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node!=""}
              OR
              label_replace(
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node=""}
                , "node", "$1", "instance", "([^:]+).*"
              )
            ))
          )
          # in GiB
          /1024/1024/1024

          # multiplied by the hourly cost per GB per node where this pod is running
          * on (cluster, node) group_left
          max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
      `;
    },
    CostCpuIdle: (cluster?: string, namespace?: string, pod?: string, container?: string) => {
      const byPodOrContainer = container ? 'container' : 'pod';
      container = container || '.+';

      return `
        # cost cpu idle
        sum_over_time(sum(
          (
            # requests
            sum by (cluster, node, ${byPodOrContainer}) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", resource="cpu"}
            )
            # usage
            - on (cluster, node, ${byPodOrContainer}) group_left
            label_replace(
              sum by (cluster, instance, ${byPodOrContainer}) (max by (cluster, instance, namespace, pod, container) (
                rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}"}[$__rate_interval])
              ))
              , "node", "$1", "instance", "([^:]+).*"
            )
          )
          * on (cluster, node) group_left
          max by (cluster, node) (node_cpu_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
      `;
    },
    CostMemoryIdle: (cluster?: string, namespace?: string, pod?: string, container?: string) => {
      const byPodOrContainer = container ? 'container' : 'pod';
      container = container || '.+';

      return `
        sum_over_time(
          sum(
            (
              # requests
              sum by (cluster, ${byPodOrContainer}) (
                kube_pod_container_resource_requests{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", resource="memory"}
              )
              # usage
              - on (cluster, ${byPodOrContainer}) group_left (node)
              sum by (cluster, node, ${byPodOrContainer}) (max by (cluster, node, namespace, pod, container) (
                container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node!=""}
                OR
                label_replace(
                  container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container=~"${container}", node=""}
                  , "node", "$1", "instance", "([^:]+).*"
                )
              ))
            )
            # in GiB
            /1024/1024/1024
            * on (cluster, node) group_left
            max by (cluster, node) (node_ram_hourly_cost{cluster="${cluster}"})
        )[$__range:1h])
      `;
    },
  },
  Container: {
    Info: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      promql.last_over_time({
        expr: `
        sum by (cluster, namespace, pod, container, container_id, image, image_id, image_spec) (kube_pod_container_info{cluster=~"${cluster}", namespace=~"${namespace}", pod=~"${pod}", container=~"${container}"})
        * on (cluster, namespace, pod) group_left(node, pod_ip, host_ip, priority_class)
        group by (cluster, namespace, pod, node, pod_ip, host_ip, priority_class) (
          kube_pod_info{cluster=~"${cluster}", namespace=~"${namespace}", pod=~"${pod}"}
        )
        * on (cluster, namespace, pod) group_left (workload, workload_type)
        group by (cluster, namespace, pod, workload, workload_type) (
          ${snippets.pods.withWorkloads(cluster, namespace, '', '', pod)}
        )
      `,
      }),
    CurrentCpuLimit: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      ksm.metrics.kube_pod_container_resource_limits({ resource: 'cpu', cluster, namespace, pod, container }),
    RecommendedCpuLimit: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      `1.4*max(max_over_time(max by (cluster, namespace, pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval]))[$__range:]))`,
    CPULimitSizing: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      avg by (cluster, namespace, pod, container) (max_over_time(${SceneQueries.Container.CurrentCpuLimit(
        cluster,
        namespace,
        pod,
        container
      )}[$__range:]))
      - on (container) (1.4*max_over_time(max by (cluster, namespace, pod, container) (
          label_replace(rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval]),
        "node", "$1", "instance", "([^:]+).*"))[$__range:]))
  `,
    CurrentMemoryLimit: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      ksm.metrics.kube_pod_container_resource_limits({ resource: 'memory', cluster, namespace, pod, container }),
    RecommendedMemoryLimit: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      `1.4*max(max_over_time(max by (cluster, namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"})[$__range:]))`,
    MemoryLimitSizing: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      max by (cluster, namespace, pod, container) (max_over_time(${SceneQueries.Container.CurrentMemoryLimit(
        cluster,
        namespace,
        pod,
        container
      )}[$__range:]))
      - on (container)
      (1.4*max_over_time(max by (cluster, namespace, pod, container) (
          label_replace(container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"},
          "node", "$1", "instance", "([^:]+).*"))[$__range:]))
    `,

    CurrentCpuRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      ksm.metrics.kube_pod_container_resource_requests({
        resource: 'cpu',
        cluster,
        namespace,
        pod,
        container,
      }),
    RecommendedCpuRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      1.2*max(max_over_time(max by (cluster, namespace, pod, container) (rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval]))[$__range:]))
    `,
    CPURequestsSizing: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      avg by (cluster, namespace, pod, container) (max_over_time(${SceneQueries.Container.CurrentCpuRequests(
        cluster,
        namespace,
        pod,
        container
      )}[$__range:]))
        - on (container)
        (1.2*max_over_time(max by (cluster, namespace, pod, container) (
          label_replace(rate(container_cpu_usage_seconds_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval]),
          "node", "$1", "instance", "([^:]+).*"))[$__range:]))
    `,
    CurrentMemoryRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      ksm.metrics.kube_pod_container_resource_requests({
        resource: 'memory',
        cluster,
        namespace,
        pod,
        container,
      }),
    RecommendedMemoryRequests: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      1.2*max(max_over_time(max by (cluster, namespace, pod, container) (container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"})[$__range:]))
    `,
    MemoryRequestsSizing: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      max by (cluster, namespace, pod, container) (max_over_time(${SceneQueries.Container.CurrentMemoryRequests(
        cluster,
        namespace,
        pod,
        container
      )}[$__range:]))
        - on (container)
        (1.2*max_over_time(max by (cluster, namespace, pod, container) (
          label_replace(container_memory_rss{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"},
          "node", "$1", "instance", "([^:]+).*"))[$__range:]))
    `,

    ThrottledPercentageA: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      # throttled percentage := container_cpu_cfs_throttled_periods_total / container_cpu_cfs_periods_total
      container_cpu_cfs_throttled_periods_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}
      /
      container_cpu_cfs_periods_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}
    `,
    ThrottledPercentageB: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      # https://github.com/kubernetes-monitoring/kubernetes-mixin/blob/31169fd115654ca023c03cd7b45b9c96704a87e2/alerts/resource_alerts.libsonnet#L200
      sum(increase(container_cpu_cfs_throttled_periods_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval])) by (container, pod, namespace)
      /
      sum(increase(container_cpu_cfs_periods_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval])) by (container, pod, namespace)
    `,
    OOMKilledSince: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      sum without (instance) (kube_pod_container_status_last_terminated_reason{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}", reason="OOMKilled"})
    `,
    LastTerminatedReason: (cluster?: string, namespace?: string, pod?: string, container?: string) =>
      `sum without (instance) (kube_pod_container_status_last_terminated_reason{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"})`,
    RestartsPerSecond: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      rate(kube_pod_container_status_restarts_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}[$__rate_interval])
    `,
    RestartHistory: (cluster?: string, namespace?: string, pod?: string, container?: string) => `
      (kube_pod_container_status_restarts_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"}
      - kube_pod_container_status_restarts_total{cluster="${cluster}", namespace="${namespace}", pod="${pod}", container="${container}"} offset 1m) > 0
    `,
    Energy: {
      Total: (cluster: string, namespace?: string, pod?: string, container?: string) =>
        energyQueries.ByContainer('kepler_container_joules_total')(cluster, namespace, pod, container),
      Package: (cluster: string, namespace?: string, pod?: string, container?: string) =>
        energyQueries.ByContainer('kepler_container_package_joules_total')(cluster, namespace, pod, container),
      DRAM: (cluster: string, namespace?: string, pod?: string, container?: string) =>
        energyQueries.ByContainer('kepler_container_dram_joules_total')(cluster, namespace, pod, container),
      GPU: (cluster: string, namespace?: string, pod?: string, container?: string) =>
        energyQueries.ByContainer('kepler_container_gpu_joules_total')(cluster, namespace, pod, container),
      Other: (cluster: string, namespace?: string, pod?: string, container?: string) =>
        energyQueries.ByContainer('kepler_container_other_joules_total')(cluster, namespace, pod, container),
    },
  },
  Alerts: {
    Cluster: `
      count by (cluster) (
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace="", alertname=~"Kube.*"}
        OR
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})"}
      )`,
    Namespace: `
      count by (namespace) (
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})"}
      )`,
    Severity: `
      count by (severity) (
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace="", alertname=~"Kube.*"}
        OR
        ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})"}
      )`,
    Table: `
      ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=""}
      OR
      ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod="", daemonset="", deployment="", statefulset="", job_name=""}
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod="", daemonset!=""}
          , "workload_type", "daemonset", "", ""
        )
        , "workload", "$1", "daemonset", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod="", deployment!=""}
          , "workload_type", "deployment", "", ""
          )
        , "workload", "$1", "deployment", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod="", statefulset!=""}
          , "workload_type", "statefulset", "", ""
          )
        , "workload", "$1", "statefulset", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod="", job_name!=""}
          , "workload_type", "job", "", ""
          )
        , "workload", "$1", "job_name", "(.*)"
      )
      OR
      ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(\${cluster:pipe})", severity=~"(\${severity:pipe})", namespace=~"(\${namespace:pipe})", pod=~"(\${cachePodNames:pipe})"}
      * on (namespace, pod) group_left (workload, workload_type)
      group by (pod, workload, workload_type, namespace)
      (${snippets.pods.withWorkloads('${cluster:pipe}', '${namespace:pipe}', '', '', '${cachePodNames:pipe}')})`,
  },
};

export const CostsScenesQueries = {
  totalCost0d30d: '$total_cost_0d_15d + $total_cost_15d_30d',
  totalCost30d60d: '$total_cost_30d_45d + $total_cost_45d_60d',
  totalCostOverTime: (offset?: number) =>
    `${promql.sum_over_time({
      expr: `sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"$cluster"}${promql.offset({
        units: { d: offset },
      })}))`,
      range: '15d',
      interval: '1h',
    })} or vector(0)`,

  runningPods0d30d: '($running_pods_0d_15d + $running_pods_15d_30d) /2',
  runningPods30d60d: '($running_pods_30d_45d + $running_pods_45d_60d) / 2',
  runningPodsOverTime: (offset?: number) =>
    `${promql.avg_over_time({
      expr: `sum(max by (cluster, instance) (kubelet_running_pods{cluster=~"$cluster"}${promql.offset({
        units: { d: offset },
      })}))`,
      range: '15d',
      interval: '1d',
    })} or vector(0)`,
  costPerPod0d30d: '$total_cost_0d_30d / $running_pods_0d_30d',
  costPerPod30d60d: '$total_cost_30d_60d / $running_pods_30d_60d',
  runningPodsPercentChange: '1 - ($running_pods_30d_60d / $running_pods_0d_30d)',
  totalCostPercentChange: '1 - ($total_cost_30d_60d / $total_cost_0d_30d)',
  costPerPodPercentChange: '1 - ($cost_per_pod_30d_60d / $cost_per_pod_0d_30d)',
  Next30Days: {
    totalComputeCost: '$total_cost_0d_30d + ($total_cost_0d_30d * $total_cost_percent_change)',
    averageCostPerPod: '$cost_per_pod_0d_30d + ($cost_per_pod_0d_30d * $cost_per_pod_percent_change)',
    averagePodCount: '$running_pods_0d_30d + ($running_pods_0d_30d * $running_pods_percent_change)',
  },

  savings30Days: `
    sum(
      floor(
        max by (cluster, node) (
          kube_node_status_capacity{resource="cpu", cluster=~"(\${cluster:pipe})"}
        )
        - on (cluster, node) group_left(pod) (
          sum by (cluster, node) (
            cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster=~"(\${cluster:pipe})"}
          )
        )
      )
      * on (cluster, node) group_left()
      max by (cluster, node) (node_cpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    ) * 24 * 30`,
  totalCost: 'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})"}))',
  unallocatedResources: `
    (
      count(
        max by (cluster, namespace, pod, persistentvolume, persistentvolumeclaim) (pod_pvc_allocation{cluster=~"(\${cluster:pipe})", pod="unmounted-pvs"})
      )
      /
      count(
        max by (cluster, namespace, pod, persistentvolume, persistentvolumeclaim) (pod_pvc_allocation{cluster=~"(\${cluster:pipe})", pod!=""})
      )
    )
    OR on () vector(0)`,
  costsVsSavingsA: 'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})"}))',
  costsVsSavingsB: `
    sum(
      floor(
        max by (cluster, node) (
          kube_node_status_capacity{resource="cpu", cluster=~"(\${cluster:pipe})"}
        )
        - on (cluster, node) group_left(pod) (
          sum by (cluster, node) (
            cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster=~"(\${cluster:pipe})"}
          )
        )
      )
      * on (cluster, node) group_left()
      max by (cluster, node) (node_cpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  gcpCost:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"gce://.*"}))',
  gcpGrowthVsTimeRange:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"gce://.*"})) / sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"gce://.*"} offset $__range)) - 1',
  gcpCostByDay:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"gce://.*"})) * 24',
  awsCost:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"aws://.*"}))',
  awsGrowthVsTimeRange:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"aws://.*"})) / sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"aws://.*"} offset $__range)) - 1',
  awsCostByDay:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"aws://.*"})) * 24',
  azureCost:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"azure://.*"}))',
  azureGrowthVsTimeRange:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"azure://.*"})) / sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"azure://.*"} offset $__range)) - 1',
  azureCostByDay:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id=~"azure://.*"})) * 24',
  othersCost:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id!~"(aws|azure|gce)://.*"}))',
  othersGrowthVsTimeRange:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id!~"(aws|azure|gce)://.*"})) / sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id!~"(aws|azure|gce)://.*"} offset $__range)) - 1',
  othersCostByDay:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})", provider_id!~"(aws|azure|gce)://.*"})) * 24',
  growthVsTimeRange:
    'sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})"})) / sum(max by (cluster, node) (node_total_hourly_cost{cluster=~"(${cluster:pipe})"} offset $__range)) - 1',
  cpuCost: `
    sum(
      sum by (cluster, node) (
        kube_node_status_capacity{resource="cpu", cluster=~"(\${cluster:pipe})"}
      )
      * on (cluster, node) group_left
      max by (cluster, node) (node_cpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  cpuSavings: `
    sum(
      floor(
        avg by (cluster, node) (
          kube_node_status_capacity{resource="cpu", cluster=~"(\${cluster:pipe})"}
        )
        - on (cluster, node) group_left() (
          sum by (cluster, node) (
            cluster:namespace:pod_cpu:active:kube_pod_container_resource_requests{cluster=~"(\${cluster:pipe})"}
          )
        )
      )
      * on (cluster, node) group_left
      max by (cluster, node) (node_cpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  ramCost: `
    sum(
      sum by (cluster, node) (
        kube_node_status_capacity{resource="memory", cluster=~"(\${cluster:pipe})"}
      )
      /1024/1024/1024
      * on (cluster, node) group_left
      max by (cluster, node) (node_ram_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  ramSavings: `
    sum(
      (
        avg by (cluster, node) (
          kube_node_status_capacity{resource="memory", cluster=~"(\${cluster:pipe})"}
        )
        - on (cluster, node) group_left() (
          sum by (cluster, node) (
            cluster:namespace:pod_memory:active:kube_pod_container_resource_requests{cluster=~"(\${cluster:pipe})"}
          )
        )
      )
      /1024/1024/1024
      * on (cluster, node) group_left
      max by (cluster, node) (node_ram_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  storageCost: `sum(
      sum by (cluster, namespace, persistentvolume) (
        pod_pvc_allocation{cluster=~"(\${cluster:pipe})"}
      ) /1024/1024/1024
      * on (cluster, persistentvolume) group_left
      max by (cluster, persistentvolume, volumename) (pv_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  storageSavings: `sum(
      sum by (cluster, namespace, persistentvolume) (
        pod_pvc_allocation{cluster=~"(\${cluster:pipe})"}
        - on (cluster, namespace, persistentvolumeclaim) group_left()
        max without (node, instance) (
          kubelet_volume_stats_used_bytes{cluster=~"(\${cluster:pipe})"}
        )
      ) /1024/1024/1024
      * on (cluster, persistentvolume) group_left
      max by (cluster, persistentvolume, volumename) (pv_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  networkEgressCost:
    'sum(sum(rate(node_network_transmit_bytes_total{device="eth0", cluster=~"(${cluster:pipe})"}[60m])/1024/1024/1024) by (cluster) * on (cluster) sum(kubecost_network_internet_egress_cost{cluster=~"(${cluster:pipe})"} + kubecost_network_region_egress_cost{cluster=~"(${cluster:pipe})"} + kubecost_network_zone_egress_cost{cluster=~"(${cluster:pipe})"}) by (cluster))',
  gpuCost: `
    sum(
      max by (cluster, node) (node_gpu_count{cluster=~"(\${cluster:pipe})"})
      * on (cluster, node) group_left
      max by (cluster, node) (node_gpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  gpuSavings: `
    sum(
      max by (cluster, node) (node_gpu_count{cluster=~"(\${cluster:pipe})"})
      - on (cluster, node) group_left() (
        sum by (cluster, node) (
          max by (cluster, node, namespace, pod, container) (container_gpu_allocation{cluster=~"(\${cluster:pipe})"})
        )
      )
      * on (cluster, node) group_left
      max by (cluster, node) (node_gpu_hourly_cost{cluster=~"(\${cluster:pipe})"})
    )`,
  clusterList: 'label_values(kube_node_info{cluster!=""},cluster)',
};

export const AssertsQueries = {
  isEnabled: 'asserts:node:count',
  clustersEnabled: 'sum(asserts:node_memory_MemAvailable_bytes:sum) by (cluster)',
  namespaceEnabled: 'sum(kube_namespace_status_phase{asserts_env!=""} == 1) by (namespace, asserts_env, asserts_site)',
};

export const WorkloadQueries = {
  WorkloadsReadyPods: (cluster?: string, namespace?: string, workload?: string, type?: string) => {
    cluster = cluster || '.+';
    namespace = namespace || '.+';
    type = type || '.+';
    workload = workload || '.+';
    return `
    max by (cluster, namespace, workload, workload_type, asserts_env) (
      # replicaset
      max by (cluster, namespace, replicaset, asserts_env) (kube_replicaset_status_ready_replicas{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}"})
      * on (cluster, namespace, replicaset) group_left (workload, workload_type)
      label_replace(
          label_replace(
              max by (cluster, namespace, replicaset) (kube_replicaset_owner{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}", owner_kind=""})
              , "workload", "$1", "replicaset", "(.+)"
          )
          , "workload_type", "replicaset", "", ""
      )

      OR

      # deployment (kube_replicaset)
      max by (cluster, namespace, replicaset, asserts_env) (kube_replicaset_status_ready_replicas{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}"})
      * on (cluster, namespace, replicaset, asserts_env) group_left (workload, workload_type)
      label_replace(
          label_replace(
              max by (cluster, namespace, replicaset, owner_name) (kube_replicaset_owner{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}", owner_is_controller="true", owner_kind="Deployment", owner_name!=""})
              , "workload", "$1", "owner_name", "(.+)"
          )
          , "workload_type", "deployment", "", ""
      )

      OR

      # deployment (kube_deployment)
      label_replace(
        label_replace(
            max by (cluster, namespace, deployment, asserts_env) (kube_deployment_status_replicas_available{cluster=~"${cluster}", namespace=~"${namespace}", deployment=~"${workload}"})
            , "workload", "$1", "deployment", "(.+)"
        )
        , "workload_type", "deployment", "", ""
      )

      OR

      # daemonset
      label_replace(
          label_replace(
              max by (cluster, namespace, daemonset, asserts_env) (kube_daemonset_status_number_ready{cluster=~"${cluster}", namespace=~"${namespace}", daemonset=~"${workload}"})
              , "workload", "$1", "daemonset", "(.+)"
          )
      , "workload_type", "daemonset", "", ""
      )

      OR

      # job
      label_replace(
          label_replace(
              max by (cluster, namespace, job_name, asserts_env) (kube_job_status_succeeded{cluster=~"${cluster}", namespace=~"${namespace}", job_name=~"${workload}"})
              , "workload", "$1", "job_name", "(.+)"
          )
      , "workload_type", "job", "", ""
      )

      OR

      # statefulset
      label_replace(
          label_replace(
              max by (cluster, namespace, statefulset, asserts_env) (kube_statefulset_status_replicas_ready{cluster=~"${cluster}", namespace=~"${namespace}", statefulset=~"${workload}"})
              , "workload", "$1", "statefulset", "(.+)"
          )
      , "workload_type", "statefulset", "", ""
      )
  )
    `;
  },
  WorkloadsDesiredPods: (cluster?: string, namespace?: string, workload?: string, type?: string) => {
    cluster = cluster || '.+';
    namespace = namespace || '.+';
    type = type || '.+';
    workload = workload || '.+';
    return `
    max by (cluster, namespace, workload, workload_type, asserts_env) (
      # bare pods
      label_replace(
          label_replace(
              max by (cluster, namespace, pod, asserts_env) (${promql.last_over_time({
                expr: `kube_pod_owner{cluster=~"${cluster}", namespace=~"${namespace}", pod=~"${workload}", owner_kind=""}`,
              })})
              , "workload", "$1", "pod", "(.+)"
          )
          , "workload_type", "pod", "", ""
      )

      OR

      # static pods
      label_replace(
          label_replace(
              max by (cluster, namespace, pod, asserts_env) (${promql.last_over_time({
                expr: `kube_pod_owner{cluster=~"${cluster}", namespace=~"${namespace}", pod=~"${workload}", owner_kind="Node"}`,
              })})
              , "workload", "$1", "pod", "(.+)"
          )
          , "workload_type", "staticpod", "", ""
      )

      OR

      # replicaset
      max by (cluster, namespace, replicaset, asserts_env) (${promql.last_over_time({
        expr: `kube_replicaset_spec_replicas{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}"}`,
      })})
      * on (cluster, namespace, replicaset) group_left (workload, workload_type)
      label_replace(
          label_replace(
              max by (cluster, namespace, replicaset) (${promql.last_over_time({
                expr: `kube_replicaset_owner{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}", owner_kind=""}`,
              })})
              , "workload", "$1", "replicaset", "(.+)"
          )
          , "workload_type", "replicaset", "", ""
      )

      OR

      # deployment (kube_replicaset )
      max by (cluster, namespace, replicaset, asserts_env) (${promql.last_over_time({
        expr: `kube_replicaset_spec_replicas{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}"}`,
      })})
      * on (cluster, namespace, replicaset) group_left (workload, workload_type)
      label_replace(
          label_replace(
              max by (cluster, namespace, replicaset, owner_name) (${promql.last_over_time({
                expr: `kube_replicaset_owner{cluster=~"${cluster}", namespace=~"${namespace}", replicaset=~"${workload}", owner_is_controller="true", owner_kind="Deployment", owner_name!=""}`,
              })})
              , "workload", "$1", "owner_name", "(.+)"
          )
          , "workload_type", "deployment", "", ""
      )

      OR

      # deployment (kube_deployment)
      label_replace(
        label_replace(
            max by (cluster, namespace, deployment, asserts_env) (${promql.last_over_time({
              expr: `kube_deployment_spec_replicas{cluster=~"${cluster}", namespace=~"${namespace}", deployment=~"${workload}"}`,
            })})
            , "workload", "$1", "deployment", "(.+)"
        )
        , "workload_type", "deployment", "", ""
      )

      OR

      # daemonset
      label_replace(
          label_replace(
              max by (cluster, namespace, daemonset, asserts_env) (${promql.last_over_time({
                expr: `kube_daemonset_status_desired_number_scheduled{cluster=~"${cluster}", namespace=~"${namespace}", daemonset=~"${workload}"}`,
              })})
              , "workload", "$1", "daemonset", "(.+)"
          )
      , "workload_type", "daemonset", "", ""
      )

      OR

      # job
      label_replace(
          label_replace(
              max by (cluster, namespace, job_name, asserts_env) (${promql.last_over_time({
                expr: `kube_job_spec_completions{cluster=~"${cluster}", namespace=~"${namespace}", job_name=~"${workload}"}`,
              })})
              , "workload", "$1", "job_name", "(.+)"
          )
      , "workload_type", "job", "", ""
      )

      OR

      # statefulset
      label_replace(
          label_replace(
              max by (cluster, namespace, statefulset, asserts_env) (${promql.last_over_time({
                expr: `kube_statefulset_replicas{cluster=~"${cluster}", namespace=~"${namespace}", statefulset=~"${workload}"}`,
              })})
              , "workload", "$1", "statefulset", "(.+)"
          )
      , "workload_type", "statefulset", "", ""
      )
  )
    `;
  },
  WorkloadAlerts: (cluster?: string, namespace?: string, workload?: string) => {
    cluster = cluster || '.+';
    namespace = namespace || '.+';
    workload = workload || '.+';
    const pod = `${workload}-.+` || '.+';
    return `
    sum by (cluster, namespace, workload, workload_type) (
      # pod alerts
      group by (cluster, namespace, pod) (ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod=~"${pod}"})
      * on (cluster, namespace, pod) group_left (workload, workload_type)
      (
        group by (cluster, namespace, pod, workload, workload_type) (
          ${snippets.pods.withWorkloads(cluster, namespace, '', '', pod)}
        )
      )

      # workload alerts
      OR

      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod="", replicaset=~"${workload}"}
          , "workload_type", "replicaset", "", ""
        )
        , "workload", "$1", "replicaset", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod="", daemonset=~"${workload}"}
          , "workload_type", "daemonset", "", ""
        )
        , "workload", "$1", "daemonset", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod="", deployment=~"${workload}"}
          , "workload_type", "deployment", "", ""
          )
        , "workload", "$1", "deployment", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod="", statefulset=~"${workload}"}
          , "workload_type", "statefulset", "", ""
          )
        , "workload", "$1", "statefulset", "(.*)"
      )
      OR
      label_replace(
        label_replace(
          ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"${cluster}", namespace=~"${namespace}", pod="", job_name=~"${workload}"}
          , "workload_type", "job", "", ""
          )
        , "workload", "$1", "job_name", "(.*)"
      )
    )
    `;
  },
};

export const compileLogsQuery = (cluster: string, namespace?: string, pod?: string, container?: string) => {
  const namespaceFilter = namespace ? `, namespace="${namespace}"` : '';
  const podFilter = pod ? `, pod=~"^(${pod})$"` : '';
  const containerFilter = container ? `, container="${container}"` : '';
  return `{
  ${defaultLogsLabel},
  cluster="${cluster}" ${namespaceFilter} ${podFilter} ${containerFilter}
} | logfmt | json | drop __error__,__error_details__`;
};

export const compileOTelLogsQuery = (cluster: string, namespace?: string, pod?: string, container?: string) => {
  const namespaceFilter = namespace ? `, k8s_namespace_name="${namespace}"` : '';
  const podFilter = pod ? `, k8s_pod_name=~"^(${pod})$"` : '';
  const containerFilter = container ? `, k8s_container_name="${container}"` : '';
  return `{
  ${defaultLogsLabel},
  k8s_cluster_name="${cluster}" ${namespaceFilter} ${podFilter} ${containerFilter}
} | logfmt | json | drop __error__,__error_details__`;
};

export const compileEventsQuery = (cluster: string, namespace?: string, pod?: string, container?: string) => {
  const namespaceFilter = namespace ? `, namespace="${namespace}"` : '';
  const podFilter = pod ? `| kind = "Pod" | name =~ "^(${pod})$"` : '';
  const containerFilter = container ? `| msg =~ ".*${container}.*"` : '';
  return `{
  ${defaultEventsLabel},
  cluster="${cluster}" ${namespaceFilter}
} | logfmt | json | drop __error__,__error_details__ ${podFilter} ${containerFilter}`;
};

export const compileOTelEventsQuery = (cluster: string, namespace?: string, pod?: string, container?: string) => {
  const namespaceFilter = namespace ? `| attributes_k8s_namespace_name = "${namespace}"` : '';
  const podFilter = pod ? `| resources_k8s_object_kind = "Pod" | resources_k8s_object_name =~ "^(${pod})$"` : '';
  const containerFilter = container ? `| body =~ ".*${container}.*"` : '';
  return `{
  ${defaultEventsLabel},
  k8s_cluster_name="${cluster}"
} | logfmt | json | drop __error__,__error_details__ ${namespaceFilter} ${podFilter} ${containerFilter}`;
};

const energyQueries = {
  range: '1h:$__rate_interval',
  ByCluster: (metric: string) => (cluster: string) =>
    // Kepler adds special metrics that come from the system, but not from within the cluster. These
    // are identified by:
    //   container_namespace="system", pod_name="system_processes"
    // or:
    //   container_namespace="kernel", pod_name="kernel_processes"
    `
    sum by(cluster) (
      increase(
        (
          ${metric}{cluster="${cluster}"}
          unless
          (
            ${metric}{cluster="${cluster}", container_namespace="system", pod_name="system_processes"}
            or
            ${metric}{cluster="${cluster}", container_namespace="kernel", pod_name="kernel_processes"}
          )
        )[${energyQueries.range}]
      )
    ) * $watt_per_second_to_kWh`,
  ByNode: (metric: string) => (cluster: string, node?: string) => {
    const nodeFilter = node ? `, node="${node}"` : '';
    return `
      sum by (cluster, node) (
        increase(
          (
            kube_pod_info{cluster="${cluster}"${nodeFilter}}
            * on(cluster, namespace, pod) group_left
            sum by (cluster, namespace, pod) (
              label_join(
                label_join(
                  ${metric}{cluster="${cluster}"},
                  "pod", "", "pod_name"
                ),
                "namespace", "", "container_namespace"
              )
            )
          )[${energyQueries.range}]
        )
      ) * $watt_per_second_to_kWh`;
  },
  ByPodOnNode: (metric: string) => (cluster: string) =>
    `
    sum by (cluster, namespace, pod, workload, workload_type) (
      sum by (cluster, namespace, pod) (
        label_join(
          label_join(
            ${metric}{cluster="${cluster}"},
            "pod", "", "pod_name"
          ),
          "namespace", "", "container_namespace"
        )
      )
      * on (cluster, namespace, pod) group_left (workload, workload_type)
      group by (cluster, namespace, pod, workload, workload_type) (
        ${snippets.pods.withWorkloads(cluster, '', '', '', '(${podNames:pipe})')}
      )
    ) * $watt_per_second_to_kWh`,
  ByNamespace: (metric: string) => (cluster: string, namespace?: string) => {
    // Kepler adds special metrics that come from the system, but not from within the cluster. These
    // are identified by:
    //   container_namespace="system", pod_name="system_processes"
    // or:
    //   container_namespace="kernel", pod_name="kernel_processes"
    const namespaceFilter = namespace ? `, container_namespace="${namespace}"` : '';
    return `
          sum by(cluster, namespace) (
            increase(
              label_join(
                (
                  ${metric}{cluster="${cluster}"${namespaceFilter}}
                  unless
                  (
                    ${metric}{cluster="${cluster}", container_namespace="system", pod_name="system_processes"}
                    or
                    ${metric}{cluster="${cluster}", container_namespace="kernel", pod_name="kernel_processes"}
                  )
                ),
                "namespace", "", "container_namespace"
              )[${energyQueries.range}]
            )
          ) * $watt_per_second_to_kWh`;
  },
  ByWorkload: (metric: string) => (cluster: string, namespace?: string, pod?: string) =>
    `sum by(cluster, namespace, workload, workload_type) (
  ${energyQueries.workloadQuery(metric)(cluster, namespace, pod)}
)`,
  ByPod: (metric: string) => (cluster: string, namespace?: string, pod?: string) =>
    `sum by(cluster, namespace, pod, workload, workload_type) (
  ${energyQueries.workloadQuery(metric)(cluster, namespace, pod)}
)`,
  ByContainer: (metric: string) => (cluster: string, namespace?: string, pod?: string, container?: string) =>
    `sum by(cluster, namespace, pod, workload, workload_type, container) (
  ${energyQueries.workloadQuery(metric)(cluster, namespace, pod, container)}
)`,
  workloadQuery: (metric: string) => (cluster: string, namespace?: string, pod?: string, container?: string) => {
    const namespaceFilter = namespace ? `, container_namespace="${namespace}"` : '';
    const podFilter = pod
      ? pod === '(${podNames:pipe})'
        ? ', pod_name=~"(${podNames:pipe})"'
        : `, pod_name=~"${pod}"`
      : '';
    const containerFilter = container ? `, container_name="${container}"` : '';
    return `
        increase(
          label_join(
            label_join(
              label_join(
                ${metric}{cluster="${cluster}"${namespaceFilter}${podFilter}${containerFilter}},
                "container", "", "container_name"
              ),
              "pod", "", "pod_name"
            ),
            "namespace", "", "container_namespace"
          )[${energyQueries.range}]
        )
        * on (cluster, namespace, pod) group_left (workload, workload_type)
        group by (cluster, namespace, pod, workload, workload_type) (
          ${snippets.pods.withWorkloads(cluster, namespace, '', '', pod)}
        ) * $watt_per_second_to_kWh`;
  },
};
