import React from 'react';
import { shallow } from 'zustand/shallow';
import { DataSourceInstanceSettings, DataSourceJsonData } from '@grafana/data';
import { DataSourcePicker } from '@grafana/runtime';
import useDatasourceStore from 'store/datasource';
import useLocalStorage from 'hooks/useLocalStorage';
import { StoredParams } from 'types';
import { K8S_STORAGE_KEY } from '../constants';
import useQueryParams from 'hooks/useQueryParams';

interface Props {
  className?: string;
  onChange?: (ds: DataSourceInstanceSettings<DataSourceJsonData>) => void;
}

const LokiPicker = ({ className, onChange }: Props) => {
  const [lokiName, setLokiName] = useDatasourceStore((state) => [state.lokiName, state.setLokiName], shallow);
  const [, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const { updateQueryParam } = useQueryParams();

  return (
    <div className={className}>
      <DataSourcePicker
        current={lokiName}
        type="loki"
        pluginId="loki"
        onChange={(ds) => {
          setLokiName(ds.name);
          setStoredParams({ lokiName: ds.name });
          updateQueryParam('var-loki', ds.name);
          if (onChange) {
            onChange(ds);
          }
        }}
        dashboard={false}
        filter={(datasource) => !datasource.name.includes('usage-insights')}
      />
    </div>
  );
};

export default LokiPicker;
