import { MatchingOperator } from '../types';
import { Expression } from '../expression';
import { promql } from '../promql';

type cluster = { cluster?: string };
type container = { container?: string };
type cpu = { cpu?: string };
type id = { id?: string };
type image = { image?: string };
type instance = { instance?: string };
type name = { instance?: string };
type namespace = { namespace?: string };
type node = { node?: string };
type pod = { pod?: string };

type container_labels = cluster & instance & namespace & pod & container & image & name & id;

export const cadvisor = {
  metrics: {
    container_cpu_usage_seconds_total: (values: container_labels & cpu) =>
      promql.max({
        by: ['cluster', 'instance', 'namespace', 'pod', 'container'],
        expr: promql.rate({
          expr: new Expression({
            metric: 'container_cpu_usage_seconds_total',
            values,
            defaultOperator: MatchingOperator.regexMatch,
            defaultSelectors: [{ label: 'container', operator: MatchingOperator.notEqual, value: '' }],
          }).toString(),
        }),
      }),

    container_memory_rss: (values: container_labels) =>
      promql.max({
        by: ['cluster', 'instance', 'namespace', 'pod', 'container'],
        expr: new Expression({
          metric: 'container_memory_rss',
          values,
          defaultOperator: MatchingOperator.regexMatch,
          defaultSelectors: [{ label: 'container', operator: MatchingOperator.notEqual, value: '' }],
        }).toString(),
      }),
  },

  rules: {
    'node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate': (
      values: cluster & node & namespace & pod & container
    ) =>
      new Expression({
        metric: 'node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate',
        values,
        defaultOperator: MatchingOperator.regexMatch,
      }).toString(),

    'node_namespace_pod_container:container_memory_rss': (values: container_labels) =>
      new Expression({
        metric: 'node_namespace_pod_container:container_memory_rss',
        values,
        defaultOperator: MatchingOperator.regexMatch,
      }).toString(),
  },
};
