const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "presto" {
  targets = discovery.kubernetes.services.targets
  rule {
    source_labels = ["__meta_kubernetes_service_label_<service label>"]
    regex = "<service label value>"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_service_port_number"]
    regex = "<your-presto-service-listening-port>"
    action = "keep"
  }
  rule {
    replacement = "integrations/presto"
    target_label = "job"
  }
}

prometheus.scrape "presto" {
  targets      = discovery.relabel.presto.output
  honor_labels = true
  forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

export default { metrics };
