const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.squid "squid_exporter" {
    address = "<squid-service>.<squid-namespace>.svc.cluster.local:<squid-port>"
}

prometheus.scrape "integrations_squid_default" {
    targets      = prometheus.exporter.squid.squid_exporter.targets
    forward_to   = [prometheus.relabel.metrics_service.receiver]
    job_name     = "integrations/squid"
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
loki.process "squid_cache_logs_process" {
    forward_to = [loki.relabel.squid_cache_logs_label.receiver]

    stage.drop {
        expression = \`^\\d+\\.\\d+\`
    }
    stage.multiline {
        firstline = \`^\\d{4}/\\d{2}/\\d{2} \\d{2}:\\d{2}:\\d{2}\`
    }
    stage.regex {
        expression = \`^\\d{4}/\\d{2}/\\d{2} \\d{2}:\\d{2}:\\d{2}\`
    }
}

loki.process "squid_access_logs_process" {
    forward_to = [loki.relabel.squid_access_logs_label.receiver]

    stage.drop {
        expression = \`^\\s+listening port: \\d+\`
    }
    stage.drop {
        expression = \`^\\d{4}/\\d{2}/\\d{2} \\d{2}:\\d{2}:\\d{2}\`
    }
    stage.regex {
        expression = \`^\\d+\\.\\d+\`
    }
}

loki.relabel "squid_cache_logs_label" {
    forward_to = [loki.write.logs_service.receiver]
    rule {
        replacement = "cache"
        target_label = "log_type"
    }
}

loki.relabel "squid_access_logs_label" {
    forward_to = [loki.write.logs_service.receiver]
    rule {
        replacement = "access"
        target_label = "log_type"
    }
}

discovery.relabel "squid_logs" {
    targets = discovery.relabel.pod_logs.output

    rule {
        action        = "keep"
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator     = ":"
        regex         = "(<squid-namespace>:<squid-pod-name>)"
    }

    rule {
        replacement  = "<your-squid-cluster>"
        target_label = "squid_cluster"
    }

    rule {
        replacement   = "integrations/squid" 
        target_label  = "job"
    }

    rule {
        replacement   = "<squid-exporter-address-value>"
        target_label  = "instance"
    }
}

loki.source.kubernetes "squid_logs" {
    targets      = discovery.relabel.squid_logs.output
    forward_to   = [loki.process.squid_access_logs_process.receiver,loki.process.squid_cache_logs_process.receiver]
}`;

export default { metrics, logs };
