import Loader from 'components/Loader/Loader';
import React, { lazy, Suspense } from 'react';
import integrations from 'static/config/integrations';
import { ExtensionLink } from 'types';

export interface ChartConfigProps {
  slug: string;
  showTitle?: boolean;
}

const HelmChartIntegration = lazy(() => import('components/IntegrationsList/HelmChartIntegration'));

export function HelmChartIntegrationConfigExtension({ context }: { context?: ChartConfigProps }) {
  return (
    <Suspense fallback={<Loader />}>
      <HelmChartIntegration showTitle={context?.showTitle} slug={context?.slug || ''} />
    </Suspense>
  );
}

export const HelmChartIntegrationConfigExtensionParams = {
  title: 'K8s Helm Chart Config',
  description: 'K8s Helm Chart Integration Snippets',
  targets: 'grafana/grafana-k8s-app/helm-chart-integration-snippets/v1',
  component: HelmChartIntegrationConfigExtension,
};

export const HelmChartConfigLinkParams: ExtensionLink = {
  title: 'K8s Helm Chart Config Check',
  description: 'K8s Helm Chart Integration Snippets Check',
  targets: 'grafana/grafana-k8s-app/helm-chart-integration-snippets-check/v1',
  path: '/a/grafana-k8s-app/helm-chart-integration-snippets-check/v1',
  configure(context) {
    if (!context?.slug || integrations[context.slug]?.logs || integrations[context.slug]?.metrics) {
      return {};
    } else {
      return undefined;
    }
  },
};
