import { SceneQueries } from 'queries';
import { DataSourceRef, VariableHide, VariableRefresh } from '@grafana/schema';
import { QueryVariable, SceneVariableSet } from '@grafana/scenes';
import { query_result } from 'helpers/scenes';
import { CustomSceneObjectDetailState, ObjectTypes } from 'types';

export function getVariables(
  type: ObjectTypes,
  datasource: DataSourceRef,
  cluster?: string,
  node?: string,
  namespace?: string,
  workload?: string,
  workloadType?: string
) {
  let query = '';
  switch (type) {
    case 'workload':
      query = query_result(SceneQueries.WorkloadDetail.PodNames(cluster, namespace, workload, workloadType));
      break;
    case 'node':
      query = query_result(SceneQueries.NodeDetail.PodNames(cluster, node));
      break;
  }
  const podNames = new QueryVariable({
    name: 'podNames',
    query,
    includeAll: true,
    defaultToAll: true,
    datasource,
    skipUrlSync: true,
    regex: '/.*pod="(?<value>[^"]*)".*/', // extract pod label value
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const variableSet = new SceneVariableSet({
    variables: [podNames],
  });

  return variableSet;
}

export const getNetworkQueriesByParams = ({ ...params }: CustomSceneObjectDetailState) => {
  const { type, cluster, node, namespace, podName } = params;
  let sum = {};
  let split = {};
  switch (type) {
    case 'cluster':
      sum = {
        netUsageRx: SceneQueries.ClusterDetail.Network.Usage.Rx(cluster),
        netUsageTx: SceneQueries.ClusterDetail.Network.Usage.Tx(cluster),
        netDroppedRx: SceneQueries.ClusterDetail.Network.Dropped.Rx(cluster),
        netDroppedTx: SceneQueries.ClusterDetail.Network.Dropped.Tx(cluster),
      };
      split = {
        netUsageRx: SceneQueries.ClusterDetail.Network.Usage.Rx(cluster, ['cluster', 'node'].join(',')),
        netUsageTx: SceneQueries.ClusterDetail.Network.Usage.Tx(cluster, ['cluster', 'node'].join(',')),
        netDroppedRx: SceneQueries.ClusterDetail.Network.Dropped.Rx(cluster, ['cluster', 'node'].join(',')),
        netDroppedTx: SceneQueries.ClusterDetail.Network.Dropped.Tx(cluster, ['cluster', 'node'].join(',')),
      };
      return { sum, split, splitBy: 'node' };
    case 'node':
      sum = {
        netUsageRx: SceneQueries.NodeDetail.Network.Usage.Rx(cluster, node),
        netUsageTx: SceneQueries.NodeDetail.Network.Usage.Tx(cluster, node),
        netDroppedRx: SceneQueries.NodeDetail.Network.Dropped.Rx(cluster, node),
        netDroppedTx: SceneQueries.NodeDetail.Network.Dropped.Tx(cluster, node),
      };
      split = {
        netUsageRx: SceneQueries.NodeDetail.Network.Usage.RxBy(cluster, node),
        netUsageTx: SceneQueries.NodeDetail.Network.Usage.TxBy(cluster, node),
        netDroppedRx: SceneQueries.NodeDetail.Network.Dropped.RxBy(cluster, node),
        netDroppedTx: SceneQueries.NodeDetail.Network.Dropped.TxBy(cluster, node),
      };
      return { sum, split, splitBy: 'pod' };
    case 'namespace':
      sum = {
        netUsageRx: SceneQueries.NamespaceDetail.Network.Usage.Rx(cluster, namespace),
        netUsageTx: SceneQueries.NamespaceDetail.Network.Usage.Tx(cluster, namespace),
        netDroppedRx: SceneQueries.NamespaceDetail.Network.Dropped.Rx(cluster, namespace),
        netDroppedTx: SceneQueries.NamespaceDetail.Network.Dropped.Tx(cluster, namespace),
      };
      split = {
        netUsageRx: SceneQueries.NamespaceDetail.Network.Usage.RxBy(cluster, namespace),
        netUsageTx: SceneQueries.NamespaceDetail.Network.Usage.TxBy(cluster, namespace),
        netDroppedRx: SceneQueries.NamespaceDetail.Network.Dropped.RxBy(cluster, namespace),
        netDroppedTx: SceneQueries.NamespaceDetail.Network.Dropped.TxBy(cluster, namespace),
      };
      return { sum, split, splitBy: 'workload' };
    case 'workload':
      sum = {
        netUsageRx: SceneQueries.WorkloadDetail.Network.Usage.Rx(cluster, namespace),
        netUsageTx: SceneQueries.WorkloadDetail.Network.Usage.Tx(cluster, namespace),
        netDroppedRx: SceneQueries.WorkloadDetail.Network.Dropped.Rx(cluster, namespace),
        netDroppedTx: SceneQueries.WorkloadDetail.Network.Dropped.Tx(cluster, namespace),
      };
      split = {
        netUsageRx: SceneQueries.WorkloadDetail.Network.Usage.RxBy(cluster, namespace),
        netUsageTx: SceneQueries.WorkloadDetail.Network.Usage.TxBy(cluster, namespace),
        netDroppedRx: SceneQueries.WorkloadDetail.Network.Dropped.RxBy(cluster, namespace),
        netDroppedTx: SceneQueries.WorkloadDetail.Network.Dropped.TxBy(cluster, namespace),
      };
      return { sum, split, splitBy: 'pod' };
    case 'pod':
      sum = {
        netUsageRx: SceneQueries.PodDetail.Network.Usage.Rx(cluster, namespace, podName),
        netUsageTx: SceneQueries.PodDetail.Network.Usage.Tx(cluster, namespace, podName),
        netDroppedRx: SceneQueries.PodDetail.Network.Dropped.Rx(cluster, namespace, podName),
        netDroppedTx: SceneQueries.PodDetail.Network.Dropped.Tx(cluster, namespace, podName),
      };
      split = {
        netUsageRx: SceneQueries.PodDetail.Network.Usage.Rx(cluster, namespace, podName, 'interface'),
        netUsageTx: SceneQueries.PodDetail.Network.Usage.Tx(cluster, namespace, podName, 'interface'),
        netDroppedRx: SceneQueries.PodDetail.Network.Dropped.Rx(cluster, namespace, podName, 'interface'),
        netDroppedTx: SceneQueries.PodDetail.Network.Dropped.Tx(cluster, namespace, podName, 'interface'),
      };
      return { sum, split, splitBy: 'interface' };
    default:
      return {};
  }
};
