import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { QueryType } from '../GenericDetailOptimization/scene';
import { addVizPanelMenuHandler, getGenericQueryRunner } from 'helpers/scenes';
import { networkConfig } from './config';
import { transformData } from 'components/HomeScene/queryRunners';
import { CustomSceneObjectDetailState } from 'types';

export const networkRowAggregated = (datasource: DataSourceRef, queries: QueryType) => {
  return new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(
          networkConfig({
            title: 'Network Bandwidth',
            runner: getGenericQueryRunner(
              datasource,
              queries.netUsageRx,
              {
                instant: false,
                range: true,
                interval: '',
                refId: 'rx',
                legendFormat: 'Rx',
              },
              [
                {
                  expr: queries.netUsageTx,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'tx',
                  legendFormat: 'Tx',
                },
              ]
            ),
          }).build()
        ),
      }),
      new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(
          networkConfig({
            title: 'Network Saturation',
            runner: getGenericQueryRunner(
              datasource,
              queries.netDroppedRx,
              {
                instant: false,
                range: true,
                interval: '',
                refId: 'rx_dropped',
                legendFormat: 'Rx dropped packets',
              },
              [
                {
                  expr: queries.netDroppedTx,
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'tx_dropped',
                  legendFormat: 'Tx dropped packets',
                },
              ]
            ),
          }).build()
        ),
      }),
    ],
  });
};

export const networkRowSplit = (
  datasource: DataSourceRef,
  queries: QueryType,
  splitBy: string,
  type: CustomSceneObjectDetailState['type']
) => {
  let legendPrefix = '';
  if (splitBy === 'workload') {
    legendPrefix = '{{ workload_type }}/';
  }

  const splitByStringRx = `Rx (${legendPrefix}{{ ${splitBy} }})`;
  const splitByStringTx = `Tx (${legendPrefix}{{ ${splitBy} }})`;
  const splitByStringRxDropped = `Rx dropped packets (${legendPrefix}{{ ${splitBy} }})`;
  const splitByStringTxDropped = `Tx dropped packets (${legendPrefix}{{ ${splitBy} }})`;
  let title = splitBy;
  // Make sure to have consistency in labeling nodes
  if (splitBy === 'instance') {
    title = 'Node';
  }
  return new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(
          networkConfig(
            {
              title: `Network Bandwidth by ${title}`,
              runner: getGenericQueryRunner(
                datasource,
                queries.netUsageRx,
                {
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'rx',
                  legendFormat: splitByStringRx,
                  transformations: [transformData],
                },
                [
                  {
                    expr: queries.netUsageTx,
                    instant: false,
                    range: true,
                    interval: '',
                    refId: 'tx',
                    legendFormat: splitByStringTx,
                  },
                ]
              ),
            },
            type
          ).build()
        ),
      }),
      new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(
          networkConfig(
            {
              title: `Network Saturation by ${title}`,
              runner: getGenericQueryRunner(
                datasource,
                queries.netDroppedRx,
                {
                  instant: false,
                  range: true,
                  interval: '',
                  refId: 'rx_dropped',
                  legendFormat: splitByStringRxDropped,
                  transformations: [transformData],
                },
                [
                  {
                    expr: queries.netDroppedTx,
                    instant: false,
                    range: true,
                    interval: '',
                    refId: 'tx_dropped',
                    legendFormat: splitByStringTxDropped,
                  },
                ]
              ),
            },
            type
          ).build()
        ),
      }),
    ],
  });
};
