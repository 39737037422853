import { Spinner } from '@grafana/ui';
import React from 'react';

const Loader = () => {
  return (
    <div style={{ width: '100%', display: 'flex', height: '100vh', justifyContent: 'center', gap: 4, marginTop: 96 }}>
      Loading ... <Spinner />
    </div>
  );
};

export default Loader;
