const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
prometheus.exporter.apache "exporter_apache_http" {
    scrape_uri = "http://apache.sample-apps.svc.cluster.local:80/server-status?auto"
}

prometheus.scrape "exporter_apache_http" {
    targets    = prometheus.exporter.apache.exporter_apache_http.targets
    forward_to   = [prometheus.relabel.exporter_apache_http.receiver]
}

prometheus.relabel "exporter_apache_http" {
    forward_to   = [prometheus.relabel.metrics_service.receiver]  
    rule {
        replacement = "integrations/apache_http"
        target_label  = "job"
    }
    rule {
        replacement = "<your-instance-name>" // this has to be manually set to the value of your apache http pod <namespace>-<container name>
        target_label  = "instance"
    }
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "apache_http_logs" {
    targets = discovery.relabel.pod_logs.output
    rule {
        source_labels = ["__meta_kubernetes_pod_label_<apache_http_pod_label>"]
        regex = "<apache_http_pod_label_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<apache_http_pod_port_number_value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
}

local.file_match "apache_http_logs" {
    path_targets = discovery.relabel.apache_http_logs.output
}

loki.source.file "apache_http_logs" {
    targets    = local.file_match.apache_http_logs.targets
    forward_to = [loki.process.logs_apache_http_access.receiver, loki.process.logs_apache_http_error.receiver]
} 

loki.process "logs_apache_http_access" {
    forward_to = [loki.process.logs_service.receiver]

    stage.regex {
        expression = "^(?P<ip>[^ ]*) [^ ]* (?P<user>[^ ]*) \\\\[(?P<timestamp>[^\\\\]]*)\\\\] \\"(?P<method>\\\\S+)(?: +(?P<path>[^ ]*) +\\\\S*)?\\" (?P<code>[^ ]*) (?P<size>[^ ]*)(?: \\"(?P<referer>[^\\\\\\"]*)\\" \\"(?P<agent>.*)\\")?$"
    }

    stage.metrics { 
        metric.histogram {
            name    = "response_http_codes"
            description = "Apache responses by HTTP codes"
            source    = "code"
            prefix    = "apache_"
            buckets   = [199,299,399,499,599]
        }
    }

    stage.labels {
        values = {
            method = "",
        }
    }

    stage.static_labels {
        values = {
            logtype = "access",
            job = "integrations/apache_http",
        }
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }
}

loki.process "logs_apache_http_error" {
    forward_to = [loki.process.logs_service.receiver]

    stage.regex {
        expression = "^\\\\[[^ ]* (?P<timestamp>[^\\\\]]*)\\\\] \\\\[(?:(?P<module>[^:\\\\]]+):)?(?P<level>[^\\\\]]+)\\\\](?: \\\\[pid (?P<pid>[^\\\\]]*)\\\\])?(?: \\\\[client (?P<client>[^\\\\]]*)\\\\])? (?P<message>.*)$"
    }

    stage.labels {
        values = {
            level  = "",
            module = "",
        }
    }

    stage.static_labels {
        values = {
            logtype = "error",
            job = "integrations/apache_http",
        }
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"docker\\"}"
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = "{tmp_container_runtime=\\"containerd\\"}"
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
       stage.cri {}
    }
}`;

export default { metrics, logs };
