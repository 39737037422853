const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "couchbase" {
    targets = discovery.kubernetes.endpoints.targets

    rule {
        source_labels = ["__meta_kubernetes_service_name"]
        regex = "<couchbase_service_name>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "couchbase_cluster"
    }
    rule {
        replacement = "integrations/couchbase"
        target_label = "job"
    }
}

prometheus.scrape "metrics_couchbase" {        
    targets      = discovery.relabel.couchbase.output
    honor_labels = true
    forward_to   = [prometheus.relabel.metrics_service.receiver]
    basic_auth {
        username = "<your-username>"
        password = "<your-password>"
      }
}`;
const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_couchbase" {
    targets = discovery.kubernetes.pods.targets

    rule {
        source_labels = ["__meta_kubernetes_pod_name"]
        regex = "<couchbase_pod_name>"
        action = "keep"
    }

    rule {
        source_labels = ["__meta_kubernetes_pod_container_name"]
        regex = "<couchbase-log-forwarder>"
        action = "keep"
    }

    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "couchbase_cluster"
    }
    rule {
        replacement = "integrations/couchbase"
        target_label = "job"
    }
}

loki.source.kubernetes "logs_couchbase" {
    targets    = discovery.relabel.logs_couchbase.output
    forward_to = [loki.process.logs_couchbase.receiver]
}

loki.process "logs_couchbase" {
    forward_to = [loki.process.logs_service.receiver]

    stage.multiline {
        firstline     = \`\\[\d\\] couchbase.log.*\`
        max_lines     = 0
        max_wait_time = "3s"
    }

    stage.drop {
        expression = "---"
    }

    stage.match {
        selector = \`{tmp_container_runtime=\"docker\"}\`
        // the docker processing stage extracts the following k/v pairs: log, stream, time
        stage.docker {}
    }

    stage.match {
        selector = \`{tmp_container_runtime=\"containerd\"}\`
        // the cri processing stage extracts the following k/v pairs: log, stream, time, flags
        stage.cri {}
    }
}`;

export default { metrics, logs };
