const metrics = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "ibm_db2" {
    targets = discovery.kubernetes.pods.targets
    rule {
        source_labels = ["__meta_kubernetes_pod_label_app_<ibm-db2-pod-label>"]
        regex = "<ibm-db2-pod-label-value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_pod_container_port_number"]
        regex = "<db2-exporter-port>"
        action = "keep"
    }
    rule {
        target_label = "db2_cluster"
        replacement = "<ibm-db2-cluster-label>"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
}
prometheus.scrape "ibm_db2" {
    job_name     = "integrations/ibm-db2"
    targets      = discovery.relabel.ibm_db2.output
    forward_to   = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `// Replace all values between the angle brackets '<>', with your desired configuration
discovery.relabel "logs_ibm_db2" {
    targets = discovery.relabel.pod_logs.output
    rule {
        source_labels = ["__meta_kubernetes_pod_label_app_<ibm-db2-pod-label>"]
        regex = "<ibm-db2-pod-label-value>"
        action = "keep"
    }
    rule {
        source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
        separator = "-"
        target_label = "instance"
    }
    rule {
        replacement = "<your-cluster-name>"
        target_label = "db2_cluster"
    }
    rule {
        replacement = "db2diag"
        target_label = "log_type"
    }
}

loki.source.kubernetes "logs_ibm_db2" {
    targets    = discovery.relabel.logs_ibm_db2.output
    forward_to = [loki.process.logs_ibm_db2.receiver]
}

loki.process "logs_ibm_db2" {
    forward_to = [loki.process.logs_service.receiver]
    stage.cri {}
    stage.multiline {
        firstline     = \`\\d+-\\d+-\\d+-\\d+.\\d+.\\d+.\\d+\\+\\d+\\s[A-Z0-9]+\\s+LEVEL:\\s[a-zA-Z]+\`
        max_lines     = 0
        max_wait_time = "3s"
    }
}`;
export default { metrics, logs };
