const metrics = `discovery.relabel "mimir" {
  targets = discovery.kubernetes.pods.targets
  rule {
    source_labels = ["__meta_kubernetes_pod_label_helm_sh_chart"]
    regex = "mimir-distributed-.*"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_port_name"]
    regex = ".*metrics"
    action = "keep"
  }
}

prometheus.scrape "mimir" {
  targets    = discovery.relabel.mimir.output
  job_name   = "integrations/grafana-mimir/metrics"
  forward_to = [prometheus.relabel.metrics_service.receiver]
}`;

const logs = `discovery.relabel "mimir_logs" {
  targets = discovery.relabel.pod_logs.output

  rule {
    source_labels = ["__meta_kubernetes_pod_label_helm_sh_chart"]
    regex = "mimir-distributed-.*"
    action = "keep"
  }
  rule {
    source_labels = ["__meta_kubernetes_pod_container_name"]
    action = "replace"
    target_label = "name"
  }
}

local.file_match "mimir_logs" {
  path_targets = discovery.relabel.mimir_logs.output
}

loki.source.file "mimir_logs" {
  targets    = local.file_match.mimir_logs.targets
  forward_to = [loki.process.mimir_logs.receiver]
}

loki.process "mimir_logs" {
  // If you're using docker, replace \`cri\` with \`docker\` below.
  stage.cri {}
  forward_to = [loki.process.logs_service.receiver]
}`;

export default { metrics, logs };
